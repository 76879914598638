import React, { useEffect } from "react";
import { normalizeResponse } from "../../helper/normalizeResponse";
import { useNavigate } from "react-router-dom";

const RelatedAds = ({ reltedPosts }) => {
  const relatedAdPost = normalizeResponse(reltedPosts);
  const navigate = useNavigate();

  const goToPreviewPage = (data) => {
    navigate("/post-preview", {
      state: {
        data: data,
        actionType: "viewUserAd",
      },
    });
    window.scrollTo(0, 0);
  };

  return (
    <div className="preview-related-ads-wrapper">
      <div className="preview-related-ads-wrap">
        <div className="related-ad-title-wrap">
          <img src="./images/icons/anoucment-icon.png" alt="anoucment icon" />
          <h3> Related ads</h3>
        </div>

        <div className="related-ads-product-wrapper">
          <div className="related-ads-product-wrap">
            {relatedAdPost &&
              relatedAdPost?.slice(0, 4).map((data, index) => {
                return (
                  <>
                    <div className="related-ad-product-repeat">
                      <div className="related-ad-product-img">
                        {data?.uploadedFiles &&
                          data.uploadedFiles.length > 0 && (
                            <img
                              onClick={() => goToPreviewPage(data)}
                              src={data.uploadedFiles[0]}
                              alt="product image"
                            />
                          )}
                      </div>
                      <div className="related-ads-product-detail">
                        <h3> {data?.title} </h3>
                        <p>
                          ${data?.price}/ <span> {data?.selectedAmountV} </span>
                        </p>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedAds;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import { useNavigate } from "react-router-dom";

const CancelModal = (props) => {
  const navigate = useNavigate();
  const logoutHandler = () => {
    navigate("/profile");
    props.onHide();
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        dialogClassName="delete-post-modal-main-wrapper"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="delete-post-main-wrapper">
            <div className="delete-post-main-wrap">
              <div className="delete-post-content-wrap">
                <h3> Post Cancellation! </h3>
                <p>
                  Are you sure you want to cancel this post? Your information
                  will not be saved.
                </p>
              </div>

              <div className="delete-post-buttons">
                <button className="green-button" onClick={logoutHandler}>
                  Cancel Post
                </button>
                <button
                  className="green-bordered-button"
                  onClick={() => props.onHide()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CancelModal;

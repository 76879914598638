import { SERVICE_URL } from "../constant/utility";
import axios from "axios";

const fetchJSON = async (url, options = {}) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw await response.json();
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
};

const servicePost = async (path, payload, authToken) => {
  try {
    const response = await axios.post(`${SERVICE_URL}/${path}`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const serviceImagePost = async (path, payload, authToken) => {
  try {
    const response = await axios.post(`${SERVICE_URL}/${path}`, payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        "auth-token": authToken,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const servicePut = async (path, payload, authToken) => {
  try {
    const response = await axios.put(`${SERVICE_URL}/${path}`, payload, {
      headers: { "auth-token": authToken },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const serviceGet = async (path, payload) => {
  try {
    const response = await axios.get(`${SERVICE_URL}/${path}`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const serviceDelete = async (path, payload, authToken) => {
  try {
    const response = await axios.delete(`${SERVICE_URL}/${path}`, payload, {
      headers: { "auth-token": authToken },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  fetchJSON,
  servicePost,
  serviceImagePost,
  serviceGet,
  servicePut,
  serviceDelete,
};

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setReduxToken } from "../../redux/action/userAction";

const LogoutModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandler = () => {
    props.onHide();
    navigate("/");
    dispatch(setReduxToken(null));
    localStorage.clear();
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        dialogClassName="delete-post-modal-main-wrapper"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="delete-post-main-wrapper">
            <div className="delete-post-main-wrap">
              <div className="delete-post-content-wrap">
                <h3> Logout! </h3>
                <p> Are you sure you want to Logout? </p>
              </div>

              <div className="delete-post-buttons">
                <button className="green-button" onClick={logoutHandler}>
                  Confirm
                </button>
                <button
                  className="green-bordered-button"
                  onClick={() => props.onHide()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default LogoutModal;

import "./App.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Home from "./components/Home";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Register from "./components/register/Register";
import EmailVerification from "./components/register/EmailVerification";
import StartJourney from "./components/register/StartJourney";
import CreateAd from "./components/register/CreateAd";
import CreatePost from "./components/createpost/CreatePost";
import PreviewPost from "./components/createpost/PreviewPost";
import ForgetPassword from "./components/forgetpassword/ForgetPassword";
import Profile from "./components/profile/Profile";
import UserProfile from "./components/profile/UserProfile";
import EditProfile from "./components/profile/EditProfile";
import CategoryProducts from "./components/category/CategoryProducts";
import Messages from "./components/messages/Messages";
import Chat from "./components/messages/Chat";
import EmailConfirmation from "./components/register/EmailConfirmation";
import EditPost from "./components/createpost/EditPost";
import { useEffect, useState } from "react";
import { ProtectedRoute } from "./components/routes/ProtectedRoute";
import PrivacyPolicy from "./components/footer/PrivacyPolicy";

function App() {
  // Get LogedIn User From LocalStorage
  const logedInUser = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/register-step-2" element={<StartJourney />}></Route>
        <Route path="/register-step-3" element={<CreateAd />}></Route>
        <Route path="/forget-password" element={<ForgetPassword />}></Route>
        <Route path="/category-products" element={<CategoryProducts />}></Route>
        <Route path="/post-preview" element={<PreviewPost />}></Route>
        <Route path="/user-profile" element={<UserProfile />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>

        <Route element={<ProtectedRoute />}>
          <Route path="/create-post" element={<CreatePost />}></Route>
          <Route path="/edit-post" element={<EditPost />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/edit-profile" element={<EditProfile />}></Route>
          <Route path="/messages" element={<Messages />}></Route>
          <Route path="/chat" element={<Chat />}></Route>
        </Route>

        <Route
          path="/email-verification"
          element={<EmailVerification />}
        ></Route>

        <Route
          path="/email-confirmation"
          element={<EmailConfirmation />}
        ></Route>
      </Routes>

      <Footer />
    </>
  );
}

export default App;

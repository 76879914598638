import React, { Fragment, useEffect, useState } from "react";
import "./dropdown.css";
import { servicePost } from "../../helper/api";

const AvailabilityDrop = ({ onTypeChange, alreadySelected, error }) => {
  const [isStateActive, setIsStateActive] = useState(false);
  const [stateSelected, setStateSelected] = useState(
    alreadySelected ? alreadySelected : "Select Availability"
  );
  const [availabilityList, setAvailabilityList] = useState([]);
  // get Token From localstroge
  const authToken = localStorage.getItem("token");
  const gatQuantityVariation = async () => {
    try {
      const availabilityListRes = await servicePost(
        "category/getAvailability",
        {},
        authToken
      );
      setAvailabilityList(availabilityListRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    gatQuantityVariation();
  }, []);

  return (
    <>
      <div className="select-box-wrapper">
        {error ? (
          <label>
            <span className="error-message">{error}</span>
          </label>
        ) : (
          <label> Availability* </label>
        )}

        <div className="react-dropdown">
          <div
            className={
              error ? "react-dropdown-btn input-error" : "react-dropdown-btn"
            }
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsStateActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsStateActive(false);
            }}
          >
            <div
              className={`selected-item ${
                stateSelected.startsWith("Select") ? "placeholderText" : ""
              }`}
            >
              <p>{stateSelected}</p>
            </div>

            <img
              className={
                isStateActive
                  ? "dropdown-down-arrow rotate-arrow"
                  : "dropdown-down-arrow"
              }
              src="/images/icons/nav-country-drop.svg"
              alt="arrow"
            />
          </div>
          <div
            className="dropdown-space-manage"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsStateActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsStateActive(false);
            }}
          ></div>
          {isStateActive && (
            <div
              className="dropdown-content"
              onMouseEnter={(e) => {
                e.stopPropagation();
                setIsStateActive(true);
              }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                setIsStateActive(false);
              }}
            >
              {availabilityList &&
                availabilityList.map((option, index) => {
                  return (
                    <Fragment key={index}>
                      <div
                        onClick={(e) => {
                          setStateSelected(option.name);
                          setIsStateActive(false);

                          onTypeChange(option.name); // Call callback function
                        }}
                        className={
                          option.name === stateSelected
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                      >
                        <div className="dropdown-options">
                          <p
                            className={`${
                              option.name === stateSelected
                                ? "option-active"
                                : "option-normal"
                            }`}
                          >
                            {option.name}
                          </p>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AvailabilityDrop;

import React, { useState } from "react";
import "./footer.css";
import { useNavigate } from "react-router-dom";
import { servicePost } from "../../helper/api";
import CommanModal from "../modals/CommanModal";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCommanModal, setShowCommanModal] = useState(false);
  // Error Validation
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const goToPrivacyPage = () => {
    navigate("/privacy-policy");
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setError("");
  };

  const subscribeEmail = async () => {
    if (email == "") {
      setError("Please Enter Email");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Please Enter Valid Email");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    setIsLoading(true);
    try {
      const addMemberRes = await servicePost("message/addMember", {
        email: email,
      });
    } catch (error) {
      console.log("error", error);
    }

    try {
      const sendEmailRes = await servicePost("message/sendEmail", {
        email: email,
        type: "plaintext",
      });

      setShowCommanModal(true);
      setTimeout(() => {
        setShowCommanModal(false);
        setIsLoading(false);
        setEmail("");
      }, 3000);
    } catch (error) {
      console.log("err", error);
      setError("server Error");
      setTimeout(() => {
        setError("");
        setIsLoading(false);
      }, 3000);
    }
  };

  return (
    <>
      <CommanModal
        show={showCommanModal}
        onHide={() => setShowCommanModal(false)}
        type="Thank You for Subscribing!"
        content="We've sent a confirmation email to the address you provided.
                 Please check your inbox (and maybe your spam folder, just in case!) 
                 to confirm your subscription."
      />
      <div className="footer-main-wrapper">
        <div className="footer-main-wrap container">
          <div className="footer-left-wrap">
            <img src="./images/icons/footer-logo.png" alt="logo" />
            <div className="footer-subscribe-wrapper">
              <div className="footer-input-wrap">
                {error ? (
                  <label>
                    {" "}
                    <span className="error-message">{error}</span>{" "}
                  </label>
                ) : (
                  <label> Email Address</label>
                )}

                <input
                  className={error ? "input-error" : ""}
                  type="text"
                  value={email}
                  placeholder="Enter your Email Address"
                  onChange={handleInputChange}
                />
                <div className="footer-subscribe-button">
                  <button
                    style={{ cursor: isLoading ? "no-drop" : "pointer" }}
                    onClick={subscribeEmail}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Subscribe"}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-right-wrapper">
            <div className="footer-right-wrap">
              {/* <div className="footer-right-step">
                <h3> Farm </h3>

                <div className="form-page-ul-list">
                  <p> About Us </p>
                  <p> Contact Us</p>
                </div>
              </div> */}

              <div className="footer-right-step">
                <h3> Contact </h3>

                <div className="form-page-ul-list">
                  <p> info@foodclassifieds.com </p>
                  {/* <p> +17654309873246 </p> */}
                  <p className="privacy-policy" onClick={goToPrivacyPage}>
                    Privacy Policy
                  </p>
                </div>
              </div>

              <div className="footer-right-step">
                <h3> Follow us </h3>

                <div className="form-page-follow-icons">
                  <div className="form-follow-icons">
                    <a
                      href="https://www.instagram.com/food.classifieds/"
                      target="_blank"
                    >
                      <img src="./images/icons/insta.svg" alt="insta icon" />
                    </a>
                    <a
                      href="https://www.facebook.com/FoodClassifieds/"
                      target="_blank"
                    >
                      <img
                        src="./images/icons/facebook.svg"
                        alt="facebook icon"
                      />
                    </a>
                    <a
                      href="https://twitter.com/foodclassifieds"
                      target="_blank"
                    >
                      <img
                        src="./images/icons/twitter.svg"
                        alt="twitter icon"
                      />
                    </a>

                    <a href="" target="_blank">
                      <img
                        src="./images/icons/linkden.svg"
                        alt="linkden icon"
                      />
                    </a>

                    <a
                      href="https://www.youtube.com/@FoodClassifieds"
                      target="_blank"
                    >
                      <img
                        src="./images/icons/youtube.svg"
                        alt="youtube icon"
                      />
                    </a>
                  </div>
                  {/* <div className="form-follow-icons">
                    <img
                      src="./images/icons/facebook.svg"
                      alt="facebook icon"
                    />
                    <img src="./images/icons/twitter.svg" alt="twitter icon" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copy-right-text container">
          <div className="gyan-consulting-logo">
            <p> Powered by </p>
            <a href="https://www.gyan.solutions" target="_blank">
              <img src="./images/icons/gyan-consulting.png" alt="icon" />
            </a>
          </div>
          <p> Copyright 2024, All Right Reserved</p>
        </div>
      </div>
      {/***************** mobile View footer */}
      <div className="mobile-view-footer-wrapper">
        <div className="mobile-view-fopter-wrap">
          <div className="mobile-footer-logo">
            <img src="./images/icons/footer-logo.png" alt="logo" />
          </div>

          <div className="mobile-footer-accordin-wrap">
            <div className="accordion" id="accordionExample">
              {/* <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Farm
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p> About Us</p>
                    <p> Contact Us</p>
                  </div>
                </div>
              </div> */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Contact
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p> info@foodclassifieds.com</p>
                    <p className="privacy-policy" onClick={goToPrivacyPage}>
                      Privacy Policy
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mobile-footer-socail-icons">
            <h3> Follow us </h3>
            <div className="mobile-footer-follow-icons">
              <a
                href="https://www.instagram.com/food.classifieds/"
                target="_blank"
              >
                <img src="./images/icons/insta.svg" alt="insta icon" />
              </a>
              <a
                href="https://www.facebook.com/FoodClassifieds/"
                target="_blank"
              >
                <img src="./images/icons/facebook.svg" alt="facebook icon" />
              </a>
              <a href="https://twitter.com/foodclassifieds" target="_blank">
                <img src="./images/icons/twitter.svg" alt="twitter icon" />
              </a>

              <a href="" target="_blank">
                <img src="./images/icons/linkden.svg" alt="linkden icon" />
              </a>

              <a
                href="https://www.youtube.com/@FoodClassifieds"
                target="_blank"
              >
                <img src="./images/icons/youtube.svg" alt="youtube icon" />
              </a>
            </div>
          </div>

          <div className="mobile-footer-copyright">
            <div className="mobile-gyan-consulting-logo">
              <p> Powered by </p>
              <a href="https://www.gyan.solutions" target="_blank">
                <img src="./images/icons/gyan-consulting.png" alt="icon" />
              </a>
            </div>
            <p> Copyright 2024, All Right Reserved</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

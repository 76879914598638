// helper/normalizeData.js
export const normalizeMsgResponse = (data) => {
  return data.map((item) => ({
    AccountId: item.AccountId,
    postId: item.PostId,
    MainCategory: item.MainCategory,
    selectedCategory: item.Category,
    selectedSubcategory: item.SubCategory,
    selectedAvailability: item.Availability,
    selectedDays: item.PickupDays,
    selectedCountry: item.Country,
    selectedProvince: item.Province,
    selectedCity: item.City,
    selectedPreference: item.ContactPreference,
    openTime: item.PickupOpenTime,
    closeTime: item.PickupCloseTime,
    selectedDeliveryDays: item.DeliveryDays,
    openDeliveryTime: item.DeliveryOpenTime,
    closeDeliveryTime: item.DeliveryCloseTime,
    selectedAmountV: item.PricingVariation,
    selectedQuantityV: item.QuantityVariation,
    uploadedFiles: item.Images,
    pickupChecked: item.PickupChecked,
    deliveryChecked: item.DeliveryChecked,
    title: item.AdvertisementTitle,
    price: item.Pricing,
    quantity: item.Quantity,
    specialOffer: item.SpecialOffer,
    websiteLink: item.WebsiteLink,
    description: item.Description,
    certificate1: item.Certificate1,
    certificate2: item.Certificate2,
    certificate3: item.Certificate3,
    address: item.Address,
    email: item.EmailAddress,
    phoneNumber: item.Phone,
    deilveryCost: item.CostForDelivery,
    ExpiryDate: item.ExpiryDate,
    createdAt: item.createdAt,
    //
    User1Image: item.User1Image,
    User2Image: item.User2Image,
    EmailAddress1: item.EmailAddress1,
    EmailAddress2: item.EmailAddress2,
    AccountId1: item.AccountId1,
    AccountId2: item.AccountId2,
    messageId: item.id,
  }));
};

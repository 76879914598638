import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./messages.css";
import { Helmet } from "react-helmet";
import { servicePost } from "../../helper/api";
import moment from "moment";
import { normalizeMsgResponse } from "../../helper/normalizeMsgResponse";
import Navbar from "../navbar/Navbar";
import { setTaskDep } from "../../redux/action/userAction";
import { useSelector, useDispatch } from "react-redux";

const Messages = () => {
  const [messageData, setMessageData] = useState([]);
  const [visibleCount, setVisibleCount] = useState(4); // Number of initially visible items
  // NormalizeData and Loop throw
  const normalizedData = normalizeMsgResponse(messageData);
  const itemsToShow = normalizedData?.slice(0, visibleCount);

  // Get LogedIn User From LocalStorage
  const logedInUser = JSON.parse(localStorage.getItem("user"));
  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  const dispatch = useDispatch();
  const taskDep = useSelector(
    (state) => state.userReducer?.taskDep && state.userReducer.taskDep.Dep1
  );

  const handleShowMore = () => {
    const increment = 4; // Increment by 3 items
    setVisibleCount((prevCount) => prevCount + increment);
  };

  const navigate = useNavigate();

  const deleteViewedNotification = async (data) => {
    try {
      const deleteViewNotify = await servicePost(
        "message/deleteNotificationData",
        {
          accountId: logedInUser?.id,
          messageId: data?.messageId,
        },
        authToken
      );

      dispatch(
        setTaskDep({
          Dep1: taskDep + 1,
        })
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  const goToChatPage = (data, accountId) => {
    navigate("/chat", {
      state: {
        data: data,
        accountId: accountId,
      },
    });
    deleteViewedNotification(data);
  };

  const goToViewPost = (data, accountId) => {
    navigate("/post-preview", {
      state: {
        data: data,
        actionType: "viewUserAd",
        accountId: accountId,
      },
    });
  };

  const goToUserProfile = (accountId) => {
    navigate("/user-profile", {
      state: {
        AccountId: accountId,
      },
    });
  };

  const getMessagesData = async () => {
    try {
      const messagesRespose = await servicePost(
        "message/getMessageData",
        {
          accountId: logedInUser?.id,
        },
        authToken
      );

      // Reverse the array of messages
      const reversedMessages = messagesRespose.data.reverse();

      setMessageData(reversedMessages);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getMessagesData();
  }, [logedInUser?.id]);

  return (
    <>
      <Helmet>
        <title> Messages | Food Classifieds </title>
      </Helmet>

      <div className="profile-main-wrapper">
        <div className="profile-main-wrap container">
          <div className="profile-top-ad-wrap">
            <img src="./images/preview-top-ad.png" alt="img" />
            <p> Ads </p>
          </div>

          <div className="profile-product-items-wrapper">
            <div className="message-title-wrap">
              <h3> Messages </h3>
            </div>
            <div className="profile-item-table-wrapper messages-item-table-wrapper">
              {itemsToShow?.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th className="msg-table-product-th">Media</th>
                      <th>Title</th>
                      <th>Price</th>
                      <th className="none-mobile-view">Posting Time</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemsToShow?.map((data, index) => {
                      return (
                        <tr>
                          <td>
                            {data.AccountId1 == logedInUser?.id ? (
                              <div
                                className="message-table-product-image"
                                onClick={() =>
                                  goToViewPost(data, data.AccountId2)
                                }
                              >
                                {data?.uploadedFiles &&
                                  data.uploadedFiles.length > 0 && (
                                    <img
                                      src={data.uploadedFiles[0]}
                                      alt="product image"
                                    />
                                  )}
                              </div>
                            ) : (
                              <div
                                className="message-table-product-image"
                                onClick={() =>
                                  goToViewPost(data, data.AccountId1)
                                }
                              >
                                {data?.uploadedFiles &&
                                  data.uploadedFiles.length > 0 && (
                                    <img
                                      src={data.uploadedFiles[0]}
                                      alt="product image"
                                    />
                                  )}
                              </div>
                            )}
                          </td>
                          <td>
                            <div className="table-messages-title-wrap">
                              {data?.title}

                              {data.AccountId1 == logedInUser?.id ? (
                                <div
                                  className="table-msg-profile-wrap"
                                  onClick={() =>
                                    goToUserProfile(data.AccountId2)
                                  }
                                >
                                  <img
                                    src={
                                      data?.User2Image
                                        ? data.User2Image
                                        : "./images/icons/avtar.png"
                                    }
                                    alt="profile icon"
                                  />
                                  <p> {data?.EmailAddress2?.slice(0, 8)}... </p>
                                </div>
                              ) : (
                                <div
                                  className="table-msg-profile-wrap"
                                  onClick={() =>
                                    goToUserProfile(data.AccountId1)
                                  }
                                >
                                  <img
                                    src={
                                      data?.User1Image
                                        ? data.User1Image
                                        : "./images/icons/avtar.png"
                                    }
                                    alt="profile icon"
                                  />
                                  <p> {data?.EmailAddress1?.slice(0, 8)}... </p>
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            ${data?.price}/{data?.selectedAmountV}
                          </td>
                          <td className="none-mobile-view">
                            {moment(data.createdAt)
                              .startOf("minutes")
                              .fromNow()}
                          </td>

                          <td>
                            <div className="msg-table-actions-wrap">
                              {data.AccountId1 == logedInUser?.id ? (
                                <img
                                  onClick={() =>
                                    goToChatPage(data, data.AccountId2)
                                  }
                                  src="./images/icons/messages-icon.png"
                                  alt="messages icon"
                                />
                              ) : (
                                <img
                                  onClick={() =>
                                    goToChatPage(data, data.AccountId1)
                                  }
                                  src="./images/icons/messages-icon.png"
                                  alt="messages icon"
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="messages-empty-main-wrap">
                  <div className="empty-icon-wrap">
                    <img src="./images/icons/empty-icon.svg" alt="empty-icon" />
                    <p> No Messages Found </p>
                  </div>
                </div>
              )}

              {visibleCount < messageData.length && (
                <div className="table-show-more" onClick={handleShowMore}>
                  <h3> Show more</h3>
                </div>
              )}
            </div>

            <div className="profile-botto-ad-wrap">
              <img src="./images/car-ads.png" alt="ads" />
              <p> Ads </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Messages;

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { servicePost } from "../../helper/api";
import CountryDropdown from "../dropdown/CountryDropdown";
import { setReduxCountry } from "../../redux/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import LocationProvince from "../dropdown/LocationProvince";
import LocationCityDrop from "../dropdown/LocationCityDrop";
import LocationCountry from "../dropdown/LocationCountry";

const LocationSearch = ({ hideLocationDropdown, handleModalSave }) => {
  // const [searchValue, setSearchValue] = useState("");
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedProvince, setSelectedProvince] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [provinceError, setProvinceError] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // get Token From localstroge

  const alreadyData = useSelector(
    (state) => state.userReducer?.locationData && state.userReducer.locationData
  );

  const AllCategorydata = {
    categoryType: "Location",
    categoryTypeImg: "./images/icons/location-icon.png",
  };

  const locationData = {
    country: selectedCountry ? selectedCountry : alreadyData?.country,
    state: selectedProvince ? selectedProvince : alreadyData?.state,
    city: selectedCity,
  };

  const goToAllCategory = () => {
    if (!selectedProvince && !alreadyData?.state) {
      setProvinceError("Please select province");

      setTimeout(() => {
        setProvinceError("");
      }, 3000);
      return;
    }
    navigate("/category-products", {
      state: {
        data: AllCategorydata,
        MainCategory: "",
        locationCity: selectedCity,
        locationProvince: selectedProvince
          ? selectedProvince
          : alreadyData?.state,
      },
    });
    hideLocationDropdown();
    dispatch(setReduxCountry(locationData));
  };

  const changePreData = {
    country: alreadyData?.country,
    state: selectedProvince,
    city: selectedCity,
  };

  const handleCountrychange = (country) => {
    setSelectedCountry(country);

    dispatch(setReduxCountry(changePreData));
    setSelectedCity();
  };

  const handleProvincechange = (provice) => {
    setSelectedProvince(provice);
  };

  const handleCitychange = (city) => {
    setSelectedCity(city);
  };

  return (
    <>
      <div className="loction-search-filter-dropdown-wrapper">
        <div className="location-search-filter-drop-wrap">
          <div className="login-form-input-wrap">
            <LocationCountry
              onTypeChange={handleCountrychange}
              // error={errors.selectedProvince}
            />
          </div>

          <div className="login-form-input-wrap">
            <LocationProvince
              onTypeChange={handleProvincechange}
              error={provinceError}
              country={selectedCountry}
              state={selectedProvince}
            />
          </div>

          <div className="login-form-input-wrap">
            <LocationCityDrop
              onTypeChange={handleCitychange}
              // error={errors.selectedCity}
              country={selectedCountry}
              state={selectedProvince}
            />
          </div>

          <div className="login-button-main-wrap preview-button-wrap loction-filter-btn">
            <button onClick={goToAllCategory}> Apply </button>
            <button
              className="login-cancel-button"
              onClick={() => hideLocationDropdown()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationSearch;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginModal from "../modals/LoginModal";
import { servicePost } from "../../helper/api";
import validator from "validator";
const ForgetPassword = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShownicon, setPasswordShownicon] = useState(false);
  const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);
  const [confirmpasswordShownicon, setConfirmPasswordShownicon] =
    useState(false);

  const [otpVerified, setOtpVerifed] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  //////////////
  const [otp, setOtp] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [sentotp, setsentOtp] = useState(true);

  // Error Validation
  const [errors, setErrors] = useState({
    email: "",
    otp: "",
    password: "",
    confirmPassword: "",
  });

  //////////////
  const navigate = useNavigate();

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    setPasswordShownicon(!passwordShownicon);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmpasswordShown);
    setConfirmPasswordShownicon(!confirmpasswordShownicon);
  };

  const changePassword = () => {
    setPasswordChanged(true);

    setTimeout(() => {
      setShowLoginModal(true);
    }, 3000);
  };

  //////////////
  ///////////////////////////////
  const reqOtp = async () => {
    if (!email || !email.trim() || email == undefined) {
      setErrors({
        email: "Email is required",
      });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }

    if (validator.isEmail(email)) {
    } else {
      setErrors({
        email: "Please, enter valid Email!",
      });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }

    try {
      //////////////
      const signupUser = await servicePost(
        "forgotpassword",
        {
          email: email.toLowerCase(),
        },
        {}
        //csrfTokenState
      );

      if (signupUser.message == "SUCCESS") {
        setsentOtp(false);
        // props.onHide();
        // setModalShow(true);
        /// setReqLoader(false);
        // setTimeout(function () {
        //   navigate("/");
        //  // setModalShow(false);
        // }, 2000);
      }
    } catch (error) {
      console.log("error", error);
      //setReqLoader(false);
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
  };
  ///////////
  ///////////////////////////////
  const verifyOtp = async () => {
    if (!email || !email.trim() || email == undefined) {
      setErrors({
        email: "Email is required",
      });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }

    if (validator.isEmail(email)) {
    } else {
      setErrors({
        email: "Please, enter valid Email!",
      });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }

    if (!otp || !otp.trim() || otp == undefined) {
      setErrors({
        otp: "Otp is required",
      });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }
    try {
      //////////////
      const signupUser = await servicePost(
        "veriryOtp",
        {
          email: email.toLowerCase(),
          otp: otp,
        },
        {}
        //csrfTokenState
      );

      if (
        signupUser.message == "OTP Expired" ||
        signupUser.message == "Invalid OTP"
      ) {
        setErrors({
          otp: signupUser.message,
        });
        setTimeout(() => {
          setErrors("");
        }, 5000);
        return;
      }

      if (signupUser.message == "SUCCESS") {
        setOtpVerifed(true);
        // setTimeout(function () {
        //   navigate("/");
        // }, 2000);
      }
    } catch (error) {
      console.log("error", error);
      //setReqLoader(false);
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
  };
  ///////////
  ///////////////////////////////
  const changepasswords = async () => {
    if (!password || !password.trim() || password == undefined) {
      setErrors({
        password: "Password is required",
      });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }

    if (
      !confirmPassword ||
      !confirmPassword.trim() ||
      confirmPassword == undefined
    ) {
      setErrors({
        confirmPassword: "confirmPassword is required",
      });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }

    if (password !== confirmPassword) {
      setErrors({
        confirmPassword: "Password not match",
      });
      setTimeout(() => {
        setErrors("");
      }, 5000);
      return;
    }

    try {
      //////////////
      const signupUser = await servicePost(
        "resetPassword",
        {
          email: email.toLowerCase(),
          password: password,
        },
        {}
        //csrfTokenState
      );

      if (signupUser.message == "SUCCESS") {
        setPasswordChanged(true);
        setTimeout(() => {
          setShowLoginModal(true);
        }, 3000);
      }
    } catch (error) {
      console.log("error", error);

      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
  };
  ///////////
  ///useHandleChange to show password Error when user type
  const handleChange = (e) => {
    let { value } = e.target;

    const pass = e.target.value;
    setPassword(pass);
    if (
      validator.isStrongPassword(pass, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      // //console.log("hh....");
      //document.getElementById("newpass").style.display = "block";

      setErrorMessage("");
      setError(false);
    } else {
      setError(true);
      //setHide(!hide);
      setErrorMessage(
        "Password must contain: min 8 Characters, 1 uppercase & 1 lowercase letter, 1 number and 1 special character!"
      );
      // document.getElementById("newpass").style.display = "none";
      // setTimeout(() => {
      //   setErrorMessage("");
      //   setError(false);
      // }, 5000);
      return;
    }
  };

  ///////////////////////////////
  return (
    <>
      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />

      <div className="register-main-wrapper">
        <div className="register-main-wrap">
          <div className="register-main-left-wrapper">
            {passwordChanged && (
              <div className="register-main-left-wrap email-verification-content forget-password-content">
                <img
                  src="./images/icons/confirm-tick.png"
                  alt="confirm tick image"
                />
                <h3> Forgot Password! </h3>
                <p>your successfully changed you password</p>
              </div>
            )}

            {!passwordChanged && (
              <div className="register-main-left-wrap forget-password-left-wrap">
                {otpVerified ? (
                  <div className="register-header-content">
                    <h3> New Password </h3>
                    <p> Please enter your new password </p>
                  </div>
                ) : (
                  <div className="register-header-content">
                    <h3> Forget Password </h3>
                    <p> Please enter your email or username </p>
                  </div>
                )}

                <div className="login-form-main-wrap">
                  <form>
                    {otpVerified ? (
                      <>
                        <div className="login-form-input-wrap">
                          {errors.password ? (
                            <label>
                              <span className="error-message">
                                {errors.password}
                              </span>
                            </label>
                          ) : (
                            <label> Enter Password* </label>
                          )}

                          <input
                            className={errors.password ? "input-error" : ""}
                            type={passwordShown ? "text" : "password"}
                            placeholder="Enter your New Password"
                            onChange={(e) => handleChange(e)}
                            value={password}
                          />
                          <span
                            className="password-icon"
                            onClick={togglePassword}
                          >
                            {passwordShownicon ? (
                              <img
                                src="./images/icons/password-show.svg"
                                alt="password show icon"
                              />
                            ) : (
                              <img
                                src="./images/icons/password-hide.svg"
                                alt="hide password icon"
                              />
                            )}
                          </span>
                        </div>

                        <div className="login-form-input-wrap">
                          {errors.confirmPassword ? (
                            <label>
                              <span className="error-message">
                                {errors.confirmPassword}
                              </span>
                            </label>
                          ) : (
                            <label> Re-enter Password*</label>
                          )}

                          <input
                            className={
                              errors.confirmPassword ? "input-error" : ""
                            }
                            type={confirmpasswordShown ? "text" : "password"}
                            placeholder="Confirm your Password"
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                            value={confirmPassword}
                          />
                          <span
                            className="password-icon"
                            onClick={toggleConfirmPassword}
                          >
                            {confirmpasswordShownicon ? (
                              <img
                                src="./images/icons/password-show.svg"
                                alt="show icon"
                              />
                            ) : (
                              <img
                                src="./images/icons/password-hide.svg"
                                alt="hide icon"
                              />
                            )}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="login-form-input-wrap forget-pass-email-input">
                          {errors.email ? (
                            <label>
                              <span className="error-message">
                                {errors.email}
                              </span>
                            </label>
                          ) : (
                            <label> Email Address* </label>
                          )}

                          <input
                            className={errors.email ? "input-error" : ""}
                            type="text"
                            placeholder="Enter your email"
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                          <div className="send-otp-wrap">
                            {sentotp ? (
                              <h3 onClick={reqOtp}> Send Otp</h3>
                            ) : (
                              <h3> check mail</h3>
                            )}
                          </div>
                        </div>
                        <div className="login-form-input-wrap">
                          {errors.otp ? (
                            <label>
                              <span className="error-message">
                                {errors.otp}
                              </span>
                            </label>
                          ) : (
                            <label> Enter otp*</label>
                          )}

                          <input
                            className={errors.otp ? "input-error" : ""}
                            type="number"
                            placeholder="Enter your OTP code"
                            onChange={(e) => {
                              setOtp(e.target.value);
                            }}
                          />
                        </div>
                      </>
                    )}
                    {error ? (
                      <div className="error-msg">{errorMessage}</div>
                    ) : (
                      ""
                    )}
                  </form>

                  <div className="login-button-main-wrap">
                    {otpVerified ? (
                      <button onClick={changepasswords}>Submit</button>
                    ) : (
                      <button onClick={verifyOtp}> Procced </button>
                    )}

                    <p onClick={() => setShowLoginModal(true)}>
                      {" "}
                      Already To Farm App?{" "}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="register-main-right-wrap">
            <img
              src="./images/forget-password.png"
              alt="forget password icon"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;

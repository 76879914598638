import React, { useEffect } from "react";

const AdsComponent = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1117338431023347"; // Replace with your AdSense client ID if different
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script); // Clean up the script on unmount
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-1117338431023347"
      data-ad-slot="8949706521"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  );
};

export default AdsComponent;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";

const DeliveryTimeModal = ({
  alreadySelected,
  preOpenTime,
  preCloseTime,
  ...props
}) => {
  const [openTime, setOpenTime] = useState(preOpenTime || "");
  const [closeTime, setCloseTime] = useState(preCloseTime || "");
  const [checkedDays, setCheckedDays] = useState(alreadySelected || []);

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      setCheckedDays((prevCheckedDays) => [...prevCheckedDays, id]);
    } else {
      setCheckedDays((prevCheckedDays) =>
        prevCheckedDays.filter((day) => day !== id)
      );
    }
  };

  const handleOpenTimeChange = (e) => {
    setOpenTime(e.target.value);
  };

  const handleCloseTimeChange = (e) => {
    setCloseTime(e.target.value);
  };

  const handleSave = () => {
    props.onSave(checkedDays, openTime, closeTime);
  };

  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="login-modal-main-wrapper pickup-time-modal-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}

      <Modal.Body>
        <div className="select-time-modal-main-wrapper">
          <div className="cross-button">
            <img
              onClick={() => {
                props.onHide();
              }}
              src="./images/icons/cross-icon.svg"
              alt="icon"
            />
          </div>
          <div className="login-main-wrap days-select-modal-wrap">
            <div className="login-content-wrap select-modal-content-wrap">
              <h3> Select Days& Timing</h3>
            </div>

            <div className="days-checkbox-wrapper">
              <label htmlFor="Monday">
                <input
                  className="accented"
                  type="checkbox"
                  id="Monday"
                  name="Monday"
                  onChange={handleCheckboxChange}
                  checked={checkedDays.includes("Monday")}
                />
                Monday
              </label>
            </div>

            <div className="days-checkbox-wrapper">
              <label htmlFor="Tuesday">
                <input
                  className="accented"
                  type="checkbox"
                  id="Tuesday"
                  name="Tuesday"
                  onChange={handleCheckboxChange}
                  checked={checkedDays.includes("Tuesday")}
                />
                Tuesday
              </label>
            </div>

            <div className="days-checkbox-wrapper">
              <label htmlFor="Wednesday">
                <input
                  className="accented"
                  type="checkbox"
                  id="Wednesday"
                  name="Wednesday"
                  onChange={handleCheckboxChange}
                  checked={checkedDays.includes("Wednesday")}
                />
                Wednesday
              </label>
            </div>

            <div className="days-checkbox-wrapper">
              <label htmlFor="Thursday">
                <input
                  className="accented"
                  type="checkbox"
                  id="Thursday"
                  name="Thursday"
                  onChange={handleCheckboxChange}
                  checked={checkedDays.includes("Thursday")}
                />
                Thursday
              </label>
            </div>

            <div className="days-checkbox-wrapper">
              <label htmlFor="Friday">
                <input
                  className="accented"
                  type="checkbox"
                  id="Friday"
                  name="Friday"
                  onChange={handleCheckboxChange}
                  checked={checkedDays.includes("Friday")}
                />
                Friday
              </label>
            </div>

            <div className="days-checkbox-wrapper">
              <label htmlFor="Saturday">
                <input
                  className="accented"
                  type="checkbox"
                  id="Saturday"
                  name="Saturday"
                  onChange={handleCheckboxChange}
                  checked={checkedDays.includes("Saturday")}
                />
                Saturday
              </label>
            </div>

            <div className="days-checkbox-wrapper">
              <label htmlFor="Sunday">
                <input
                  className="accented"
                  type="checkbox"
                  id="Sunday"
                  name="Sunday"
                  onChange={handleCheckboxChange}
                  checked={checkedDays.includes("Sunday")}
                />
                Sunday
              </label>
            </div>

            <div className="select-time-title">
              <h3> Select Time</h3>
            </div>

            <div className="days-checkbox-wrapper days-time-wrapper">
              <div className="open-time-wrap">
                <label> Open </label>
                <input
                  type="time"
                  value={openTime}
                  onChange={handleOpenTimeChange}
                />
              </div>
              <p> to </p>
              <div className="open-time-wrap">
                <label> Close </label>
                <input
                  type="time"
                  value={closeTime}
                  onChange={handleCloseTimeChange}
                />
              </div>
            </div>

            <div className="login-button-main-wrap">
              <button onClick={handleSave}> Save </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DeliveryTimeModal;

import React, { useState } from "react";
import "./drawer.css";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";
import CommanModal from "../modals/CommanModal";
import { servicePost } from "../../helper/api";
import { useNavigate } from "react-router-dom";

const MessageDrawer = ({ data, User2Image }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showCommanModal, setShowCommanModal] = useState(false);

  const [formData, setFormData] = useState({
    message: "",
  });

  const { message } = formData;

  // Error Validation
  const [errors, setErrors] = useState({
    message: "",
  });

  const errorMessages = {
    message: "Message is required",
  };

  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;
    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
    setErrors({ ...errors, [name]: "" });
  };

  // Get LogedIn User From LocalStorage
  const logedInUser = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  const sendMessageHandler = async () => {
    const requiredFields = ["message"];
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field] || !formData[field].trim()) {
        newErrors[field] = errorMessages[field];
      } else if (field === "message") {
        const minLength = 35;
        const titleLength = formData[field].trim().length;
        if (titleLength < minLength) {
          newErrors[field] = "Message must be at least 35 characters";
        }
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
      return;
    }

    const pickupDays = data?.selectedDays.join(",");
    const deliveryDays = data?.selectedDeliveryDays.join(",");
    try {
      setIsLoading(true);
      const messageData = await servicePost(
        "message/addMessageData",
        {
          AdvertisementTitle: data?.title,
          MainCategory: data.MainCategory,
          Category: data?.selectedCategory,
          SubCategory: data?.selectedSubcategory,
          Pricing: data?.price,
          Message: message,
          PostId: data?.postId,
          AccountId1: logedInUser?.id,
          AccountId2: data?.AccountId,
          //
          PricingVariation: data.selectedAmountV,
          Quantity: data.quantity,
          QuantityVariation: data.selectedQuantityV,
          Availability: data.selectedAvailability,
          SpecialOffer: data.specialOffer,
          WebsiteLink: data.websiteLink,
          Description: data.description,
          //
          Certificate1: data.certificate1,
          Certificate2: data.certificate2,
          Certificate3: data.certificate3,
          Province: data.selectedProvince,
          City: data.selectedCity,
          Address: data.address,
          EmailAddress1: logedInUser?.Email,
          EmailAddress2: data.email,
          Phone: data.phoneNumber,
          ContactPreference: data.selectedPreference,
          PickupChecked: data.pickupChecked,
          DeliveryChecked: data.deliveryChecked,
          //
          Images: data?.uploadedFiles,
          PickupDays: pickupDays,
          DeliveryDays: deliveryDays,
          PickupOpenTime: data.openTime,
          PickupCloseTime: data.closeTime,

          DeliveryOpenTime: data.openDeliveryTime,
          DeliveryCloseTime: data.closeDeliveryTime,
          CostForDelivery: data.deilveryCost,
          ExpiryDate: data.ExpiryDate,
          User1Image: logedInUser?.ProfileImageURL,
          User2Image: User2Image,
        },
        authToken
      );

      setIsLoading(false);
      // document.getElementById("closeMessageDrawer").click();
      setShowCommanModal(true);
      setTimeout(() => {
        setShowCommanModal(false);
        navigate("/messages");
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  return (
    <>
      <span
        id="closeMessageDrawer"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></span>
      <CommanModal
        show={showCommanModal}
        onHide={() => setShowCommanModal(false)}
        type="Contact!"
        content="your successfully Contacted the username. they have received your message. He/she will contact with you as soon as possible"
      />
      <div className="message-drawer-main-wrapper">
        <div className="message-drawer-main-wrap">
          <div className="close-drawer-btn">
            <h3> Message</h3>
            <img
              id="messageDrawerRight"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              src="./images/icons/cross-icon.png"
              alt="cross icon"
            />
          </div>
          <div className="message-drawer-textarea-wrap">
            {errors.message ? (
              <label>
                <span className="error-message">{errors.message}</span>
              </label>
            ) : (
              ""
            )}
            <textarea
              className={errors.message ? "input-error" : ""}
              placeholder="Message here..."
              name="message"
              value={message}
              onChange={inputEventHandler}
            ></textarea>
          </div>
          <div className="message-drawer-button">
            <button
              onClick={sendMessageHandler}
              disabled={isLoading}
              className="green-button"
            >
              {isLoading ? (
                <div className="button-loading-gif">
                  <Lottie animationData={LoadingLottie} loop={true} />
                </div>
              ) : (
                "Send message"
              )}
            </button>
            {/* <p>
              Content here if any info
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageDrawer;

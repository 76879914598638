import React, { Fragment, useEffect, useState } from "react";
import "./dropdown.css";
import { servicePost } from "../../helper/api";
import { useSelector } from "react-redux";

const ProvinceDrop = ({
  onTypeChange,
  error,
  alreadySelected,
  allOption,
  country,
  type,
}) => {
  // get Data from Redux
  const alreadyData = useSelector(
    (state) => state.userReducer?.locationData && state.userReducer.locationData
  );
  const [stateList, setStateList] = useState([]);
  const [isStateActive, setIsStateActive] = useState(false);

  const [stateSelected, setStateSelected] = useState("Select Province");

  useEffect(() => {
    if (type == "Editpage") {
      setStateSelected(
        alreadySelected
          ? alreadySelected
          : country
          ? "Select Province"
          : "Select Province"
      );
    } else {
      setStateSelected(
        country
          ? "Select Province"
          : alreadySelected
          ? alreadySelected
          : "Select Province"
      );
    }
  }, [country]);

  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  const getStateList = async () => {
    try {
      const stateListRes = await servicePost(
        "state/getStateData",
        {
          country: country ? country : alreadyData.country,
        },
        authToken
      );
      setStateList(stateListRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getStateList();
  }, [country]);

  return (
    <>
      <div className="select-box-wrapper">
        {error ? (
          <label>
            <span className="error-message">{error}</span>
          </label>
        ) : (
          <label> Province*</label>
        )}
        <div className="react-dropdown">
          <div
            className={
              error ? "react-dropdown-btn input-error" : "react-dropdown-btn"
            }
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsStateActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsStateActive(false);
            }}
          >
            <div
              className={`selected-item ${
                stateSelected.startsWith("Select") ? "placeholderText" : ""
              }`}
            >
              <p>{stateSelected}</p>
            </div>

            <img
              className={
                isStateActive
                  ? "dropdown-down-arrow rotate-arrow"
                  : "dropdown-down-arrow"
              }
              src="/images/icons/nav-country-drop.svg"
              alt="arrow"
            />
          </div>
          <div
            className="dropdown-space-manage"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsStateActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsStateActive(false);
            }}
          ></div>
          {isStateActive && (
            <div
              className="dropdown-content"
              onMouseEnter={(e) => {
                e.stopPropagation();
                setIsStateActive(true);
              }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                setIsStateActive(false);
              }}
            >
              {allOption && (
                <Fragment>
                  <div
                    onClick={(e) => {
                      setStateSelected(allOption);
                      setIsStateActive(false);

                      onTypeChange(allOption);
                    }}
                    className="dropdown-item"
                  >
                    <div className="dropdown-options">
                      <p
                        className={`${
                          allOption === stateSelected
                            ? "option-active"
                            : "option-normal"
                        }`}
                      >
                        {allOption}
                      </p>
                    </div>
                  </div>
                </Fragment>
              )}
              {stateList &&
                stateList.map((option, index) => {
                  return (
                    <Fragment key={index}>
                      <div
                        onClick={(e) => {
                          setStateSelected(option.state);
                          setIsStateActive(false);

                          onTypeChange(option.state); // Call callback function
                        }}
                        className={
                          option.state === stateSelected
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                      >
                        <div className="dropdown-options">
                          <p
                            className={`${
                              option.state === stateSelected
                                ? "option-active"
                                : "option-normal"
                            }`}
                          >
                            {option.state}
                          </p>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProvinceDrop;

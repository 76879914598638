import React, { useEffect, useState } from "react";
import "./editprofile.css";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";
import CommanModal from "../modals/CommanModal";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { serviceImagePost, servicePost, servicePut } from "../../helper/api";

const EditProfile = () => {
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [userData, setUserData] = useState([]);

  const initialEmailShown =
    userData?.HideEmail !== undefined ? userData?.HideEmail : true;
  const [emailShownicon, setEmailShownicon] = useState(initialEmailShown);
  const [eamilShown, setEmailShown] = useState(false);
  const [typePassword, setShowTypePassword] = useState(false);
  const [showCommanModal, setShowCommanModal] = useState(false);
  const [selectedProvance, setSelectedProvance] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);

  // Get LogedIn User From LocalStorage
  const logedInUser = JSON.parse(localStorage.getItem("user"));
  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    email: "",
    address: "",
    number: "",
    farmname: "",
    website: "",
    instagram: "",
    facebook: "",
    twitter: "",
  });

  const {
    username,
    password,
    email,
    address,
    number,
    farmname,
    website,
    instagram,
    facebook,
    twitter,
  } = formData;

  // Error Validation
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    uploadedFiles: "",
  });

  const errorMessages = {
    username: "Username is required",
    password: "Verify your current password",
    uploadedFiles: "Please upload minimum 1 Image",
  };

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;
    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
    setErrors({ ...errors, [name]: "" });
  };

  const navigate = useNavigate();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    // Check if the file size exceeds the maximum size
    if (file && file.size > maxSize) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        uploadedFiles: "Some files exceed the maximum size of 5MB.",
      }));

      // Remove the error message after 5 seconds
      setTimeout(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          uploadedFiles: null,
        }));
      }, 3000);

      return;
    }

    if (file) {
      try {
        setImageUploadLoading(true);
        const formData = new FormData();
        formData.append("image", file);

        // Make the API call to upload the image and get the URL
        const response = await serviceImagePost(
          "post/uploadImageData",
          formData,
          authToken
        );

        setUploadedImage(response.Imageurl);
        setImageUploadLoading(false);
      } catch (error) {
        setImageUploadLoading(false);
        console.error("Error uploading image:", error);
      }
    }
  };

  const togglePassword = () => {
    setEmailShownicon(!userData?.HideEmail);
  };

  useEffect(() => {
    if (userData?.HideEmail !== undefined) {
      setEmailShownicon(userData.HideEmail);
    }
  }, [userData?.HideEmail]);

  const emailChangeHandler = () => {
    setEmailShown(true);
    setFormData({
      email: "",
    });
  };

  // Get User Detail By UserId
  const getUserDetail = async () => {
    try {
      const userRes = await servicePost(
        `user/getUser`,
        {
          id: logedInUser?.id,
        },
        authToken
      );
      setUserData(userRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  // Check if Values already exist
  useEffect(() => {
    if (userData) {
      setFormData({
        username: !userData.FirstName ? "" : userData.FirstName,
        email: !userData.Email ? "" : userData.Email,
        address: !userData.Address1 ? "" : userData.Address1,
        number: !userData.Phone ? "" : userData.Phone,
        farmname: !userData.FarmName ? "" : userData.FarmName,
        website: !userData.Website ? "" : userData.Website,
        instagram: !userData.Instagram ? "" : userData.Instagram,
        facebook: !userData.Facebook ? "" : userData.Facebook,
        twitter: !userData.Twitter ? "" : userData.Twitter,
      });

      setUploadedImage(
        !userData.ProfileImageURL ? "" : userData.ProfileImageURL
      );
    }
  }, [userData]);

  // Update user Profile Values and Image...
  const editProfileHandler = async () => {
    const requiredFields = ["username", "password"];
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field] || !formData[field].trim()) {
        newErrors[field] = errorMessages[field];
      } else if (field === "username") {
        const minLength = 3;
        const maxLength = 20;
        const titleLength = formData[field].trim().length;
        if (titleLength < minLength || titleLength > maxLength) {
          newErrors[field] = "username must be between 3 and 20 characters.";
        }
      }
    });

    // Check if there are any errors; if yes, update the state and return
    if (Object.keys(newErrors).length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
      return;
    }

    setIsLoading(true);
    try {
      const updatedProfile = await servicePut(
        "user/update/user",
        {
          id: logedInUser?.id,
          FirstName: username,
          Email: email,
          Address1: address,
          Phone: number,
          FarmName: farmname,
          Website: website,
          Instagram: instagram,
          Facebook: facebook,
          Twitter: twitter,
          ProfileImageURL: uploadedImage,
          HideEmail: emailShownicon,
          Password: password,
        },
        authToken
      );

      setIsLoading(false);
      setShowCommanModal(true);
      setTimeout(() => {
        setShowCommanModal(false);
        navigate("/profile");
      }, 2000);
    } catch (err) {
      setIsLoading(false);
      if (err.response.data.message == "Wrong Password") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "Wrong Password",
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "",
        }));
      }
      setIsLoading(false);
    }
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  return (
    <>
      <Helmet>
        <title> Edit Profile | Food Classifieds </title>
      </Helmet>
      <CommanModal
        show={showCommanModal}
        onHide={() => setShowCommanModal(false)}
        type="Changes!"
        content="you successfully saved your changes"
      />

      <div className="edit-profile-main-wrapper">
        <div className="edit-profile-top-ads">
          <img src="./images/preview-top-ad.png" alt="google ads image" />
          <p> Ads </p>
        </div>
        <div className="edit-profile-main-wrap container">
          <div className="edit-profile-left-wrap">
            <div
              className={
                errors.uploadedFiles
                  ? "change-profile-pic-wrapper input-error"
                  : "change-profile-pic-wrapper"
              }
            >
              {uploadedImage ? (
                <img src={uploadedImage} alt="Uploaded" />
              ) : (
                <img src="./images/icons/avtar.png" alt="Default image" />
              )}

              <div className="edit-profile-upload-input">
                <button className="green-bordered-button">
                  {imageUploadLoading
                    ? "Uploading..."
                    : "  Upload profile image"}
                </button>

                <input type="file" onChange={handleFileChange} />
              </div>
            </div>
            {errors.uploadedFiles && (
              <span className="error-message">{errors.uploadedFiles}</span>
            )}
          </div>

          <div className="edit-profile-right-wrap">
            <div className="edit-profile-inner-left">
              <div className="login-form-input-wrap">
                {errors.username ? (
                  <label>
                    <span className="error-message">{errors.username}</span>
                  </label>
                ) : (
                  <label> Username* </label>
                )}

                <input
                  className={errors.username ? "input-error" : ""}
                  type="text"
                  placeholder="Enter your username"
                  name="username"
                  value={username}
                  onChange={inputEventHandler}
                />
              </div>

              <div className="login-form-input-wrap forget-pass-email-input">
                <label className="email-hide-show-input">
                  Email Address
                  <span
                    tooltip="if you Click hide icon and save changes then your email not showing another user"
                    flow="up"
                  >
                    <img src="./images/icons/info-icon.svg" alt="info icon" />
                  </span>
                </label>
                <input
                  type="email"
                  placeholder="Enter your Email Address"
                  name="email"
                  onChange={inputEventHandler}
                  value={!eamilShown ? email : email}
                />
                <div className="send-otp-wrap email-show-hide-wrap">
                  <div className="password-icon">
                    {emailShownicon ? (
                      <img
                        onClick={() => setEmailShownicon(false)}
                        src="./images/icons/password-hide.svg"
                        alt="hide password icon"
                      />
                    ) : (
                      <img
                        onClick={() => setEmailShownicon(true)}
                        src="./images/icons/password-show.svg"
                        alt="password show icon"
                      />
                    )}
                  </div>
                  {/* <h3 onClick={emailChangeHandler}> Change </h3> */}
                </div>
              </div>

              {/* <div className="login-form-input-wrap forget-pass-email-input">
                <label> Password </label>
                <input
                  readOnly={!typePassword}
                  type={!typePassword ? "password" : "text"}
                  placeholder="Enter your new Password"
                  value={!typePassword ? 123342 : ""}
                />
                <div className="send-otp-wrap">
                  <h3 onClick={() => setShowTypePassword(true)}> Change </h3>
                </div>
              </div> */}

              <div className="login-form-input-wrap">
                <label> Street Address (optional) </label>
                <input
                  type="text"
                  placeholder="Enter your Address"
                  name="address"
                  value={address}
                  onChange={inputEventHandler}
                />
              </div>

              <div className="login-form-input-wrap">
                <label> Phone Number (optional) </label>
                <input
                  type="number"
                  placeholder="Enter your Phone number"
                  name="number"
                  value={number}
                  onChange={inputEventHandler}
                />
              </div>

              <div className="edit-profile-inner-right mobile-edit-profile-inner-right">
                <div className="login-form-input-wrap">
                  <label> Farm name </label>
                  <input
                    type="text"
                    placeholder="Enter Farm name if you have"
                    name="farmname"
                    value={farmname}
                    onChange={inputEventHandler}
                  />
                </div>

                <div className="login-form-divide-field-wrap">
                  <div className="login-form-input-wrap">
                    <label> Website </label>
                    <input
                      type="text"
                      placeholder="www."
                      name="website"
                      value={website}
                      onChange={inputEventHandler}
                    />
                  </div>

                  <div className="login-form-input-wrap">
                    <label> Instagram </label>
                    <input
                      type="text"
                      placeholder="https://"
                      name="instagram"
                      value={instagram}
                      onChange={inputEventHandler}
                    />
                  </div>
                </div>

                <div className="login-form-divide-field-wrap">
                  <div className="login-form-input-wrap">
                    <label> Facebook </label>
                    <input
                      type="text"
                      placeholder="https://"
                      name="facebook"
                      value={facebook}
                      onChange={inputEventHandler}
                    />
                  </div>

                  <div className="login-form-input-wrap">
                    <label> Twitter </label>
                    <input
                      type="text"
                      placeholder="https://"
                      name="twitter"
                      value={twitter}
                      onChange={inputEventHandler}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="edit-profile-preferences-wrapper">
                <h3> Verify your current password to save changes </h3>
                <div className="edit-profile-terms">
                  <label for="agreeTerms">
                    <input
                      className="accented"
                      type="checkbox"
                      id="pickup-checkbox"
                      name="pickup-checkbox"
                    />
                    Lorem Ipsum is simply dummy typesetting industry.
                  </label>
                </div>

                <div className="edit-profile-terms">
                  <label for="agreeTerms">
                    <input
                      className="accented"
                      type="checkbox"
                      id="pickup-checkbox"
                      name="pickup-checkbox"
                    />
                    Lorem Ipsum is dummy simply typesetting industry.
                  </label>
                </div>

                <div className="edit-profile-terms">
                  <label for="agreeTerms">
                    <input
                      className="accented"
                      type="checkbox"
                      id="pickup-checkbox"
                      name="pickup-checkbox"
                    />
                    Lorem Ipsum is simply typesetting industry.
                  </label>
                </div>
              </div> */}

              <div className="login-form-input-wrap farm-name-for-desktop">
                <label> Farm name </label>
                <input
                  type="text"
                  placeholder="Enter Farm name if you have"
                  name="farmname"
                  value={farmname}
                  onChange={inputEventHandler}
                />
              </div>

              <div className="edit-profile-subtitle">
                <h3> Verify your current password to save changes </h3>
              </div>

              <div className="login-form-input-wrap">
                {errors.password ? (
                  <label>
                    <span className="error-message">{errors.password}</span>
                  </label>
                ) : (
                  <label> Password* </label>
                )}

                <input
                  className={errors.password ? "input-error" : ""}
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  value={password}
                  onChange={inputEventHandler}
                />
              </div>

              <div className="edit-profile-buttons">
                <button
                  className="green-button"
                  disabled={isLoading}
                  onClick={editProfileHandler}
                >
                  {isLoading ? (
                    <div className="button-loading-gif">
                      <Lottie animationData={LoadingLottie} loop={true} />
                    </div>
                  ) : (
                    "Save Changes"
                  )}
                </button>
                <button className="green-bordered-button" onClick={goToProfile}>
                  Cancel
                </button>
              </div>
            </div>

            <div className="edit-profile-inner-right desktop-edit-profile-inner-right">
              <div className="login-form-input-wrap">
                <label> Website </label>
                <input
                  type="text"
                  placeholder="www."
                  name="website"
                  value={website}
                  onChange={inputEventHandler}
                />
              </div>

              <div className="login-form-input-wrap">
                <label> Instagram </label>
                <input
                  type="text"
                  placeholder="https://"
                  name="instagram"
                  value={instagram}
                  onChange={inputEventHandler}
                />
              </div>

              <div className="login-form-input-wrap">
                <label> Facebook </label>
                <input
                  type="text"
                  placeholder="https://"
                  name="facebook"
                  value={facebook}
                  onChange={inputEventHandler}
                />
              </div>

              <div className="login-form-input-wrap">
                <label> Twitter </label>
                <input
                  type="text"
                  placeholder="https://"
                  name="twitter"
                  value={twitter}
                  onChange={inputEventHandler}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="edit-profile-bottom-ads">
          <img src="./images/car-ads.png" alt="google-ads" />
          <p> Ads </p>
        </div>
      </div>
    </>
  );
};

export default EditProfile;

import React, { useState } from "react";
import "./register.css";
import { useNavigate } from "react-router-dom";
import ProductOffered from "../dropdown/ProductOffered";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";
import { servicePut } from "../../helper/api";

const StartJourney = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [productOffered, setProductOffered] = useState();

  // Get LogedIn User From LocalStorage
  const logedInUser = JSON.parse(localStorage.getItem("user"));
  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  const navigate = useNavigate();
  const handleProductChange = (product) => {
    setProductOffered(product);
  };

  const [formData, setFormData] = useState({
    farmname: "",
    website: "",
    instagram: "",
    facebook: "",
    twitter: "",
  });

  const { farmname, website, instagram, facebook, twitter } = formData;

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;
    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
  };

  // Update user Profile Values and Image...
  const startJourneyHandler = async () => {
    setIsLoading(true);
    try {
      const updatedProfile = await servicePut(
        "user/update/userInfo",
        {
          id: logedInUser?.id,
          ProductOffer: productOffered,
          FarmName: farmname,
          Website: website,
          Instagram: instagram,
          Facebook: facebook,
          Twitter: twitter,
        },
        authToken
      );
      console.log("updatedProfile", updatedProfile);
      navigate("/register-step-3");
    } catch (err) {
      console.log("err", err);
      setIsLoading(false);
    }
  };

  const goBack = () => {
    navigate("/register");
  };

  const goToNextStep = () => {
    navigate("/register-step-3");
  };

  return (
    <div className="register-main-wrapper">
      <div className="register-main-wrap">
        <div className="register-main-left-wrapper">
          <div className="register-main-left-wrap">
            <div className="register-header-content register-content-with-backbtn">
              <img
                onClick={goBack}
                className="back-arrow-icon"
                src="./images/icons/back-arrow.svg"
                alt="back arrow icon"
              />
              <div className="register-head-content">
                <h3> Start Your Journey </h3>
                <p> Please enter your details. </p>
              </div>
            </div>

            <div className="login-form-main-wrap">
              <form>
                <div className="login-form-input-wrap">
                  <ProductOffered onTypeChange={handleProductChange} />
                </div>

                {/* <div className="login-form-input-wrap">
                  <FarmLocation onTypeChange={handleLocationChange} />
                </div> */}

                <div className="login-form-input-wrap">
                  <label> Farm name </label>
                  <input
                    type="text"
                    placeholder="Enter Farm name if you have"
                    name="farmname"
                    value={farmname}
                    onChange={inputEventHandler}
                  />
                </div>

                <div className="login-form-input-wrap">
                  <label> Website </label>
                  <input
                    type="text"
                    placeholder="www."
                    name="website"
                    value={website}
                    onChange={inputEventHandler}
                  />
                </div>

                <div className="login-form-input-wrap">
                  <label> Instagram </label>
                  <input
                    type="text"
                    placeholder="https://"
                    name="instagram"
                    value={instagram}
                    onChange={inputEventHandler}
                  />
                </div>

                <div className="login-form-divide-field-wrap">
                  <div className="login-form-input-wrap">
                    <label> Facebook </label>
                    <input
                      type="text"
                      placeholder="https://"
                      name="facebook"
                      value={facebook}
                      onChange={inputEventHandler}
                    />
                  </div>

                  <div className="login-form-input-wrap">
                    <label> Twitter </label>
                    <input
                      type="text"
                      placeholder="https://"
                      name="twitter"
                      value={twitter}
                      onChange={inputEventHandler}
                    />
                  </div>
                </div>
              </form>

              <div className="login-button-main-wrap register-skip-button">
                <button onClick={startJourneyHandler} disabled={isLoading}>
                  {isLoading ? (
                    <div className="button-loading-gif">
                      <Lottie animationData={LoadingLottie} loop={true} />
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
                <button onClick={goToNextStep} className="login-cancel-button">
                  Skip
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="register-main-right-wrap">
          <img src="./images/register-img.png" alt="main image" />
        </div>
      </div>
    </div>
  );
};

export default StartJourney;

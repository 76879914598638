import React from "react";
import "./Privacy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="terms-main-page-wrapper">
        <div className="terms-main-page-wrap container">
          <div className="terms-header-wrap">
            <h3> Privacy Policy </h3>
          </div>
          <div className="terms-service-wrapper">
            <div className="terms-service-wrap">
              <h4> 1 Privacy Policy </h4>

              <div className="terms-content-wrapper">
                <p>
                  FoodClassifieds are committed to safeguarding personal
                  information. This FoodClassifieds Privacy Policy relates to
                  our use and collection of personal information relating to
                  clients of the Product platforms, including directors,
                  beneficial owners, principals, employees or consultants of our
                  institutional clients and vendors (you/your). <br /> <br />
                  With respect to CANADA, US clients, FoodClassifieds is the
                  data controller of your personal information processed under
                  this Privacy Policy.
                  <br />
                  <br /> <br /> This FoodClassifieds Privacy Policy provides you
                  with detail about how we use such personal information. We use
                  commercially reasonable physical, electronic and procedural
                  measures to protect people’s personal information in
                  accordance with data protection legislative requirements. This
                  Privacy Policy does not apply to anonymized data as it cannot
                  be used to identify you. <br /> <br />
                  FoodClassifieds will not use your personal information for
                  purposes other than those purposes we have disclosed to you in
                  this Privacy Policy without your permission. <br /> <br />
                  By visiting, accessing or using FoodClassifieds , you
                  acknowledge that we use your personal information as set out
                  in this Privacy Policy. <br /> <br />
                  1.1. This Privacy Policy is intended to explain our privacy
                  practices and covers the following areas: <br /> <br />
                  What personal information about you we may collect <br />{" "}
                  <br />
                  How we may use your personal information <br /> <br />
                  Whom we may disclose your personal information to <br />{" "}
                  <br />
                  Our use of automated decision-making <br /> <br />
                  How we protect your personal information <br /> <br />
                  How to contact us <br /> <br />
                  Your rights to prevent marketing and to access and update{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;

import React from "react";

const Pagination = ({ totalPages, currentPage, setCurrentPage }) => {
  const displayPages = 4; // Number of pages to display at once
  const delta = Math.floor(displayPages / 2);
  let startPage = Math.max(currentPage - delta, 1);
  let endPage = Math.min(currentPage + delta, totalPages);

  if (totalPages <= displayPages) {
    startPage = 1;
    endPage = totalPages;
  } else if (currentPage <= delta) {
    startPage = 1;
    endPage = displayPages;
  } else if (currentPage + delta >= totalPages) {
    startPage = totalPages - displayPages + 1;
    endPage = totalPages;
  }

  const pageButtons = [];
  for (let i = startPage; i <= endPage; i++) {
    pageButtons.push(
      <button
        key={i}
        onClick={() => setCurrentPage(i)}
        className={currentPage === i ? "active" : ""}
      >
        {i}
      </button>
    );
  }

  return (
    <div className="pagination-main-wrap">
      {currentPage > 1 && (
        <button
          className="pagination-left-btn"
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          {"<"}
        </button>
      )}
      {pageButtons}
      {currentPage < totalPages && (
        <button
          className="pagination-left-btn"
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          {">"}
        </button>
      )}
    </div>
  );
};

export default Pagination;

import React, { useEffect, useState } from "react";
import "./createpost.css";
import CategoryDrop from "../dropdown/CategoryDrop";
import SubCategoryDrop from "../dropdown/SubCategoryDrop";
import AmountVarations from "../dropdown/AmountVarations";
import QuantityVarations from "../dropdown/QuantityVarations";
import AvailabilityDrop from "../dropdown/AvailabilityDrop";
import ProvinceDrop from "../dropdown/ProvinceDrop";
import CityDropdown from "../dropdown/CityDropdown";
import PreferenceDrop from "../dropdown/PreferenceDrop";
import PickupTimeModal from "../modals/PickupTimeModal";
import DeliveryTimeModal from "../modals/DeliveryTimeModal";
import { useLocation, useNavigate } from "react-router-dom";
import DeilveryCost from "../dropdown/DeilveryCost";
import CancelModal from "../modals/CancelModal";
import { Helmet } from "react-helmet";
import { serviceImagePost } from "../../helper/api";
import CountryDropdown from "../dropdown/CountryDropdown";

const EditPost = () => {
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [emailChange, setEmailChange] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubcategory, setSelectedSubcategory] = useState();
  const [selectedAmountV, setSelectedAmountV] = useState();
  const [selectedQuantityV, setSelectedQuantityV] = useState();
  const [selectedAvailability, setSelectedAvailability] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedProvince, setSelectedProvince] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedPreference, setSelectedPreference] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deilveryCost, setDeiveryCost] = useState();
  const navigate = useNavigate();

  const location = useLocation();
  const data = location.state?.data;
  const actionType = location.state?.actionType;

  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  useEffect(() => {
    try {
      if (actionType === "createAd") {
        setUploadedFiles(data.uploadedFiles);
      } else {
        const parsedFiles = data?.uploadedFiles;
        if (Array.isArray(parsedFiles)) {
          setUploadedFiles(parsedFiles);
        }
      }
    } catch (error) {
      console.error("Error parsing uploaded files:", error);
    }
  }, [data.uploadedFiles]);

  // Get Selected Days and Time Pick up
  const [showPickupModal, setShowPickupModal] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [pickupChecked, setPickupChecked] = useState(false);

  const handlePickupChange = (e) => {
    setPickupChecked(e.target.checked);
  };

  // Get Selected Days and Time Delivery
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [selectedDeliveryDays, setSelectedDeliveryDays] = useState([]);
  const [openDeliveryTime, setOpenDeliveryTime] = useState("");
  const [closeDeliveryTime, setCloseDeliveryTime] = useState("");
  const [deliveryChecked, setDeliveryChecked] = useState(false);

  const handleDeliveryChange = (e) => {
    setDeliveryChecked(e.target.checked);
  };

  // get Valuse for Pick-up Modal
  const handleModalSave = (days, open, close) => {
    setSelectedDays(days);
    setOpenTime(open);
    setCloseTime(close);
    setShowPickupModal(false);
  };

  // get Valuse for Delivery Modal
  const handleDeliveryModalSave = (days, open, close) => {
    setSelectedDeliveryDays(days);
    setOpenDeliveryTime(open);
    setCloseDeliveryTime(close);
    setShowDeliveryModal(false);
  };

  // Error Validation
  const [errors, setErrors] = useState({
    title: "",
    price: "",
    quantity: "",
    selectedCategory: "",
    selectedSubcategory: "",
    selectedAvailability: "",
    selectedAmountV: "",
    selectedQuantityV: "",
    description: "",
    selectedProvince: "",
    selectedCity: "",
    selectedDays: "",
    selectedDeliveryDays: "",
    uploadedFiles: "",
  });

  const errorMessages = {
    title: "Title is required.",
    price: "Price is required.",
    quantity: "Quantity is required.",
    selectedCategory: "Category is required.",
    selectedSubcategory: "Sub-Category is required.",
    selectedAmountV: "Varations required.",
    selectedQuantityV: "Varations required.",
    selectedAvailability: "Availability is required.",
    description: "Description is required.",
    selectedProvince: "Province is required.",
    selectedCity: "City is required.",
    selectedDays: "Select Timing",
    selectedDeliveryDays: "Select Timing",
    uploadedFiles: "Please upload minimum 1 Image",
  };

  // User Bank detail
  const [formData, setFormData] = useState({
    title: "",
    price: "",
    quantity: "",
    specialOffer: "",
    websiteLink: "",
    description: "",
    certificate1: "",
    certificate2: "",
    certificate3: "",
    address: "",
    email: "",
    phoneNumber: "",
    // deilveryCost: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors({ ...errors, [name]: "" });
  };

  useEffect(() => {
    if (data) {
      setFormData({
        email: data?.email,
      });
    }
  }, []);

  const emailChangeHandler = () => {
    setEmailChange(true);
    setFormData({
      email: "",
    });
  };

  const {
    title,
    price,
    quantity,
    specialOffer,
    websiteLink,
    description,
    certificate1,
    certificate2,
    certificate3,
    address,
    email,
    phoneNumber,
    // deilveryCost,
  } = formData;

  // Handle Select Dropdown values
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleSubcategoryChange = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  const handleAmountVchange = (varation) => {
    setSelectedAmountV(varation);
  };

  const handleQuantityVchange = (varation) => {
    setSelectedQuantityV(varation);
  };

  const handleAvailablityVchange = (availability) => {
    setSelectedAvailability(availability);
  };

  const handleCountrychange = (country) => {
    setSelectedCountry(country);
    setSelectedProvince();
    setSelectedCity();
  };

  const handleProvincechange = (provice) => {
    setSelectedProvince(provice);
    setSelectedCity();
  };

  const handleCitychange = (provice) => {
    setSelectedCity(provice);
  };

  const handlePreferencechange = (prefer) => {
    setSelectedPreference(prefer);
  };

  const handleDeilveryCostcechange = (cost) => {
    setDeiveryCost(cost);
  };

  // If Url Then this handleFileUpload
  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    // Check each file's size
    const oversizedFiles = Array.from(files).filter(
      (file) => file.size > maxSize
    );

    if (oversizedFiles.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        uploadedFiles: "Some files exceed the maximum size of 5MB.",
      }));
    } else {
      // No oversized files, clear any previous error messages
      setErrors((prevErrors) => ({
        ...prevErrors,
        uploadedFiles: "",
      }));

      // Prepare to upload files and update the uploadedFiles state
      const uploadedImages = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("image", file);

        try {
          setImageUploadLoading(true);
          const response = await serviceImagePost(
            "post/uploadImageData",
            formData,
            authToken
          );
          setImageUploadLoading(false);
          uploadedImages.push(response.Imageurl);
        } catch (error) {
          setImageUploadLoading(false);
          console.error("Error uploading image:", error);
        }
      }

      // Update the uploadedFiles state with the uploaded image URLs
      setUploadedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        ...uploadedImages,
      ]);
    }
  };

  /// If Image Not Url Format Then HanleChnage

  const handleFilesUpload = (event) => {
    const files = event.target.files;
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    // Check each file's size and set error messages for files exceeding the size limit
    const oversizedFiles = Array.from(files).filter(
      (file) => file.size > maxSize
    );
    if (oversizedFiles.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        uploadedFiles: "Some files exceed the maximum size of 5MB.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        uploadedFiles: "",
      }));

      // Add files to the state if all files are within the size limit
      setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, ...files]);
    }
  };

  // Remove Upload product images
  const removeImage = (indexToRemove) => {
    setUploadedFiles((prevUploadedFiles) =>
      prevUploadedFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  // Mapping for abbreviated day names
  const dayAbbreviations = {
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
    Saturday: "Sat",
    Sunday: "Sun",
  };

  // Function to convert full day names to abbreviated names
  const getAbbreviatedDays = () => {
    if (selectedDays && selectedDays.length > 0) {
      return selectedDays.map((day) => dayAbbreviations[day]);
    } else if (data.selectedDays && data.selectedDays.length > 0) {
      return data.selectedDays.map((day) => dayAbbreviations[day]);
    } else {
      return [];
    }
  };

  const getAbbreviatedDeliveryDays = () => {
    if (selectedDeliveryDays && selectedDeliveryDays.length > 0) {
      return selectedDeliveryDays.map((day) => dayAbbreviations[day]);
    } else if (
      data.selectedDeliveryDays &&
      data.selectedDeliveryDays.length > 0
    ) {
      return data.selectedDeliveryDays.map((day) => dayAbbreviations[day]);
    } else {
      return [];
    }
  };

  // Convert time
  const convertTo12HourFormat = (time) => {
    const splitTime = time.split(":");
    let hours = parseInt(splitTime[0], 10);
    const minutes = splitTime[1];

    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Handle midnight (0 hours)

    return `${hours}:${minutes} ${amPm}`;
  };

  const previewData = {
    postId: data?.postId,
    selectedCategory: selectedCategory,
    selectedSubcategory: selectedSubcategory,
    selectedAvailability: selectedAvailability,
    selectedDays: selectedDays,
    selectedCountry: selectedCountry,
    selectedProvince: selectedProvince,
    selectedCity: selectedCity,
    selectedPreference: selectedPreference,
    pickupChecked: pickupChecked,
    deliveryChecked: deliveryChecked,
    openTime: openTime,
    closeTime: closeTime,
    selectedDeliveryDays: selectedDeliveryDays,
    openDeliveryTime: openDeliveryTime,
    closeDeliveryTime: closeDeliveryTime,
    selectedAmountV: selectedAmountV,
    selectedQuantityV: selectedQuantityV,
    uploadedFiles: uploadedFiles,
    title,
    price,
    quantity,
    specialOffer,
    websiteLink,
    description,
    certificate1,
    certificate2,
    certificate3,
    address,
    email,
    phoneNumber,
    deilveryCost,
  };

  const goToPreviewPage = () => {
    const requiredFields = ["title", "price", "quantity", "description"];
    const newErrors = {};
    let firstErrorField = null;

    requiredFields.forEach((field) => {
      if (!formData[field] || !formData[field].trim()) {
        newErrors[field] = errorMessages[field];

        if (!firstErrorField) {
          firstErrorField = field;
        }
      } else if (field === "title") {
        const minLength = 8;
        const maxLength = 64;
        const titleLength = formData[field].trim().length;
        if (titleLength < minLength || titleLength > maxLength) {
          newErrors[field] = "Title must be between 8 and 64 characters.";
          if (!firstErrorField) {
            firstErrorField = field;
          }
        }
      } else if (field === "description") {
        const minLength = 10;
        const maxLength = 350;
        const descLength = formData[field].trim().length;
        if (descLength < minLength || descLength > maxLength) {
          newErrors[field] =
            "Description must be between 10 and 350 characters.";
          if (!firstErrorField) {
            firstErrorField = field;
          }
        }
      }
    });

    // Check if Select dropdown are already chosen
    if (!selectedCategory) {
      newErrors["selectedCategory"] = errorMessages["selectedCategory"];
      if (!firstErrorField) {
        firstErrorField = "selectedCategory";
      }
    }
    if (!selectedSubcategory) {
      newErrors["selectedSubcategory"] = errorMessages["selectedSubcategory"];
      if (!firstErrorField) {
        firstErrorField = "selectedSubcategory";
      }
    }

    if (!selectedAmountV) {
      newErrors["selectedAmountV"] = errorMessages["selectedAmountV"];
      if (!firstErrorField) {
        firstErrorField = "selectedAmountV";
      }
    }

    if (!selectedQuantityV) {
      newErrors["selectedQuantityV"] = errorMessages["selectedQuantityV"];
      if (!firstErrorField) {
        firstErrorField = "selectedQuantityV";
      }
    }

    if (!selectedAvailability) {
      newErrors["selectedAvailability"] = errorMessages["selectedAvailability"];
      if (!firstErrorField) {
        firstErrorField = "selectedAvailability";
      }
    }

    if (!selectedProvince) {
      newErrors["selectedProvince"] = errorMessages["selectedProvince"];
      if (!firstErrorField) {
        firstErrorField = "selectedProvince";
      }
    }
    if (!selectedCity) {
      newErrors["selectedCity"] = errorMessages["selectedCity"];
      if (!firstErrorField) {
        firstErrorField = "selectedCity";
      }
    }

    // if (selectedDays?.length == 0) {
    //   newErrors["selectedDays"] = errorMessages["selectedDays"];
    // }

    // if (selectedDeliveryDays?.length == 0) {
    //   newErrors["selectedDeliveryDays"] = errorMessages["selectedDeliveryDays"];
    // }

    if (uploadedFiles?.length == 0) {
      newErrors["uploadedFiles"] = errorMessages["uploadedFiles"];
      if (!firstErrorField) {
        firstErrorField = "uploadedFiles";
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      navigate("/post-preview", {
        state: {
          data: previewData,
          actionType: actionType,
        },
      });
    } else if (firstErrorField) {
      const errorElement = document.getElementById(`${firstErrorField}`);
      if (errorElement) {
        const topOffset =
          errorElement.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: topOffset, behavior: "smooth" });
      }
    }
  };

  // Empty Error Select box Errors...
  useEffect(() => {
    const clearSelectBoxError = (field) => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "",
      }));
    };

    // Sent filed perametter to empty selected fields
    if (selectedCategory) {
      clearSelectBoxError("selectedCategory");
    }

    if (selectedSubcategory) {
      clearSelectBoxError("selectedSubcategory");
    }

    if (selectedAmountV) {
      clearSelectBoxError("selectedAmountV");
    }

    if (selectedQuantityV) {
      clearSelectBoxError("selectedQuantityV");
    }

    if (selectedAvailability) {
      clearSelectBoxError("selectedAvailability");
    }

    if (selectedProvince) {
      clearSelectBoxError("selectedProvince");
    }

    if (selectedCity) {
      clearSelectBoxError("selectedCity");
    }

    if (selectedDays) {
      clearSelectBoxError("selectedDays");
    }

    // if (selectedDeliveryDays) {
    //   clearSelectBoxError("selectedDeliveryDays");
    // }

    // if (uploadedFiles) {
    //   clearSelectBoxError("uploadedFiles");
    // }
  }, [
    selectedCategory,
    selectedSubcategory,
    selectedAmountV,
    selectedQuantityV,
    selectedAvailability,
    selectedProvince,
    selectedCity,
    // selectedDays,
    // selectedDeliveryDays,
    uploadedFiles,
  ]);

  const goToHomePage = () => {
    navigate("/");
  };

  // Handle key press events
  const handleKeyDown = (e) => {
    if (
      // Disallow 'e', '-', and '+'
      e.key === "e" ||
      e.key === "-" ||
      e.key === "+"
    ) {
      e.preventDefault();
    }
  };

  // Set Values If Already have
  useEffect(() => {
    if (data) {
      setFormData({
        title: !data.title ? "" : data.title,
        price: !data.price ? "" : data.price,
        quantity: !data.quantity ? "" : data.quantity,
        specialOffer: !data.specialOffer ? "" : data.specialOffer,
        websiteLink: !data.websiteLink ? "" : data.websiteLink,
        description: !data.description ? "" : data.description,
        certificate1: !data.certificate1 ? "" : data.certificate1,
        certificate2: !data.certificate2 ? "" : data.certificate2,
        certificate3: !data.certificate3 ? "" : data.certificate3,
        address: !data.address ? "" : data.address,
        email: !data.email ? "" : data.email,
        phoneNumber: !data.phoneNumber ? "" : data.phoneNumber,
      });

      // Set All DropDownValues
      // if (data.selectedCategory) {
      //   setSelectedCategory(data.selectedCategory);
      // }
      const {
        selectedCategory = null,
        selectedSubcategory = null,
        selectedCountry = null,
        selectedProvince = null,
        selectedCity = null,
        selectedDays = null,
        selectedDeliveryDays = null,
        selectedAmountV = null,
        selectedQuantityV = null,
        selectedAvailability = null,
        selectedPreference = null,
        deilveryCost = null,
        openTime = null,
        closeTime = null,
        openDeliveryTime = null,
        closeDeliveryTime = null,
        pickupChecked = null,
        deliveryChecked = null,
      } = data;

      setSelectedCategory(selectedCategory);
      setSelectedSubcategory(selectedSubcategory);
      setSelectedProvince(selectedProvince);
      setSelectedCountry(selectedCountry);
      setSelectedCity(selectedCity);
      setSelectedDays(selectedDays);
      setSelectedDeliveryDays(selectedDeliveryDays);
      setSelectedAmountV(selectedAmountV);
      setSelectedQuantityV(selectedQuantityV);
      setSelectedAvailability(selectedAvailability);
      setSelectedPreference(selectedPreference);
      setDeiveryCost(deilveryCost);
      setOpenTime(openTime);
      setCloseTime(closeTime);
      setOpenDeliveryTime(openDeliveryTime);
      setCloseDeliveryTime(closeDeliveryTime);
      setPickupChecked(pickupChecked);
      setDeliveryChecked(deliveryChecked);
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title> Create Post | Food Classifieds </title>
      </Helmet>

      <PickupTimeModal
        show={showPickupModal}
        onHide={() => setShowPickupModal(false)}
        onSave={handleModalSave}
        alreadySelected={data.selectedDays}
        preOpenTime={data.openTime}
        preCloseTime={data.closeTime}
      />
      <DeliveryTimeModal
        show={showDeliveryModal}
        onHide={() => setShowDeliveryModal(false)}
        onSave={handleDeliveryModalSave}
        alreadySelected={data.selectedDeliveryDays}
        preOpenTime={data.openDeliveryTime}
        preCloseTime={data.closeDeliveryTime}
      />

      <CancelModal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
      />
      <div className="create-post-main-wrapper">
        <div className="create-post-main-wrap container">
          <div className="create-post-first-step-wrapper">
            <div className="create-post-setp-wrap">
              <div className="create-post-head-content">
                <h3> Details</h3>
              </div>
              <div className="login-form-input-wrap">
                {errors.title ? (
                  <label>
                    <span className="error-message">{errors.title}</span>{" "}
                  </label>
                ) : (
                  <label> Advertisment Title* </label>
                )}

                <input
                  className={errors.title ? "input-error" : ""}
                  type="text"
                  placeholder="Enter title"
                  id="title"
                  name="title"
                  onChange={handleInputChange}
                  value={title}
                  minLength={8}
                  maxLength={64}
                />
              </div>

              <div className="login-form-input-wrap" id="selectedCategory">
                <CategoryDrop
                  onTypeChange={handleCategoryChange}
                  error={errors.selectedCategory}
                  alreadySelected={data.selectedCategory}
                />
              </div>
              <div className="login-form-input-wrap" id="selectedSubcategory">
                <SubCategoryDrop
                  onTypeChange={handleSubcategoryChange}
                  error={errors.selectedSubcategory}
                  alreadySelected={data.selectedSubcategory}
                  category={selectedCategory}
                />
              </div>
              <div className="create-post-head-sub-content">
                <p> Pricing</p>
              </div>
              <div className="login-form-divide-field-wrap create-post-divide-wrap">
                <div className="login-form-input-wrap">
                  {errors.price ? (
                    <label>
                      <span className="error-message">{errors.price}</span>{" "}
                    </label>
                  ) : (
                    <label> Product price* </label>
                  )}

                  <input
                    className={errors.price ? "input-error" : ""}
                    type="number"
                    placeholder="$...."
                    id="price"
                    name="price"
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    value={price}
                  />
                </div>

                <div className="login-form-input-wrap" id="selectedAmountV">
                  <AmountVarations
                    onTypeChange={handleAmountVchange}
                    alreadySelected={data.selectedAmountV}
                  />
                </div>
              </div>

              <div className="login-form-divide-field-wrap create-post-divide-wrap">
                <div className="login-form-input-wrap">
                  {errors.quantity ? (
                    <label>
                      <span className="error-message">{errors.quantity}</span>
                    </label>
                  ) : (
                    <label> Quantity* </label>
                  )}

                  <input
                    className={errors.quantity ? "input-error" : ""}
                    type="number"
                    placeholder="...."
                    id="quantity"
                    name="quantity"
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    value={quantity}
                  />
                </div>

                <div className="login-form-input-wrap" id="selectedQuantityV">
                  <QuantityVarations
                    onTypeChange={handleQuantityVchange}
                    alreadySelected={data.selectedQuantityV}
                  />
                </div>
              </div>
              <div className="login-form-input-wrap" id="selectedAvailability">
                <AvailabilityDrop
                  onTypeChange={handleAvailablityVchange}
                  alreadySelected={data.selectedAvailability}
                />
              </div>
              <div className="login-form-input-wrap">
                <label>Special Offer</label>
                <input
                  type="text"
                  placeholder="Enter your special offer"
                  name="specialOffer"
                  onChange={handleInputChange}
                  value={specialOffer}
                />
              </div>
              <div className="login-form-input-wrap">
                <label> Website/Product link</label>
                <input
                  type="text"
                  placeholder="www. or https://"
                  name="websiteLink"
                  onChange={handleInputChange}
                  value={websiteLink}
                />
              </div>
            </div>
          </div>

          {/******************* Second Step  */}
          <div className="create-post-first-step-wrapper">
            <div className="create-post-setp-wrap">
              <div className="create-post-head-content">
                <h3> Images & Pricing</h3>
              </div>
              <div className="login-form-input-wrap login-form-textarea-wrap">
                {errors.description ? (
                  <label>
                    <span className="error-message">{errors.description}</span>{" "}
                  </label>
                ) : (
                  <label> Description* </label>
                )}
                <textarea
                  className={errors.description ? "input-error" : ""}
                  placeholder="Type..."
                  id="description"
                  name="description"
                  onChange={handleInputChange}
                  value={description}
                  minLength={10}
                  maxLength={350}
                ></textarea>
              </div>

              <div className="create-post-image-content" id="uploadedFiles">
                <p>
                  Add photos to attract interest to your ad* (product images)
                </p>
                {errors.uploadedFiles ? (
                  <span className="error-message">{errors.uploadedFiles}</span>
                ) : (
                  <span>
                    Upload max 3 images. (we recommend size of imagesat least
                    1000 x 1000px)
                  </span>
                )}
              </div>
              {actionType === "createAd" ? (
                <div className="upload-image-main-wrapper">
                  {Array.from(Array(3)).map((_, index) => (
                    <>
                      {uploadedFiles[index] ? (
                        <div className="upload-verify-nft-wrap" key={index}>
                          <img
                            src={URL.createObjectURL(uploadedFiles[index])}
                            alt="uploaded"
                          />

                          {index === 0 && (
                            <div className="main-image-tag-wrap">
                              <p> Main image</p>
                            </div>
                          )}
                          <div
                            className="cross-image-wrap"
                            onClick={() => removeImage(index)}
                          >
                            <img
                              src="./images/icons/img-cross.png"
                              alt="close icon"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ))}

                  {uploadedFiles?.length < 3 && (
                    <div
                      className={
                        errors.uploadedFiles
                          ? "upload-nft-box-wrapper upload-image-error"
                          : "upload-nft-box-wrapper"
                      }
                    >
                      <div className="upload-nft-box-wrap">
                        <img
                          src="./images/icons/plus-icon.svg"
                          alt="upload icon"
                        />
                        <p> Upload image </p>
                        <input type="file" onChange={handleFilesUpload} />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="upload-image-main-wrapper">
                  {uploadedFiles.map((url, index) => (
                    <div className="upload-verify-nft-wrap" key={index}>
                      <img src={url} alt={`Uploaded ${index}`} />
                      {index === 0 && (
                        <div className="main-image-tag-wrap">
                          <p>Main image</p>
                        </div>
                      )}
                      <div
                        className="cross-image-wrap"
                        onClick={() => removeImage(index)}
                      >
                        <img
                          src="./images/icons/img-cross.png"
                          alt="close icon"
                        />
                      </div>
                    </div>
                  ))}
                  {uploadedFiles.length < 3 && (
                    <div className="upload-nft-box-wrapper">
                      <div className="upload-nft-box-wrap">
                        {imageUploadLoading ? (
                          <p> Uploading...</p>
                        ) : (
                          <>
                            <img
                              src="./images/icons/plus-icon.svg"
                              alt="upload icon"
                            />
                            <p>Upload image</p>
                          </>
                        )}

                        <input type="file" onChange={handleFileUpload} />
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="create-post-head-sub-content">
                <p> Certifications</p>
              </div>
              <div className="login-form-input-wrap">
                <label> Certificate </label>
                <input
                  type="text"
                  placeholder="Type here"
                  name="certificate1"
                  onChange={handleInputChange}
                  value={certificate1}
                  minLength={2}
                  maxLength={20}
                />
              </div>
              <div className="login-form-input-wrap">
                <label> Certificate </label>
                <input
                  type="text"
                  placeholder="Type here"
                  name="certificate2"
                  onChange={handleInputChange}
                  value={certificate2}
                  minLength={2}
                  maxLength={20}
                />
              </div>
              <div className="login-form-input-wrap">
                <label> Certificate </label>
                <input
                  type="text"
                  placeholder="Type here"
                  name="certificate3"
                  onChange={handleInputChange}
                  value={certificate3}
                  minLength={2}
                  maxLength={20}
                />
              </div>

              <div className="login-form-input-wrap desktop-create-post-btn">
                <div className="login-button-main-wrap preview-button-wrap">
                  <button onClick={goToPreviewPage}> Preview </button>

                  {actionType == "userEditPost" ? (
                    <button
                      onClick={() => setShowCancelModal(true)}
                      className="login-cancel-button"
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      onClick={goToHomePage}
                      className="login-cancel-button"
                    >
                      Skip
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/************** third Step */}
          <div className="create-post-first-step-wrapper">
            <div className="create-post-setp-wrap">
              <div className="create-post-head-content">
                <h3> Delivery Details</h3>
              </div>

              <div className="login-form-divide-field-wrap create-post-divide-wrap">
                <div className="login-form-input-wrap" id="selectedCountry">
                  <CountryDropdown
                    onTypeChange={handleCountrychange}
                    editSelected={data.selectedCountry}
                  />
                </div>
                <div className="login-form-input-wrap" id="selectedProvince">
                  <ProvinceDrop
                    onTypeChange={handleProvincechange}
                    error={errors.selectedProvince}
                    alreadySelected={selectedProvince}
                    country={selectedCountry}
                    type="Editpage"
                  />
                </div>
              </div>
              <div className="login-form-divide-field-wrap create-post-divide-wrap">
                <div className="login-form-input-wrap" id="selectedCity">
                  <CityDropdown
                    onTypeChange={handleCitychange}
                    error={errors.selectedCity}
                    alreadySelected={selectedCity}
                    state={selectedProvince}
                    country={selectedCountry}
                  />
                </div>
                <div className="login-form-input-wrap">
                  <label> Address </label>
                  <input
                    type="text"
                    placeholder="Type Full address"
                    name="address"
                    onChange={handleInputChange}
                    value={address}
                    minLength={5}
                    maxLength={50}
                  />
                </div>
              </div>

              <div className="login-form-input-wrap forget-pass-email-input">
                <label> Email </label>

                {/* Show default register email firstly */}
                <input
                  type="email"
                  placeholder="Enter your email address"
                  name="email"
                  onChange={handleInputChange}
                  value={emailChange ? email : email}
                />
                <div className="send-otp-wrap">
                  <h3 onClick={emailChangeHandler}> Change </h3>
                </div>
              </div>

              <div className="login-form-divide-field-wrap create-post-divide-wrap">
                <div className="login-form-input-wrap">
                  <label> Phone number </label>
                  <input
                    type="number"
                    placeholder="+647-717-9950"
                    name="phoneNumber"
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                    value={phoneNumber}
                  />
                </div>

                <div className="login-form-input-wrap">
                  <PreferenceDrop
                    onTypeChange={handlePreferencechange}
                    alreadySelected={data.selectedPreference}
                  />
                </div>
              </div>

              <div className="pick-up-checkbox-wrap">
                <label for="agreeTerms">
                  Pick up
                  <input
                    className="accented"
                    type="checkbox"
                    id="pickup-checkbox"
                    name="pickup-checkbox"
                    checked={pickupChecked}
                    onChange={handlePickupChange}
                  />
                </label>
                {pickupChecked && (
                  <div className="pick-up-edit">
                    <img
                      onClick={() => setShowPickupModal(true)}
                      src="./images/icons/edit-icon.png"
                      alt="edit icon"
                    />
                  </div>
                )}
              </div>
              <div className="login-form-input-wrap">
                {errors.selectedDays ? (
                  <label>
                    <span className="error-message">{errors.selectedDays}</span>{" "}
                  </label>
                ) : (
                  <label> Timing </label>
                )}
                <div
                  className={
                    errors.selectedDays
                      ? "input-error pick-up-timming-wrap"
                      : "pick-up-timming-wrap"
                  }
                >
                  <h4
                    className={
                      data.selectedDays?.length == 0 ? "placeholderText" : ""
                    }
                  >
                    {data.selectedDays?.length > 0
                      ? getAbbreviatedDays().join(", ")
                      : "Days/time"}
                  </h4>

                  <p>
                    {openTime ? convertTo12HourFormat(openTime) : ""}{" "}
                    {openTime ? "-" : ""}{" "}
                    {closeTime ? convertTo12HourFormat(closeTime) : ""}
                  </p>
                </div>
              </div>

              <div className="pick-up-checkbox-wrap">
                <label for="agreeTerms">
                  Delivery
                  <input
                    className="accented"
                    type="checkbox"
                    id="delivery-checkbox"
                    name="delivery-checkbox"
                    checked={deliveryChecked}
                    onChange={handleDeliveryChange}
                  />
                </label>
                {deliveryChecked && (
                  <div className="pick-up-edit">
                    <img
                      onClick={() => setShowDeliveryModal(true)}
                      src="./images/icons/edit-icon.png"
                      alt="edit icon"
                    />
                  </div>
                )}
              </div>
              <div className="login-form-input-wrap">
                {errors.selectedDeliveryDays ? (
                  <label>
                    <span className="error-message">
                      {errors.selectedDeliveryDays}
                    </span>{" "}
                  </label>
                ) : (
                  <label> Timing </label>
                )}

                <div
                  className={
                    errors.selectedDeliveryDays
                      ? "input-error pick-up-timming-wrap"
                      : "pick-up-timming-wrap"
                  }
                >
                  <h4
                    className={
                      data.selectedDeliveryDays?.length == 0
                        ? "placeholderText"
                        : ""
                    }
                  >
                    {data.selectedDeliveryDays?.length > 0
                      ? getAbbreviatedDeliveryDays().join(", ")
                      : "Days/time"}
                  </h4>

                  <p>
                    {openDeliveryTime
                      ? convertTo12HourFormat(openDeliveryTime)
                      : ""}{" "}
                    {openDeliveryTime ? "-" : ""}{" "}
                    {closeDeliveryTime
                      ? convertTo12HourFormat(closeDeliveryTime)
                      : ""}
                  </p>
                </div>
              </div>

              <div className="login-form-input-wrap">
                {/* <label> Cost for Deilvery</label>
                <input
                  type="number"
                  placeholder="$...."
                  name="deilveryCost"
                  onKeyDown={handleKeyDown}
                  onChange={handleInputChange}
                  value={deilveryCost}
                /> */}
                <DeilveryCost
                  onTypeChange={handleDeilveryCostcechange}
                  alreadySelected={data.deilveryCost}
                />
              </div>

              <div className="login-form-input-wrap mobile-create-post-btn">
                <div className="login-button-main-wrap preview-button-wrap">
                  <button onClick={goToPreviewPage}> Preview </button>
                  <button
                    onClick={goToHomePage}
                    className="login-cancel-button"
                  >
                    Skip
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPost;

import React, { useEffect, useState } from "react";
import FilterModal from "../modals/FilterModal";
import "./profile.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { servicePost } from "../../helper/api";
import { normalizeResponse } from "../../helper/normalizeResponse";
import moment from "moment";
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const Profile = () => {
  const [userPosts, setUserPosts] = useState([]);
  const [userData, setUserData] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [navActive, setNavActive] = useState("Myads");
  const [visibleCount, setVisibleCount] = useState(4); // Number of initially visible items
  const [adsAvalible, setAdsAvalible] = useState([]);
  const [showShare, setShowShare] = useState(false);

  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  // Get Values form Filter modal
  const [formData, setFormData] = useState({
    category: "",
    subcategory: "",
    province: "",
    city: "",
    priceFrom: "",
    priceTo: "",
    quantity: "",
    QuantityVarations: "",
    sortby: "",
  });

  const {
    category,
    subcategory,
    province,
    city,
    priceFrom,
    priceTo,
    quantity,
    QuantityVarations,
    sortby,
  } = formData;

  // NormalizeData and Loop throw
  const normalizedData = normalizeResponse(userPosts);
  const itemsToShow = normalizedData?.slice(0, visibleCount);

  // Get LogedIn User From LocalStorage
  const logedInUser = JSON.parse(localStorage.getItem("user"));

  const handleShowMore = () => {
    const increment = 4; // Increment by 3 items
    setVisibleCount((prevCount) => prevCount + increment);
  };

  const navigate = useNavigate();

  const goToDeletePost = (data) => {
    navigate("/post-preview", {
      state: {
        data: data,
        actionType: "Delete",
      },
    });
  };

  const goToEditProfile = () => {
    navigate("/edit-profile");
  };

  const goToPreviewAd = (data) => {
    navigate("/post-preview", {
      state: {
        data: data,
        actionType: "previewOwnAd",
      },
    });
  };

  const goToEditPost = (data) => {
    navigate("/edit-post", {
      state: {
        data: data,
        actionType: "userEditPost",
      },
    });
  };

  // Get User Detail By UserId
  const getUserDetail = async () => {
    try {
      const userRes = await servicePost(
        `user/getUser`,
        {
          id: logedInUser?.id,
        },
        authToken
      );

      setUserData(userRes.data);
    } catch (error) {}
  };

  useEffect(() => {
    getUserDetail();
  }, [logedInUser?.id]);

  // get Valuse for Pick-up Modal
  const handleModalSave = (
    category,
    subcategory,
    province,
    city,
    priceFrom,
    priceTo,
    quantity,
    QuantityVarations,
    sortby
  ) => {
    setFormData({
      category: category == "All" ? "" : category,
      subcategory: subcategory == "All" ? "" : subcategory,
      province: province == "All" ? "" : province,
      city: city == "All" ? "" : city,
      priceFrom: priceFrom,
      priceTo: priceTo,
      quantity: quantity,
      QuantityVarations: QuantityVarations == "All" ? "" : QuantityVarations,
      sortby: sortby,
    });

    setShowFilterModal(false);
  };

  const getUserPosts = async () => {
    try {
      const userPostsRes = await servicePost(
        "post/getFilterData",
        {
          Category: category,
          SubCategory: subcategory,
          Province: province,
          City: city,
          PriceFrom: priceFrom,
          PriceTo: priceTo,
          Quantity: quantity,
          QuantityVariation: QuantityVarations,
          SortBy: sortby,
          AccountId: logedInUser?.id?.toString(),
        },
        authToken
      );
      setUserPosts(userPostsRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserPosts();
  }, [logedInUser?.id, formData]);

  const shareUrl = "https://foodclassifieds.com/profile";
  const title = "Food Classifieds";

  return (
    <>
      <Helmet>
        <title> Profile | Food Classifieds </title>
      </Helmet>
      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        onSave={handleModalSave}
      />

      <div className="profile-main-wrapper">
        <div className="profile-main-wrap container">
          <div className="profile-top-ad-wrap">
            <img src="./images/preview-top-ad.png" alt="preview image" />
            <p> Ads </p>
          </div>

          <div className="profile-detail-main-wrapper">
            <div className="profile-detail-main-wrap">
              <div className="profile-detail-left">
                <div className="profile-preview-wrap">
                  <div className="profile-page-profile-image">
                    {userData?.ProfileImageURL ? (
                      <img src={userData?.ProfileImageURL} alt="profile-img" />
                    ) : (
                      <img src="./images/icons/avtar.png" alt="user profile" />
                    )}
                  </div>
                  <div className="profile-page-info-wrapper">
                    {userData?.FirstName ? (
                      <h3>
                        {userData?.FirstName}
                        {userData?.FarmName ? "/" : ""}
                        <span>{userData?.FarmName}</span>
                      </h3>
                    ) : (
                      <h3> {userData?.Email?.slice(0, 7)}... </h3>
                    )}

                    <div className="profile-user-location">
                      <img src="./images/icons/location.png" alt="icon" />
                      <p> Canada </p>
                    </div>
                    <div className="profile-user-location">
                      <img
                        src="./images/icons/email-icon.png"
                        alt="email icon"
                      />
                      {userData?.HideEmail ? (
                        <p> ******* </p>
                      ) : (
                        <p> {userData?.Email} </p>
                      )}
                    </div>
                    <div className="profile-socail-icons">
                      {userData?.Website && (
                        <a href={userData?.Website} target="_blank">
                          <img
                            src="./images/icons/website.svg"
                            alt="website icon"
                          />
                        </a>
                      )}

                      {userData?.Instagram && (
                        <a href={userData?.Instagram} target="_blank">
                          <img
                            src="./images/icons/insta.svg"
                            alt="insta icon"
                          />
                        </a>
                      )}

                      {userData?.Facebook && (
                        <a href={userData?.Facebook} target="_blank">
                          <img
                            src="./images/icons/facebook.svg"
                            alt="facebook icon"
                          />
                        </a>
                      )}

                      {userData?.Twitter && (
                        <a href={userData?.Twitter} target="_blank">
                          <img
                            src="./images/icons/twitter.svg"
                            alt="twitter icon"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className="profile-share-wrap">
                  <div className="profile-user-location">
                    <img src="./images/icons/share.png" alt="share icon" />
                    <p> Share Profile</p>
                  </div>
                </div> */}
                <div className="profile-share-wrap">
                  <div
                    className="profile-user-location"
                    onMouseEnter={() => setShowShare(true)}
                    onMouseLeave={() => setShowShare(false)}
                  >
                    <img src="./images/icons/share.png" alt="share icon" />
                    <p> Share Profile</p>
                  </div>

                  <div
                    className="share-btn-space-manage"
                    onMouseEnter={() => setShowShare(true)}
                    onMouseLeave={() => setShowShare(false)}
                  ></div>

                  {showShare && (
                    <div
                      className="report-button-wrap"
                      onMouseEnter={() => setShowShare(true)}
                      onMouseLeave={() => setShowShare(false)}
                    >
                      <div className="share-button-wrapper">
                        <div className="share-icon-wrap">
                          <InstapaperShareButton url={shareUrl} quote={title}>
                            <img src="./images/icons/insta.svg" alt="icon" />
                          </InstapaperShareButton>
                          <FacebookShareButton url={shareUrl} quote={title}>
                            <img src="./images/icons/facebook.svg" alt="icon" />
                          </FacebookShareButton>
                          <TwitterShareButton url={shareUrl} quote={title}>
                            <img src="./images/icons/twitter.svg" alt="icon" />
                          </TwitterShareButton>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="profile-detail-right">
                <button
                  className="green-bordered-button"
                  onClick={goToEditProfile}
                >
                  Edit Profile
                </button>
              </div>
            </div>
          </div>

          <div className="profile-product-items-wrapper">
            <div className="profile-product-filter-wrap">
              <div className="profile-filter-left-wrap">
                <div
                  onClick={() => setNavActive("Myads")}
                  className={
                    navActive === "Myads"
                      ? "profile-my-ads-wrap active"
                      : "profile-my-ads-wrap"
                  }
                >
                  <p> My ads </p>
                  <div className="nav-dot-wrap"></div>
                </div>

                <div
                  onClick={() => setNavActive("ExpireAds")}
                  className={
                    navActive === "ExpireAds"
                      ? "profile-my-ads-wrap active"
                      : "profile-my-ads-wrap"
                  }
                >
                  <p> Expire ads </p>
                  <div className="nav-dot-wrap"></div>
                </div>
              </div>
              <div className="profile-filter-right-wrap">
                <button
                  className="green-button"
                  onClick={() => setShowFilterModal(true)}
                >
                  <img src="./images/icons/filter-icon.png" alt="filter icon" />{" "}
                  Filter
                </button>
              </div>
            </div>

            <div className="profile-item-table-wrapper desktop-view-profile-table">
              {itemsToShow?.filter((data) => {
                const currentDate = moment(); // Current date and time
                const expiryDate = moment(data.ExpiryDate); // Expiry date from your data
                const daysDifference = expiryDate.diff(currentDate, "days"); // Get the difference in days

                if (navActive === "ExpireAds") {
                  return daysDifference < 0; // Filter only expired data
                } else if (navActive === "Myads") {
                  return daysDifference >= 0;
                }
              }).length === 0 ? (
                <>
                  <div className="empty-icon-wrap">
                    <img src="./images/icons/empty-icon.svg" alt="empty-icon" />
                    <p> No Data Found </p>
                  </div>
                </>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Media</th>
                      <th className="ptofile-table-title">Title</th>
                      <th>Price</th>
                      <th>Expires</th>
                      <th>Views</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemsToShow
                      ?.filter((data) => {
                        const currentDate = moment(); // Current date and time
                        const expiryDate = moment(data.ExpiryDate); // Expiry date from your data
                        const daysDifference = expiryDate.diff(
                          currentDate,
                          "days"
                        ); // Get the difference in days

                        if (navActive === "ExpireAds") {
                          return daysDifference < 0; // Filter only expired data
                        } else if (navActive === "Myads") {
                          return daysDifference >= 0;
                        }
                      })
                      .map((data, index) => {
                        const currentDate = moment(); // Current date and time
                        const expiryDate = moment(data.ExpiryDate); // Expiry date from your data
                        const daysDifference = expiryDate.diff(
                          currentDate,
                          "days"
                        ); // Get the difference in days

                        let displayText;

                        if (daysDifference > 0) {
                          displayText =
                            daysDifference === 1
                              ? "1 day left"
                              : `${daysDifference} days left`;
                        } else if (daysDifference === 0) {
                          displayText = "Expires today";
                        } else {
                          displayText = "Expired";
                        }
                        return (
                          <tr>
                            <td>
                              <div
                                key={index}
                                className="table-product-image"
                                onClick={() => goToPreviewAd(data)}
                              >
                                {data?.uploadedFiles &&
                                  data.uploadedFiles.length > 0 && (
                                    <img
                                      src={data.uploadedFiles[0]}
                                      alt="product image"
                                    />
                                  )}
                              </div>
                            </td>
                            <td>
                              <div className="table-product-title-wrap">
                                <h3> {data?.title}</h3>
                                <p>
                                  {data?.description?.length > 100
                                    ? `${data?.description?.slice(0, 100)}...`
                                    : data?.description}
                                  {data?.description.length > 100 && (
                                    <span onClick={() => goToPreviewAd(data)}>
                                      Read more
                                    </span>
                                  )}
                                </p>
                              </div>
                            </td>
                            <td>
                              ${data?.price}/
                              <span className="selected-amount-v">
                                {data?.selectedAmountV}
                              </span>
                            </td>
                            <td>{displayText}</td>
                            <td>{data?.Views}</td>
                            <td>
                              <div className="table-actions-wrap">
                                <img
                                  onClick={() => goToEditPost(data)}
                                  src="./images/icons/edit.png"
                                  alt="edit icon"
                                />
                                <img
                                  onClick={() => goToDeletePost(data)}
                                  src="./images/icons/delete-icon.png"
                                  alt="delete icon"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}

              {itemsToShow?.filter((data) => {
                const currentDate = moment(); // Current date and time
                const expiryDate = moment(data.ExpiryDate); // Expiry date from your data
                const daysDifference = expiryDate.diff(currentDate, "days"); // Get the difference in days

                if (navActive === "ExpireAds") {
                  return daysDifference < 0; // Filter only expired data
                } else if (navActive === "Myads") {
                  return daysDifference >= 0;
                }
              }).length > 0
                ? visibleCount < normalizedData.length && (
                    <div className="table-show-more" onClick={handleShowMore}>
                      <h3> Show more</h3>
                    </div>
                  )
                : ""}
            </div>

            {/*************** Mobile View table  */}
            <div className="profile-item-table-wrapper mobile-view-profile-table">
              {itemsToShow?.filter((data) => {
                const currentDate = moment(); // Current date and time
                const expiryDate = moment(data.ExpiryDate); // Expiry date from your data
                const daysDifference = expiryDate.diff(currentDate, "days"); // Get the difference in days

                if (navActive === "ExpireAds") {
                  return daysDifference < 0; // Filter only expired data
                } else if (navActive === "Myads") {
                  return daysDifference >= 0;
                }
              }).length === 0 ? (
                <>
                  <div className="empty-icon-wrap">
                    <img src="./images/icons/empty-icon.svg" alt="empty-icon" />
                    <p> No Data Found </p>
                  </div>
                </>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Media</th>
                      <th className="ptofile-table-title">Title</th>
                      <th>Price</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemsToShow
                      ?.filter((data) => {
                        const currentDate = moment(); // Current date and time
                        const expiryDate = moment(data.ExpiryDate); // Expiry date from your data
                        const daysDifference = expiryDate.diff(
                          currentDate,
                          "days"
                        ); // Get the difference in days

                        if (navActive === "ExpireAds") {
                          return daysDifference < 0; // Filter only expired data
                        } else if (navActive === "Myads") {
                          return daysDifference >= 0;
                        }
                      })
                      .map((data, index) => {
                        const currentDate = moment(); // Current date and time
                        const expiryDate = moment(data.ExpiryDate); // Expiry date from your data

                        const daysDifference = expiryDate.diff(
                          currentDate,
                          "days"
                        ); // Get the difference in days

                        let displayText;

                        if (daysDifference > 0) {
                          displayText =
                            daysDifference === 1
                              ? "1d left"
                              : `${daysDifference}d left`;
                        } else if (daysDifference === 0) {
                          displayText = "Expires today";
                        } else {
                          displayText = "Expired";
                        }
                        return (
                          <tr>
                            <td>
                              <div
                                key={index}
                                className="table-product-image"
                                onClick={() => goToPreviewAd(data)}
                              >
                                <img
                                  src="./images/category-16.png"
                                  alt="product image"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="table-product-title-wrap">
                                <h3> {data?.title}</h3>
                                <p>
                                  {data?.description?.length > 30
                                    ? `${data?.description?.slice(0, 30)}...`
                                    : data?.description}{" "}
                                  {data?.description.length > 0 && (
                                    <span onClick={() => goToPreviewAd(data)}>
                                      Read more
                                    </span>
                                  )}
                                </p>
                              </div>
                            </td>
                            <td>
                              ${data?.price}/
                              <span className="selected-amount-v">
                                {data?.selectedAmountV}
                              </span>
                            </td>

                            <td>
                              {displayText}
                              <div className="table-actions-wrap mobile-profile-table-action">
                                <img
                                  onClick={() => goToEditPost(data)}
                                  src="./images/icons/edit.png"
                                  alt="edit icon"
                                />
                                <img
                                  onClick={() => goToDeletePost(data)}
                                  src="./images/icons/delete-icon.png"
                                  alt="delete icon"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
              {itemsToShow?.filter((data) => {
                const currentDate = moment(); // Current date and time
                const expiryDate = moment(data.ExpiryDate); // Expiry date from your data
                const daysDifference = expiryDate.diff(currentDate, "days"); // Get the difference in days

                if (navActive === "ExpireAds") {
                  return daysDifference < 0; // Filter only expired data
                } else if (navActive === "Myads") {
                  return daysDifference >= 0;
                }
              }).length > 0
                ? visibleCount < normalizedData.length && (
                    <div className="table-show-more" onClick={handleShowMore}>
                      <h3> Show more</h3>
                    </div>
                  )
                : ""}
            </div>

            <div className="profile-botto-ad-wrap">
              <img src="./images/car-ads.png" alt="ads" />
              <p> Ads </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;

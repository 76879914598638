import React, { Fragment, useState } from "react";
import "./dropdown.css";

const SortedList = [
  {
    name: "Newest First",
  },
  {
    name: "Oldest First",
  },
];

const SortByFilter = ({ onTypeChange, alreadySelected }) => {
  const [isStateActive, setIsStateActive] = useState(false);
  const [stateSelected, setStateSelected] = useState(
    alreadySelected ? alreadySelected : "Select option"
  );

  return (
    <>
      <div className="select-box-wrapper">
        <label> Sort By</label>
        <div className="react-dropdown">
          <div
            className="react-dropdown-btn"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsStateActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsStateActive(false);
            }}
          >
            <div
              className={`selected-item ${
                stateSelected.startsWith("Select") ? "placeholderText" : ""
              }`}
            >
              <p>{stateSelected}</p>
            </div>

            <img
              className={
                isStateActive
                  ? "dropdown-down-arrow rotate-arrow"
                  : "dropdown-down-arrow"
              }
              src="/images/icons/nav-country-drop.svg"
              alt="arrow"
            />
          </div>
          <div
            className="dropdown-space-manage"
            onMouseEnter={(e) => {
              e.stopPropagation();
              setIsStateActive(true);
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              setIsStateActive(false);
            }}
          ></div>
          {isStateActive && (
            <div
              className="dropdown-content"
              onMouseEnter={(e) => {
                e.stopPropagation();
                setIsStateActive(true);
              }}
              onMouseLeave={(e) => {
                e.stopPropagation();
                setIsStateActive(false);
              }}
            >
              {SortedList &&
                SortedList.map((option, index) => {
                  return (
                    <Fragment key={index}>
                      <div
                        onClick={(e) => {
                          setStateSelected(option.name);
                          setIsStateActive(false);

                          onTypeChange(option.name); // Call callback function
                        }}
                        className={
                          option.name === stateSelected
                            ? "dropdown-item active"
                            : "dropdown-item"
                        }
                      >
                        <div className="dropdown-options">
                          <p
                            className={`${
                              option.name === stateSelected
                                ? "option-active"
                                : "option-normal"
                            }`}
                          >
                            {option.name}
                          </p>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SortByFilter;

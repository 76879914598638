import React, { Fragment, useEffect, useState } from "react";
import "./category.css";
import FilterModal from "../modals/FilterModal";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoginModal from "../modals/LoginModal";
import ProductsList from "./DummyData";
import Pagination from "./Pagination";
import { servicePost } from "../../helper/api";
import { normalizeResponse } from "../../helper/normalizeResponse";
import moment from "moment";

const CategoryProducts = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [categoryWiseData, setCategoryWiseData] = useState([]);

  // NormalizeData and Loop throw
  const normalizeCatoryProduct = normalizeResponse(categoryWiseData);

  // Get LogedIn User From LocalStorage
  const logedInUser = JSON.parse(localStorage.getItem("user"));
  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  const navigate = useNavigate();
  const location = useLocation();
  const MainCategory = location.state?.MainCategory;
  const category = location.state?.category;
  const locationCity = location.state?.locationCity;
  const locationProvince = location.state?.locationProvince;
  const data = location.state?.data;

  // Get Values form Filter modal
  const [formData, setFormData] = useState({
    selectedCategory: "",
    subcategory: "",
    province: "",
    city: "",
    priceFrom: "",
    priceTo: "",
    quantity: "",
    QuantityVarations: "",
    sortby: "",
  });

  const {
    selectedCategory,
    subcategory,
    province,
    city,
    priceFrom,
    priceTo,
    quantity,
    QuantityVarations,
    sortby,
  } = formData;

  const addViewCount = async (postId) => {
    try {
      const addView = await servicePost(
        "post/addViewData",
        {
          postId: postId,
        },
        authToken
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  const goToViewPost = (data) => {
    navigate("/post-preview", {
      state: {
        data: data,
        actionType: "viewUserAd",
      },
    });
    if (data.AccountId == logedInUser?.id) {
    } else {
      addViewCount(data.postId);
    }
  };

  // get Valuse for Pick-up Modal
  const handleModalSave = (
    category,
    subcategory,
    province,
    city,
    priceFrom,
    priceTo,
    quantity,
    QuantityVarations,
    sortby
  ) => {
    setFormData({
      selectedCategory: category == "All" ? "" : category,
      subcategory: subcategory == "All" ? "" : subcategory,
      province: province == "All" ? "" : province,
      city: city == "All" ? "" : city,
      priceFrom: priceFrom,
      priceTo: priceTo,
      quantity: quantity,
      QuantityVarations: QuantityVarations == "All" ? "" : QuantityVarations,
      sortby: sortby,
    });

    setShowFilterModal(false);
  };

  const getUserPosts = async () => {
    try {
      const userPostsRes = await servicePost(
        "post/getFilterData",
        {
          Category: selectedCategory ? selectedCategory : category,
          MainCategory: MainCategory,
          SubCategory: subcategory,
          Province: province ? province : locationProvince,
          City: city ? city : locationCity,
          PriceFrom: priceFrom,
          PriceTo: priceTo,
          Quantity: quantity,
          QuantityVariation: QuantityVarations,
          SortBy: sortby,
          AccountId: "",
        },
        authToken
      );
      setCategoryWiseData(userPostsRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserPosts();
  }, [category, MainCategory, formData, locationCity, locationProvince]);

  const goBack = () => {
    navigate(-1);
  };

  const goToHomePage = () => {
    navigate("/");
  };

  const renderProduct = (data, index) => {
    return (
      <div className="product-list-repeat" key={index}>
        <div className="product-list-detail-wrap">
          <div
            className="product-list-image"
            onClick={() => goToViewPost(data)}
          >
            {data?.uploadedFiles && data.uploadedFiles.length > 0 && (
              <img src={data.uploadedFiles[0]} alt="product image" />
            )}
          </div>
          <div className="product-list-info-wrap">
            <h3> {data?.title} </h3>
            <div className="product-info-time">
              <p> {data?.selectedCity} </p>
              <div className="standing-line"></div>
              <p>{moment(data.createdAt).startOf("minutes").fromNow()}</p>
            </div>
            <div className="product-desc-wrap">
              <p>
                {data?.description?.length > 80
                  ? `${data?.description?.slice(0, 80)}...`
                  : data?.description}
                {data?.description.length > 80 && (
                  <span onClick={() => goToViewPost(data)}>Read more</span>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="product-list-price-info-wrapper">
          <div className="product-list-price-info-wrap">
            <div className="product-price-wrapper">
              <img src="./images/icons/price-icon.png" alt="price icon" />
              <div className="product-price-wrap">
                <h4 className="product-price-color"> Price </h4>
                <h3>
                  ${data?.price}/{data?.selectedAmountV}
                </h3>
              </div>
            </div>

            <div className="product-price-wrapper">
              <img
                src="./images/icons/availablety.png"
                alt="availablety icon"
              />
              <div className="product-price-wrap">
                <h4 className="product-availabity-color"> Availabity </h4>
                <p> {data?.selectedAvailability} </p>
              </div>
            </div>

            <div className="product-price-wrapper">
              <img src="./images/icons/quantity.png" alt="quantity icon" />
              <div className="product-price-wrap">
                <h4 className="product-quantity-color"> Quantity </h4>
                <p>
                  {data?.quantity}/{data?.selectedQuantityV}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAd = (index) => {
    return (
      <div className="product-list-repeat" key={index}>
        <div className="product-list-detail-wrap">
          <div className="product-list-image">
            <img src="./images/category-ad.png" alt="product image" />
          </div>
          <div className="product-list-info-wrap">
            <h3> Add Related Title</h3>
            <div className="product-desc-wrap">
              <p>
                Lorem Ipsum is simply dummy text of the printing and
                typesetting.Lorem Ipsum is simply dummy text of the
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const itemsPerPage = 27;
  const totalPages = Math.ceil(normalizeCatoryProduct?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = normalizeCatoryProduct?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <>
      <Helmet>
        <title>Category Products | Food Classifieds</title>
        <meta
          name="description"
          content="Buy fresh and flavorful oranges in Toronto. Discover our range of quality oranges with daily availability."
        />
      </Helmet>

      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />

      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        onSave={handleModalSave}
      />

      <div className="category-products-main-wrapper">
        <div className="category-products-main-wrap container">
          {!locationProvince ? (
            <div className="product-header-path-wrapper">
              <div className="preview-header-path-wrap">
                <p onClick={goToHomePage}> Home </p>
                <img src="./images/icons/path-arrow.svg" alt="arrow" />
                <p onClick={goBack} className="bold-text">
                  {data?.categoryType}
                </p>
                <img src="./images/icons/path-arrow.svg" alt="arrow" />

                <p onClick={goBack}> {category ? category : MainCategory} </p>
              </div>
            </div>
          ) : (
            <div className="product-header-path-wrapper">
              <div className="preview-header-path-wrap">
                <p onClick={goToHomePage}> Home </p>
                <img src="./images/icons/path-arrow.svg" alt="arrow" />
                <p style={{ cursor: "default" }} className="bold-text">
                  {data?.categoryType}
                </p>
                <img src="./images/icons/path-arrow.svg" alt="arrow" />
                <p style={{ cursor: "default" }}> {locationProvince} </p>
              </div>
            </div>
          )}

          <div className="category-product-top-ad-wrap">
            <img src="./images/car-ads.png" alt="google ads" />
            <p> Ads </p>
          </div>

          {data?.categoryType !== "All" && !locationProvince ? (
            <div className="category-product-header-wrap">
              <div className="category-product-head-left">
                <img src={data?.categoryTypeImg} alt="category icon" />
                <h2> {category} </h2>
                {/* <img
                  className="side-arrow"
                  src="./images/icons/path-arrow.svg"
                  alt="arrow"
                />
                <h3> {category ? category : MainCategory} </h3> */}
              </div>
            </div>
          ) : locationProvince ? (
            <div className="category-product-header-wrap">
              <div className="category-product-head-left location-product-head-left">
                <img src={data?.categoryTypeImg} alt="category icon" />
                <h2> {locationProvince} </h2>
                <h3> {locationCity} </h3>
              </div>
            </div>
          ) : (
            <div className="category-product-header-wrap">
              <div className="category-product-head-left">
                <img src={data?.categoryTypeImg} alt="category icon" />
                <h2> {MainCategory} </h2>
                {/* <img
                  className="side-arrow"
                  src="./images/icons/path-arrow.svg"
                  alt="arrow"
                /> */}
              </div>
            </div>
          )}

          {/* <div className="category-product-header-wrap">
            <div className="category-product-head-left"></div>
          </div> */}

          <div className="category-products-wrapper">
            <div className="category-products-wrap">
              {currentItems?.length === 0 ? (
                <>
                  <div className="product-list-empty-wrapper">
                    <div className="empty-icon-wrap">
                      <img
                        src="./images/icons/empty-icon.svg"
                        alt="empty-icon"
                      />
                      <p>No Data Found</p>
                    </div>
                  </div>
                </>
              ) : (
                <div className="category-product-left-wrap">
                  <div className="category-product-filter-wrap">
                    <button
                      className="green-button"
                      onClick={() => setShowFilterModal(true)}
                    >
                      <img
                        src="./images/icons/filter-icon.png"
                        alt="filter icon"
                      />
                      Filter
                    </button>
                    {/* <p> Results 1-20 of 120</p> */}
                  </div>

                  <div className="products-list-wrapper">
                    <div className="products-list-wrap">
                      {currentItems.map((data, index) => {
                        return (
                          <React.Fragment key={index}>
                            {renderProduct(data, index)}
                            {index === 10 && renderAd(index)}
                            {index > 10 &&
                              (index - 10) % 8 === 0 &&
                              renderAd(index)}
                          </React.Fragment>
                        );
                      })}
                    </div>

                    {totalPages > 1 && (
                      <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                      />
                    )}
                  </div>
                </div>
              )}
              {currentItems?.length > 0 && (
                <>
                  <div className="category-product-left-right">
                    <img
                      src="./images/preview-sidebar-ad.png"
                      alt="google ads"
                    />
                    <p> Ads </p>
                  </div>
                </>
              )}
            </div>

            <div className="category-product-google-add">
              <img src="./images/car-ads.png" alt="google ads" />
              <p> Ads </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryProducts;

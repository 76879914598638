import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";
import CommanModal from "./CommanModal";
import { servicePost } from "../../helper/api";
import { useNavigate } from "react-router-dom";

const DeletePostModal = ({ postId, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showCommanModal, setShowCommanModal] = useState(false);

  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  const navigate = useNavigate();

  const DeletePostHandler = async () => {
    try {
      setIsLoading(true);
      const deletePost = await servicePost(
        "post/deletePostData",
        {
          id: postId,
        },
        authToken
      );
      props.onHide();
      setShowCommanModal(true);
      setIsLoading(false);
      setTimeout(() => {
        setShowCommanModal(false);
        navigate("/profile");
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <CommanModal
        show={showCommanModal}
        onHide={() => setShowCommanModal(false)}
        type="Deleted!"
        content="your successfully deleted your post"
      />

      <Modal
        {...props}
        size="lg"
        dialogClassName="delete-post-modal-main-wrapper"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="delete-post-main-wrapper">
            <div className="delete-post-main-wrap">
              <div className="delete-post-content-wrap">
                <h3> Delete Confirmation! </h3>
                <p> Are you want to sure to delete this post? </p>
              </div>

              <div className="delete-post-buttons">
                <button
                  className="green-button"
                  onClick={DeletePostHandler}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="button-loading-gif">
                      <Lottie animationData={LoadingLottie} loop={true} />
                    </div>
                  ) : (
                    "Delete"
                  )}
                </button>
                <button
                  className="green-bordered-button"
                  onClick={() => props.onHide()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DeletePostModal;

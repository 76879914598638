import React, { useState } from "react";
import "./register.css";
import { useNavigate } from "react-router-dom";
import ProductOffered from "../dropdown/ProductOffered";
import FarmLocation from "../dropdown/FarmLocation";

const CreateAd = () => {
  const [productOffered, setProductOffered] = useState();
  const [farmLocation, setFarmLocation] = useState();

  const navigate = useNavigate();
  const handleProductChange = (product) => {
    setProductOffered(product);
  };

  const handleLocationChange = (location) => {
    setFarmLocation(location);
  };

  const goBack = () => {
    navigate("/register-step-2");
  };

  const goToCreatePost = () => {
    navigate("/create-post", {
      state: {
        actionType: "firstuser",
      },
    });
  };

  const skipCreateAds = () => {
    navigate("/");
  };

  return (
    <div className="register-main-wrapper">
      <div className="register-main-wrap">
        <div className="register-main-left-wrapper">
          <div className="register-main-left-wrap create-add-left-warp">
            <div className="register-header-content register-content-with-backbtn">
              <img
                onClick={goBack}
                className="back-arrow-icon"
                src="./images/icons/back-arrow.svg"
                alt="back arrow icon"
              />

              <div className="register-head-content">
                <h3> You want to Create your Ad </h3>
                <p> Your Ad look like this </p>
              </div>
            </div>

            <div className="login-form-main-wrap">
              <div className="create-ad-image-wrapper">
                <img src="./images/create-ad-img.png" alt="create image" />
              </div>

              <div className="login-button-main-wrap register-skip-button">
                <button onClick={goToCreatePost}> Create Post </button>
                <button onClick={skipCreateAds} className="login-cancel-button">
                  Skip
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="register-main-right-wrap">
          <img src="./images/register-img.png" alt="register image" />
        </div>
      </div>
    </div>
  );
};

export default CreateAd;

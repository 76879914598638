import { BrowserRouter as Router, Navigate, Outlet } from "react-router-dom";
import { isUserAuthenticated } from "./AuthUtils";

export const ProtectedRoute = ({ redirectPath = "/" }) => {
  if (!isUserAuthenticated()) {
    localStorage.clear();
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

// Protected if your logined in then show this Routes
export const ProtectedRistrict = ({ redirectPath = "/" }) => {
  const isTwofa = localStorage.getItem("isTwofa")
    ? localStorage.getItem("isTwofa")
    : false;
  if (isUserAuthenticated() && isTwofa) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

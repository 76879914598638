const ProductsList = [
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-10.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-8.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-8.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-7.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-2.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-1.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-8.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },

  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-12.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
  {
    productImage: "./images/category-2.png",
    productName: "Orange",
    city: "Toronto",
    createdAt: "2 days ago",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting.Lorem Ipsum is simply dummy text of the....",
    price: "3.00",
    lot: "lot",
    availabity: "Instock",
    quantity: 520,
    quantityV: "daily",
  },
];

export default ProductsList;

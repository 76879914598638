import React, { useEffect, useState } from "react";
import "./preview.css";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";
import WarninAlert from "../components/WarningAlert";
import RelatedAds from "../components/RelatedAds";
import { useLocation, useNavigate } from "react-router-dom";
import DeletePostModal from "../modals/DeletePostModal";
import MessageDrawer from "../drawer/MessageDrawer";
import CommanModal from "../modals/CommanModal";
import ImagePopup from "./ImagePopup";
import { Helmet } from "react-helmet";
import { serviceImagePost, servicePost } from "../../helper/api";
import moment from "moment";
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const PreviewPost = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [showCommanModal, setShowCommanModal] = useState(false);
  const [showDeletModal, setShowDeleteModal] = useState(false);
  const [reltedPosts, setRelatedPosts] = useState([]);
  const [commanModalType, setCommanModalType] = useState();
  const [commanModalContent, setCommanModalContent] = useState();
  const [postCreator, setPostCreator] = useState([]);
  const [imageIndex, setImageIndex] = useState();
  const [showShare, setShowShare] = useState(false);
  const [revealNumber, setRevealNumber] = useState(false);
  const [revealEmail, setRevealEmail] = useState(false);

  const location = useLocation();
  const data = location.state?.data;
  const actionType = location.state?.actionType;
  const accountId = location.state?.accountId;

  // Phone Number And Email as A Screet
  const NumberDist = data?.phoneNumber && data?.phoneNumber?.slice(0, 6);
  const hideNumber = `${NumberDist} xxxx`;

  const EmailFirstDist = data?.email && data?.email?.slice(0, 5);
  const EmailLasrDist = data?.email && data?.email?.slice(-8);
  const unRevealEmail = `${EmailFirstDist}xxxx${EmailLasrDist}`;

  // Get LogedIn User From LocalStorage
  const logedInUser = JSON.parse(localStorage.getItem("user"));
  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  const navigate = useNavigate();
  const handleImageClick = (index) => {
    setPopupOpen(true);
    setImageIndex(index);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  // Get Post creator user
  const getPostCreator = async () => {
    try {
      const postCreatorUser = await servicePost(
        `user/getUser`,
        {
          id: data?.AccountId || accountId || logedInUser?.id,
        },
        authToken
      );

      setPostCreator(postCreatorUser.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPostCreator();
  }, [data?.AccountId]);

  // Mapping for abbreviated day names
  const dayAbbreviations = {
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
    Saturday: "Sat",
    Sunday: "Sun",
  };

  // Function to convert full day names to abbreviated names
  const getAbbreviatedDays = () => {
    return data?.selectedDays.map((day) => dayAbbreviations[day]);
  };

  const getAbbreviatedDeliveryDays = () => {
    return data?.selectedDeliveryDays.map((day) => dayAbbreviations[day]);
  };

  // Convert time
  const convertTo12HourFormat = (time) => {
    const splitTime = time.split(":");
    let hours = parseInt(splitTime[0], 10);
    const minutes = splitTime[1];

    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Handle midnight (0 hours)

    return `${hours}:${minutes} ${amPm}`;
  };

  const goToUserProfile = () => {
    if (actionType == "createAd" || actionType == "userEditPost") {
      navigate("/profile");
    } else if (data?.AccountId == logedInUser?.id) {
      navigate("/profile");
    } else {
      navigate("/user-profile", {
        state: {
          AccountId: data?.AccountId || accountId,
        },
      });
    }
  };

  // Create Post Handler....
  const createPostHandler = async () => {
    try {
      setIsLoading(true);

      const pickupDays = data?.selectedDays.join(",");
      const deliveryDays = data?.selectedDeliveryDays.join(",");
      const formData = new FormData();
      formData.append("AdvertisementTitle", data?.title);
      formData.append("Category", data?.selectedCategory);
      formData.append("SubCategory", data?.selectedSubcategory);
      formData.append("Pricing", data?.price);
      formData.append(
        "PricingVariation",
        data?.selectedAmountV !== undefined ? data?.selectedAmountV : ""
      );
      formData.append("Quantity", data?.quantity);
      formData.append(
        "QuantityVariation",
        data?.selectedQuantityV !== undefined ? data?.selectedQuantityV : ""
      );
      formData.append(
        "Availability",
        data?.selectedAvailability !== undefined
          ? data?.selectedAvailability
          : ""
      );
      formData.append(
        "SpecialOffer",
        data?.specialOffer !== undefined ? data?.specialOffer : ""
      );
      formData.append(
        "WebsiteLink",
        data?.websiteLink !== undefined ? data?.websiteLink : ""
      );
      formData.append("Description", data?.description);

      // formData.append("workImages", uploadedFiles);
      data?.uploadedFiles.forEach((file) => {
        formData.append("images", file);
      });
      formData.append(
        "Certificate1",
        data.certificate1 !== undefined ? data.certificate1 : ""
      );
      formData.append(
        "Certificate2",
        data.certificate2 !== undefined ? data.certificate2 : ""
      );
      formData.append(
        "Certificate3",
        data.certificate3 !== undefined ? data.certificate3 : ""
      );
      formData.append("Country", data.selectedCountry);
      formData.append("Province", data.selectedProvince);
      formData.append("City", data.selectedCity);
      formData.append(
        "Address",
        data?.address !== undefined ? data?.address : ""
      );
      formData.append("EmailAddress", data?.email);
      formData.append(
        "Phone",
        data?.phoneNumber !== undefined ? data?.phoneNumber : ""
      );
      formData.append(
        "ContactPreference",
        data.selectedPreference !== undefined ? data.selectedPreference : ""
      );
      formData.append("PickupChecked", data.pickupChecked);
      formData.append("DeliveryChecked", data.deliveryChecked);
      formData.append("PickupDays", pickupDays);
      formData.append("DeliveryDays", deliveryDays);
      formData.append("PickupOpenTime", data.openTime);
      formData.append("PickupCloseTime", data.closeTime);
      formData.append("DeliveryOpenTime", data.openDeliveryTime);

      formData.append("DeliveryCloseTime", data.closeDeliveryTime);
      formData.append(
        "CostForDelivery",
        data?.deilveryCost !== undefined ? data?.deilveryCost : ""
      );
      formData.append("AccountId", logedInUser?.id);
      const postRes = await serviceImagePost(
        "post/addPostData",
        formData,
        authToken
      );

      setIsLoading(false);
      setCommanModalType("Ad Post!");
      setCommanModalContent("your Post successfully Live Now");
      setShowCommanModal(true);
      setTimeout(() => {
        setShowCommanModal(false);
        navigate("/profile");
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.log("err", error);
    }
  };

  // Edit Post Handler....
  const updatePostHandler = async () => {
    try {
      setIsLoading(true);
      const postRes = await servicePost(
        "post/updatePostData",
        {
          id: data?.postId,
          AdvertisementTitle: data?.title,
          Category: data?.selectedCategory,
          SubCategory: data?.selectedSubcategory,
          Pricing: data?.price,
          PricingVariation: data?.selectedAmountV,
          Quantity: data?.quantity,
          QuantityVariation: data?.selectedQuantityV,
          Availability: data?.selectedAvailability,
          SpecialOffer: data?.specialOffer,
          WebsiteLink: data?.websiteLink,
          Description: data?.description,
          Images: data?.uploadedFiles,
          Certificate1: data.certificate1,
          Certificate2: data.certificate2,
          Certificate3: data.certificate3,
          Country: data.selectedCountry,
          Province: data.selectedProvince,
          City: data.selectedCity,
          Address: data?.address,
          EmailAddress: data?.email,
          Phone: data?.phoneNumber,
          ContactPreference: data.selectedPreference,
          PickupChecked: data.pickupChecked,
          DeliveryChecked: data.deliveryChecked,
          PickupDays: data.selectedDays,
          DeliveryDays: data.selectedDeliveryDays,
          PickupOpenTime: data.openTime,
          PickupCloseTime: data.closeTime,
          DeliveryOpenTime: data.openDeliveryTime,
          DeliveryCloseTime: data.closeDeliveryTime,
          CostForDelivery: data?.deilveryCost,
          AccountId: logedInUser?.id,
        },
        authToken
      );

      setIsLoading(false);
      setCommanModalType("Post Changes!");
      setCommanModalContent("you successfully saved your changes");
      setShowCommanModal(true);
      setTimeout(() => {
        setShowCommanModal(false);
        navigate("/profile");
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.log("err", error);
    }
  };

  const getRelatedPosts = async () => {
    try {
      const relatedPostRes = await servicePost(
        "post/getRelatedPostData",
        {
          category: data?.selectedCategory,
        },
        authToken
      );
      setRelatedPosts(relatedPostRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getRelatedPosts();
  }, [data?.selectedCategory]);

  const goToEditPost = () => {
    navigate("/edit-post", {
      state: {
        data: data,
        actionType: actionType,
      },
    });
  };

  // Go To Profile Page
  const goToProfilePage = () => {
    navigate("/profile");
  };

  const goToHomePage = () => {
    navigate("/");
  };

  const parsedFiles =
    actionType !== "createAd" ? data?.uploadedFiles : undefined;

  // Share Url
  const shareUrl = "https://foodclassifieds.com";
  const title = "Food Classifieds";

  return (
    <>
      <Helmet>
        <title> Post Preview | Food Classifieds</title>
      </Helmet>
      <div
        className="offcanvas offcanvas-end offcanvas-custom"
        tabIndex="-1"
        id="messageDrawerRight"
        aria-labelledby="offcanvasRightLabel1"
      >
        <MessageDrawer data={data} User2Image={postCreator?.ProfileImageURL} />
      </div>
      <DeletePostModal
        show={showDeletModal}
        onHide={() => setShowDeleteModal(false)}
        postId={data?.postId}
      />

      <CommanModal
        show={showCommanModal}
        onHide={() => setShowCommanModal(false)}
        type={commanModalType}
        content={commanModalContent}
      />

      <div className="preview-post-main-wrapper">
        {authToken ? (
          <div className="right-sidebar-wrapper">
            {actionType == "viewUserAd" &&
            data?.AccountId != logedInUser?.id ? (
              <div
                className="sidebar-chat-wrap"
                data-bs-toggle="offcanvas"
                data-bs-target="#messageDrawerRight"
                aria-controls="messageDrawerRight"
              >
                <img
                  src="./images/icons/msg-chat-icon.png"
                  alt="message icon"
                />
              </div>
            ) : (
              ""
            )}

            {actionType == "viewUserAd" &&
            data?.AccountId != logedInUser?.id ? (
              <div className="sidebar-chat-wrap">
                <div
                  className="sidebar-chat-wrap"
                  onMouseEnter={() => setShowShare(true)}
                  onMouseLeave={() => setShowShare(false)}
                >
                  <img src="./images/icons/share-icon.png" alt="share icon" />
                </div>

                <div
                  className="share-btn-space-manage"
                  onMouseEnter={() => setShowShare(true)}
                  onMouseLeave={() => setShowShare(false)}
                ></div>

                {showShare && (
                  <div
                    className="report-button-wrap"
                    onMouseEnter={() => setShowShare(true)}
                    onMouseLeave={() => setShowShare(false)}
                  >
                    <div className="post-share-button-wrapper">
                      <div className="post-share-icon-wrap">
                        <InstapaperShareButton url={shareUrl} quote={title}>
                          <img src="./images/icons/insta.svg" alt="icon" />
                        </InstapaperShareButton>
                        <FacebookShareButton url={shareUrl} quote={title}>
                          <img src="./images/icons/facebook.svg" alt="icon" />
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl} quote={title}>
                          <img src="./images/icons/twitter.svg" alt="icon" />
                        </TwitterShareButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        <div className="preview-post-main-wrap container">
          <div className="googl-ads-location-wrap">
            <div className="previre-head-ads">
              <img src="./images/preview-top-ad.png" alt="ad imgage" />
              <p> Ads </p>
            </div>
          </div>

          <div className="preview-header-main-wrap preview-head-flex-wrap">
            {actionType == "createAd" ? (
              <div className="preview-header-path-wrap">
                <p> Preview post </p>
              </div>
            ) : actionType == "Delete" ? (
              <div className="preview-header-path-wrap">
                <p> Delete post </p>
              </div>
            ) : actionType == "userEditPost" ? (
              <div className="preview-header-path-wrap">
                <p> Edit post </p>
              </div>
            ) : (
              <div className="preview-header-path-wrap">
                <p onClick={goToHomePage}> Home </p>
                <img src="./images/icons/path-arrow.svg" alt="arrow icon" />
                <p onClick={goToHomePage}> {data?.MainCategory} </p>
                <img src="./images/icons/path-arrow.svg" alt="arrow icon" />
                <p onClick={goToHomePage}> {data?.selectedCategory} </p>
                {/* <img src="./images/icons/path-arrow.svg" alt="arrow icon" />
                <p className="bold-text"> {data?.title} </p> */}
                <img src="./images/icons/path-arrow.svg" alt="arrow icon" />
                {data?.postId && <p className="bold-text"> #{data?.postId} </p>}
              </div>
            )}

            <div className="product-detail-address">
              <img src="./images/icons/location.png" alt="location icon" />
              <p>
                {data?.address}
                {data?.address ? "," : ""} {data?.selectedProvince},{" "}
                {data.selectedCity}
              </p>
            </div>
          </div>

          {actionType == "createAd" ? (
            <WarninAlert warning="Review your post before it goes live to ensure all details are accurate and captivating for potential buyers" />
          ) : actionType == "Delete" ? (
            <WarninAlert warning="Are you sure you want to delete this post? This action cannot be undone." />
          ) : actionType == "userEditPost" ? (
            <WarninAlert warning="Review your changes post before it goes live to ensure all details are accurate and captivating for potential buyers." />
          ) : (
            ""
          )}
          <div className="Preview-action-buttons">
            <div className="preview-action-btn-wrap">
              {actionType == "Delete" ? (
                <>
                  <button
                    className="green-button"
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Delete Post
                  </button>
                  <button
                    className="green-bordered-button"
                    onClick={goToProfilePage}
                  >
                    Cancel
                  </button>
                </>
              ) : actionType == "createAd" ? (
                <>
                  <button
                    className="green-button"
                    disabled={isLoading}
                    onClick={createPostHandler}
                  >
                    {isLoading ? (
                      <div className="button-loading-gif">
                        <Lottie animationData={LoadingLottie} loop={true} />
                      </div>
                    ) : (
                      "Post"
                    )}
                  </button>
                  <button
                    className="green-bordered-button"
                    disabled={isLoading}
                    onClick={goToEditPost}
                  >
                    Edit
                  </button>
                </>
              ) : actionType == "userEditPost" ? (
                <>
                  <button
                    className="green-button"
                    disabled={isLoading}
                    onClick={updatePostHandler}
                  >
                    {isLoading ? (
                      <div className="button-loading-gif">
                        <Lottie animationData={LoadingLottie} loop={true} />
                      </div>
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                  <button
                    className="green-bordered-button"
                    disabled={isLoading}
                    onClick={goToEditPost}
                  >
                    Edit
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="product-preview-main-wrapper">
            <div className="product-preview-main-wrap">
              <div className="product-preview-left-wrapper">
                <div className="product-preview-left-wrap">
                  <div className="product-images-wrapper">
                    {actionType === "createAd" ? (
                      <>
                        <div className="product-main-image-wrap">
                          <img
                            src={URL.createObjectURL(data?.uploadedFiles[0])}
                            alt="category image"
                            onClick={() => handleImageClick(0)}
                          />
                          <ImagePopup
                            show={popupOpen}
                            onHide={() => setPopupOpen(false)}
                            imageIndex={imageIndex}
                            images={data?.uploadedFiles?.map((file) =>
                              URL.createObjectURL(file)
                            )}
                          />
                        </div>
                        <div className="product-preview-inner-images">
                          {data?.uploadedFiles ? (
                            Array.from(Array(3)).map((_, index) => (
                              <>
                                {data?.uploadedFiles[index] ? (
                                  <div key={index}>
                                    <img
                                      src={URL.createObjectURL(
                                        data?.uploadedFiles[index]
                                      )}
                                      onClick={() => handleImageClick(index)}
                                      alt="category image"
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="product-main-image-wrap">
                          <img
                            src={parsedFiles[0]} // Use the URL directly
                            alt="category image"
                            onClick={() => handleImageClick(0)}
                          />
                          <ImagePopup
                            show={popupOpen}
                            onHide={() => setPopupOpen(false)}
                            imageIndex={imageIndex}
                            images={data?.uploadedFiles} // Use the uploadedFiles directly
                          />
                        </div>

                        <div className="product-preview-inner-images">
                          {parsedFiles.map((url, index) => (
                            <React.Fragment key={index}>
                              <div>
                                <img
                                  onClick={() => handleImageClick(index)}
                                  src={url}
                                  alt={`Uploaded ${index}`}
                                />
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="product-preview-detail-wrapper">
                    <div className="product-preview-detail-wrap">
                      <div className="product-detail-title">
                        <div className="preview-title-wrap">
                          <h2> {data?.title} </h2>
                          {data?.createdAt && (
                            <p>
                              {moment(data?.createdAt)
                                .startOf("minutes")
                                .fromNow()}
                            </p>
                          )}
                        </div>

                        <p> {data?.selectedCategory} </p>
                      </div>

                      <div className="product-detail-step-wrap">
                        <h3>
                          ${data?.price} {data?.selectedAvailability ? "/" : ""}
                          <span>
                            {data?.selectedAvailability &&
                              data?.selectedAmountV}
                          </span>
                        </h3>

                        <div className="product-detail-step-repeat">
                          {data?.specialOffer &&
                            data?.specialOffer !== "undefined" && (
                              <div className="product-detail-address">
                                <img
                                  src="./images/icons/promo-icon.png"
                                  alt="icon"
                                />
                                {data?.specialOffer !== "undefined" && (
                                  <p>{data?.specialOffer}</p>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="product-preview-detail-wrap product-preview-second-wrap">
                      <div className="product-detail-step-repeat">
                        <div className="product-detail-address">
                          <img
                            src="./images/icons/quantity.png"
                            alt="quantity icon"
                          />
                          <div className="product-quantity-wrap">
                            <h4> Quantity</h4>
                            <p>
                              {data?.quantity}/
                              {data?.selectedQuantityV
                                ? data?.selectedQuantityV
                                : "-"}
                            </p>
                          </div>
                        </div>
                        {data?.selectedAvailability ? (
                          <div className="product-detail-address">
                            <img
                              src="./images/icons/availabity.png"
                              alt="availabity icon"
                            />
                            <div className="product-quantity-wrap">
                              <h4> Availabity</h4>
                              <p>{data?.selectedAvailability}</p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="product-detail-address">
                          <img
                            src="./images/icons/link-icon.png"
                            alt="link icon"
                          />
                          <div className="product-quantity-wrap">
                            <h4> Web/Product link</h4>

                            {data?.websiteLink ? (
                              <p>{data?.websiteLink}</p>
                            ) : (
                              <p>--</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="product-preview-detail-wrap product-preview-second-wrap product-preview-third-wrap">
                      <div className="product-sub-title-wrap">
                        <h3> Pickup </h3>
                      </div>

                      <div className="product-detail-step-repeat">
                        <div className="product-detail-address">
                          <img
                            src="./images/icons/pickup-icon-1.png"
                            alt="pickup icon"
                          />
                          <div className="product-quantity-wrap">
                            <h4> Days</h4>
                            <p>
                              {data?.selectedDays != ""
                                ? getAbbreviatedDays().join(", ")
                                : "--"}
                            </p>
                          </div>
                        </div>
                        <div className="product-detail-address">
                          <img
                            src="./images/icons/pickup-icon-2.png"
                            alt="pickup icon"
                          />
                          <div className="product-quantity-wrap">
                            <h4> Time</h4>

                            <p>
                              {data?.openTime
                                ? convertTo12HourFormat(data?.openTime)
                                : ""}{" "}
                              -
                              {data?.closeTime
                                ? convertTo12HourFormat(data?.closeTime)
                                : ""}
                            </p>
                          </div>
                        </div>

                        <div className="product-detail-address pickup-empty-delivery-cost">
                          <img
                            src="./images/icons/pickup-icon-5.png"
                            alt="pickup icon"
                          />
                          <div className="product-quantity-wrap">
                            <h4> Web/Product link</h4>
                            <p> uqwedhvfjh... </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="product-preview-detail-wrap product-preview-second-wrap mobile-preview-space">
                      <div className="product-sub-title-wrap">
                        <h3> Delivery </h3>
                      </div>

                      <div className="product-detail-step-repeat">
                        <div className="product-detail-address">
                          <img
                            src="./images/icons/pickup-icon-3.png"
                            alt="icon"
                          />
                          <div className="product-quantity-wrap">
                            <h4> Days</h4>
                            <p>
                              {data?.selectedDeliveryDays != ""
                                ? getAbbreviatedDeliveryDays().join(", ")
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="product-detail-address">
                          <img
                            src="./images/icons/pickup-icon-4.png"
                            alt="icon"
                          />
                          <div className="product-quantity-wrap">
                            <h4> Time</h4>
                            <p>
                              <p>
                                {data?.openDeliveryTime
                                  ? convertTo12HourFormat(
                                      data?.openDeliveryTime
                                    )
                                  : ""}
                                -
                                {data?.closeDeliveryTime
                                  ? convertTo12HourFormat(
                                      data?.closeDeliveryTime
                                    )
                                  : ""}
                              </p>
                            </p>
                          </div>
                        </div>

                        <div className="product-detail-address">
                          <img
                            src="./images/icons/pickup-icon-5.png"
                            alt="pickup icon"
                          />

                          <div className="product-quantity-wrap">
                            <h4> Delivery cost</h4>
                            {data?.deilveryCost ? (
                              <p> ${data?.deilveryCost} </p>
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="product-preview-detail-wrap product-preview-second-wrap">
                      <div className="product-sub-title-wrap">
                        <h3> Certifications </h3>
                      </div>
                      <div className="product-detail-step-repeat">
                        <div className="product-detail-address">
                          <img
                            src="./images/icons/certificate.png"
                            alt="certificate icon"
                          />
                          <div className="product-certificate-wrap">
                            {data.certificate1 ? (
                              <p>{data?.certificate1}</p>
                            ) : (
                              <p>-</p>
                            )}
                            {data.certificate2 ? (
                              <p>{data?.certificate2}</p>
                            ) : (
                              <p>-</p>
                            )}
                            {data.certificate3 ? (
                              <p>{data?.certificate3}</p>
                            ) : (
                              <p>-</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="preview-desc-wrapper">
                  <div className="preview-desc-wrap">
                    <h3> Description </h3>
                    <p>{data?.description}</p>
                  </div>
                </div>
              </div>

              <div className="product-preview-right-wrapper">
                <div className="product-preview-right-wrap">
                  <div className="product-preview-peofile-wrap">
                    <div className="product-preview-peofile">
                      {postCreator?.ProfileImageURL ? (
                        <img
                          onClick={goToUserProfile}
                          src={postCreator?.ProfileImageURL}
                          alt="user profile"
                        />
                      ) : (
                        <img
                          onClick={goToUserProfile}
                          src="./images/icons/avtar.png"
                          alt="user profile"
                        />
                      )}

                      {postCreator?.FirstName ? (
                        <h3>
                          {postCreator?.FirstName}{" "}
                          {postCreator?.FarmName ? "/" : ""}
                          <span>{postCreator?.FarmName}</span>
                        </h3>
                      ) : (
                        <h3>
                          <h3> {postCreator?.Email?.slice(0, 7)}... </h3>
                        </h3>
                      )}
                      <div className="post-profile-info">
                        {data?.phoneNumber && (
                          <p>
                            {revealNumber ? data?.phoneNumber : hideNumber}

                            {!revealNumber && (
                              <span
                                className="reveal-text"
                                onClick={() => setRevealNumber(true)}
                              >
                                Reveal
                              </span>
                            )}
                          </p>
                        )}

                        {data?.email && (
                          <p>
                            {revealEmail ? data?.email : unRevealEmail}

                            {!revealEmail && (
                              <span
                                className="reveal-text"
                                onClick={() => setRevealEmail(true)}
                              >
                                Reveal
                              </span>
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="product-preview-profile-icons">
                      {postCreator?.Website && (
                        <a href={postCreator?.Website} target="_blank">
                          <img
                            src="./images/icons/website.svg"
                            alt="website icon"
                          />
                        </a>
                      )}

                      {postCreator?.Instagram && (
                        <a href={postCreator?.Instagram} target="_blank">
                          <img
                            src="./images/icons/insta.svg"
                            alt="insta icon"
                          />
                        </a>
                      )}

                      {postCreator?.Facebook && (
                        <a href={postCreator?.Facebook} target="_blank">
                          <img
                            src="./images/icons/facebook.svg"
                            alt="facebook icon"
                          />
                        </a>
                      )}

                      {postCreator?.Twitter && (
                        <a href={postCreator?.Twitter} target="_blank">
                          <img
                            src="./images/icons/twitter.svg"
                            alt="twitter icon"
                          />
                        </a>
                      )}
                    </div>
                  </div>

                  <div className="preview-sidebar-ads-wrap">
                    <img
                      src="./images/preview-sidebar-ad.png"
                      alt="sidebar ads img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="preview-bottom-google-ads">
            <div className="preview-bottom-ad-wrap">
              <img src="./images/car-ads.png" alt="google ads" />
              <p> Ads </p>
            </div>
          </div>
          {reltedPosts.length > 0 && <RelatedAds reltedPosts={reltedPosts} />}
        </div>
      </div>
    </>
  );
};

export default PreviewPost;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CategoryDrop from "../dropdown/CategoryDrop";
import "./modal.css";
import FarmLocation from "../dropdown/FarmLocation";
import SubCategoryDrop from "../dropdown/SubCategoryDrop";
import DiscountFilter from "../dropdown/DiscountFilter";
import AmountVarations from "../dropdown/AmountVarations";
import QuantityVarations from "../dropdown/QuantityVarations";
import SortByFilter from "../dropdown/SortByFilter";
import CityDropdown from "../dropdown/CityDropdown";
import ProvinceDrop from "../dropdown/ProvinceDrop";

const FilterModal = (props) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubcategory, setSelectedSubcategory] = useState();
  const [selectedProvince, setSelectedProvince] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedQuantityV, setSelectedQuantityV] = useState();
  const [selectedSortby, setSelectedSortby] = useState();
  // const [pickupChecked, setPickupChecked] = useState(false);
  // const [deliveryChecked, setDeliveryChecked] = useState(false);

  // User Bank detail
  const [formData, setFormData] = useState({
    priceFrom: "",
    priceTo: "",
    quantity: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { priceFrom, priceTo, quantity } = formData;

  // Handle Select Dropdown values
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleCityChange = (city) => {
    setSelectedCity(city);
  };

  const handleSubcategoryChange = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  const handleProvinceChange = (province) => {
    setSelectedProvince(province);
  };

  const handleQuantityVchange = (varation) => {
    setSelectedQuantityV(varation);
  };

  const handleSortbyChange = (sortby) => {
    setSelectedSortby(sortby);
  };

  // const handlePickupChange = (e) => {
  //   setPickupChecked(e.target.checked);
  // };

  // const handleDeliveryChange = (e) => {
  //   setDeliveryChecked(e.target.checked);
  // };

  // Handle key press events
  const handleKeyDown = (e) => {
    if (
      // Disallow 'e', '-', and '+'
      e.key === "e" ||
      e.key === "-" ||
      e.key === "+"
    ) {
      e.preventDefault();
    }
  };

  const handleSave = () => {
    props.onSave(
      selectedCategory,
      selectedSubcategory,
      selectedProvince,
      selectedCity,
      priceFrom,
      priceTo,
      quantity,
      selectedQuantityV,
      selectedSortby
    );
  };

  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="filter-modal-main-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="filter-modal-body-wrapper">
          <div className="filter-cross-wrapper">
            <h3> Filters </h3>

            <img
              onClick={() => {
                props.onHide();
              }}
              src="./images/icons/cross-icon.svg"
              alt="icon"
            />
          </div>
          <div className="filter-modal-main-wrap">
            <div className="login-form-divide-field-wrap">
              <div className="login-form-input-wrap">
                <CategoryDrop
                  onTypeChange={handleCategoryChange}
                  alreadySelected={selectedCategory}
                  allOption="All"
                />
              </div>
              <div className="login-form-input-wrap">
                <SubCategoryDrop
                  onTypeChange={handleSubcategoryChange}
                  category={selectedCategory}
                  alreadySelected={selectedSubcategory}
                  allOption="All"
                />
              </div>
            </div>

            <div className="login-form-divide-field-wrap">
              <div className="login-form-input-wrap">
                <ProvinceDrop
                  onTypeChange={handleProvinceChange}
                  alreadySelected={selectedProvince}
                  allOption="All"
                />
              </div>
              <div className="login-form-input-wrap">
                <CityDropdown
                  onTypeChange={handleCityChange}
                  state={selectedProvince}
                  alreadySelected={selectedCity}
                  allOption="All"
                />
              </div>
            </div>

            <div className="create-post-head-sub-content">
              <p> Pricing</p>
            </div>
            <div className="login-form-divide-field-wrap">
              <div className="login-form-input-wrap">
                <label> Price from </label>
                <input
                  type="number"
                  placeholder="$...."
                  onKeyDown={handleKeyDown}
                  name="priceFrom"
                  value={priceFrom}
                  onChange={handleInputChange}
                />
              </div>

              <div className="login-form-input-wrap">
                <div className="login-form-input-wrap">
                  <label> Price to </label>
                  <input
                    type="number"
                    placeholder="$...."
                    onKeyDown={handleKeyDown}
                    name="priceTo"
                    value={priceTo}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="login-form-divide-field-wrap">
              <div className="login-form-input-wrap">
                <label> Quantity </label>
                <input
                  type="number"
                  placeholder="$...."
                  onKeyDown={handleKeyDown}
                  name="quantity"
                  value={quantity}
                  onChange={handleInputChange}
                />
              </div>

              <div className="login-form-input-wrap">
                <QuantityVarations
                  onTypeChange={handleQuantityVchange}
                  alreadySelected={selectedQuantityV}
                  allOption="All"
                />
              </div>
            </div>

            {/* <div className="filter-pickup-wrapper">
              <label for="agreeTerms">
                Pick up
                <input
                  className="accented"
                  type="checkbox"
                  id="pickup-checkbox"
                  name="pickup-checkbox"
                  onChange={handlePickupChange}
                />
              </label>
              <label for="agreeTerms">
                Delivery
                <input
                  className="accented"
                  type="checkbox"
                  id="delivery-checkbox"
                  name="delivery-checkbox"
                  onChange={handleDeliveryChange}
                />
              </label>
            </div> */}

            <div className="login-form-input-wrap">
              <SortByFilter
                onTypeChange={handleSortbyChange}
                alreadySelected={selectedSortby}
              />
            </div>

            {/* <div className="login-form-input-wrap">
              <label> Price (from - to) </label>
              <input
                type="number"
                placeholder="Example: 10$ - 50$"
                name="quantity"
              />
            </div> */}

            <div className="login-button-main-wrap">
              <button onClick={handleSave}> Apply </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default FilterModal;

import React, { useEffect, useRef, useState } from "react";
import "./navbar.css";
import LoginModal from "../modals/LoginModal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LogoutModal from "../modals/LogoutModal";
import { servicePost } from "../../helper/api";
import DesktopSeach from "./DesktopSeach";
import LocationSearch from "./LocationSearch";

const Navbar = () => {
  const [showSearchDrop, setShowSearchDrop] = useState(false);
  const [showLoctionSearchDrop, setShowLocationSearchDrop] = useState(false);
  const [userHaveMsg, setUserHaveMsg] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const dropdownRef = useRef(null);
  const locationDropdownRef = useRef(null);

  const [userData, setUserData] = useState([]);
  const token = localStorage.getItem("token");

  // Get LogedIn User From LocalStorage
  const logedInUser = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  // get Taskdep form Redux
  const taskDep = useSelector(
    (state) => state.userReducer?.taskDep && state.userReducer.taskDep.Dep1
  );

  const country = useSelector(
    (state) =>
      state.userReducer?.locationData && state.userReducer.locationData.country
  );

  // go to diffrent routes
  const goToRegisterPage = () => {
    navigate("/register");
  };

  const goToHomePage = () => {
    navigate("/");
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  const goToCreatePost = () => {
    navigate("/create-post");
  };

  const goToMessages = () => {
    navigate("/messages");
  };

  const showDropdown = () => {
    setShowSearchDrop(true);
  };

  const hideDropdown = () => {
    setShowSearchDrop(false);
  };

  const showLoctionDropdown = () => {
    setShowLocationSearchDrop(true);
  };

  const hideLocationDropdown = () => {
    setShowLocationSearchDrop(false);
  };

  // Get User Detail By UserId
  const getUserDetail = async () => {
    try {
      const userRes = await servicePost(
        `user/getUser`,
        {
          id: logedInUser?.id,
        },
        token
      );
      setUserData(userRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, [logedInUser?.id]);

  // getting Notications
  const getMsgNotification = async () => {
    try {
      const msgNotify = await servicePost(
        "message/getNotificationData",
        {
          accountId: logedInUser?.id,
        },
        token
      );
      setUserHaveMsg(msgNotify.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getMsgNotification();
  }, [logedInUser?.id, token, showLoginModal, taskDep]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the dropdown, hide it
        hideDropdown();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [hideDropdown]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        locationDropdownRef.current &&
        !locationDropdownRef.current.contains(event.target)
      ) {
        // Clicked outside the dropdown, hide it
        hideLocationDropdown();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [hideLocationDropdown]);

  return (
    <>
      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />

      <LogoutModal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
      />

      <div className="navbar-main-wrapper">
        <div className="navbar-main-wrap container">
          <div className="navbar-left-wrap">
            <img
              className="desktop-logo"
              onClick={goToHomePage}
              src="./images/icons/logo.png"
              alt="logo"
            />
            <img
              className="mobile-logo"
              onClick={goToHomePage}
              src="./images/icons/mobile-logo.png"
              alt="mobile-logo"
            />
          </div>
          <div className="navbar-right-wrap">
            <div className="nav-search-wrap" ref={dropdownRef}>
              <input
                type="text"
                placeholder="What are you looking for?"
                onChange={(e) => setSearchValue(e.target.value)}
                onFocus={showDropdown}
              />
              <div className="search-icon">
                <img src="./images/icons/search-icon.png" alt="search icon" />
              </div>

              {showSearchDrop && (
                <DesktopSeach
                  searchValue={searchValue}
                  hideDropdown={hideDropdown}
                />
              )}
            </div>

            <div className="nav-location-wrapper" ref={locationDropdownRef}>
              <div
                className="nav-location-wrap"
                onClick={() => showLoctionDropdown()}
              >
                <img
                  className="icon-disable-mobile"
                  src="./images/icons/location-icon.png"
                  alt="Address location icon"
                />
                <p> {country ? country : "Canada"} </p>
                {/* {locationSearchVal.length < 3 ? (
                  <p> Canada </p>
                ) : (
                  <p> {locationSearchVal?.slice(0, 6)} </p>
                )} */}

                <img
                  className="nav-country-drop location-drop-icon"
                  src="./images/icons/nav-country-drop.svg"
                  alt="country icon"
                />
              </div>
              {showLoctionSearchDrop && (
                <LocationSearch hideLocationDropdown={hideLocationDropdown} />
              )}
            </div>

            <div className="nav-right-side-wrap">
              {token ? (
                <>
                  <img
                    className="icon-disable-mobile"
                    onClick={goToProfile}
                    src="./images/icons/ad-icon.png"
                    alt="message icon"
                  />
                  <div className="nav-message-icon">
                    <img
                      onClick={goToMessages}
                      src="./images/icons/msg-icon.png"
                      alt="message icon"
                    />
                    {userHaveMsg?.length > 0 ? (
                      <div className="active-dots"></div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="nav-profile-main-wrap">
                    <div className="navbar-setting-dropdown-wrapper">
                      <div className="dropdown navbar-dashboard">
                        {userData?.ProfileImageURL ? (
                          <img
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            src={userData?.ProfileImageURL}
                            alt="profile-img"
                          />
                        ) : (
                          <img
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            src="./images/icons/avtar.png"
                            alt="user profile"
                          />
                        )}

                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <div
                              className="nav-dropdown-wrap"
                              onClick={goToProfile}
                            >
                              <img
                                src="./images/icons/profile-icon.svg"
                                alt="user profile"
                              />
                              <p> My Profile</p>
                            </div>
                          </li>

                          <li>
                            <div className="nav-dropdown-wrap logout-dropdown-wrap">
                              <img
                                onClick={() => setShowLogoutModal(true)}
                                src="./images/icons/logout-icon.svg"
                                alt="logout icon"
                              />
                              <p onClick={() => setShowLogoutModal(true)}>
                                Logout
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="nav-login-register-wrapper">
                  <div
                    className="nav-login-register-wrap"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <h4> Register </h4>
                    <p> Or </p>
                    <h4> Sign In</h4>
                  </div>

                  <div className="login-register-dropdown">
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div className="rigster-drop-wrapper">
                        <div className="rigster-drop-wrap">
                          <p> New to Farm app</p>
                          <h4 onClick={goToRegisterPage}> Register now </h4>
                        </div>
                      </div>

                      <div className="login-drop-wrapper">
                        <div className="login-drop-wrap">
                          <p> Already To Farm App? </p>
                          <button
                            onClick={() => {
                              setShowLoginModal(true);
                            }}
                          >
                            Login
                          </button>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              )}

              <div className="nav-add-post-button">
                {token ? (
                  <button onClick={goToCreatePost}> Post Ad </button>
                ) : (
                  <button onClick={() => setShowLoginModal(true)}>
                    Post Ad
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;

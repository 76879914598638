import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./home.css";
import Categories from "./category/Categories";
import { useNavigate } from "react-router-dom";
import HorizontalAds from "./components/HorizontalAds";
import AdsComponent from "./AdsComponent";
import DirectConnect from "./components/DirectConnect";

const Home = () => {
  const FRESHLIST = [
    {
      category: "Fruits",
      categoryImage: "./images/category-1.png",
    },
    {
      category: "Vegetables",
      categoryImage: "./images/category-2.png",
    },
    {
      category: "Herbs",
      categoryImage: "./images/category-3.png",
    },
    {
      category: "Dairy Products",
      categoryImage: "./images/category-4.png",
    },
    {
      category: "Grains and Cereals",
      categoryImage: "./images/category-5.png",
    },
    {
      category: "",
      categoryImage: "./images/category-ads.png",
    },
  ];

  const ESSENTIALSLIST = [
    {
      category: "Baked Goods",
      categoryImage: "./images/category-6.png",
    },
    {
      category: "Nuts and Seeds",
      categoryImage: "./images/category-7.png",
    },
    {
      category: "Honey and Syrups",
      categoryImage: "./images/category-8.png",
    },
    {
      category: "Canned and Preserved Goods",
      categoryImage: "./images/category-9.png",
    },
    {
      category: "Beverages",
      categoryImage: "./images/category-10.png",
    },
    {
      category: "Flowers and Plants",
      categoryImage: "./images/category-11.png",
    },
  ];

  const ARTISANAL = [
    {
      category: "Homemade Crafts",
      categoryImage: "./images/category-12.png",
    },
    {
      category: "Organic and Specialty Products",
      categoryImage: "./images/category-13.png",
    },
    {
      category: "DIY Kits",
      categoryImage: "./images/category-14.png",
    },
    {
      category: "Exotic or Unique Products",
      categoryImage: "./images/category-15.png",
    },
    {
      category: "Seasonal Items",
      categoryImage: "./images/category-16.png",
    },
    {
      category: "Ready-to-Eat Meals",
      categoryImage: "./images/category-17.png",
    },
  ];

  // Paste Your Shema Json Content as you Provided..
  // const organizationData = {
  //   "@context": "https://schema.org",
  //   "@type": "Organization",
  //   name: "Food Classifieds",
  //   url: "https://www.yourfarmwebsite.com",
  //   logo: "https://www.yourfarmwebsite.com/logo.png",
  // };

  return (
    <>
      <Helmet>
        <title>Home Page | Food Classifieds</title>
        <meta
          name="description"
          content="Buy fresh and flavorful oranges in Toronto."
        />
        <meta name="google-adsense-account" content="ca-pub-1117338431023347" />
      </Helmet>

      {/* Embedding the JSON-LD script for Organization schema */}
      {/* <script type="application/ld+json">
        {JSON.stringify(organizationData)}
      </script> */}

      <div className="home-page-main-wrapper">
        <div className="home-page-main-wrap">
          <div className="home-banner-wrap container">
            <img src="./images/home-banner.png" alt="home banner" />
          </div>

          <div className="google-add-wrap">
            <img src="./images/car-ads.png" alt="google ads" />
            <AdsComponent />
            <HorizontalAds />
          </div>

          <div className="home-categories-main-wrapper">
            <Categories
              categoryList={FRESHLIST}
              categoryType={"Fresh and Flavorful"}
              categoryTypeImg={"./images/icons/cat-title-1.png"}
            />
          </div>

          <div className="home-categories-main-wrapper">
            <Categories
              categoryList={ESSENTIALSLIST}
              categoryType={"Essentials & Delights"}
              categoryTypeImg={"./images/icons/cat-title-2.png"}
            />
          </div>

          <div className="home-categories-main-wrapper">
            <Categories
              categoryList={ARTISANAL}
              categoryType={"Artisanal Delights"}
              categoryTypeImg={"./images/icons/cat-title-3.png"}
            />
          </div>

          <div className="google-add-wrap google-second-ads">
            <img src="./images/car-ads.png" alt="google ads" />
            <p> Ads </p>
            {/* <AdsComponent /> */}
          </div>

          <DirectConnect />
        </div>
      </div>
    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { servicePost } from "../../helper/api";
import Lottie from "lottie-react";
import { setTaskDep } from "../../redux/action/userAction";
import LoadingLottie from "../lottiefiles/loading.json";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

const Chat = () => {
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);

  const [formData, setFormData] = useState({
    inputMessage: "",
  });

  const { inputMessage } = formData;

  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  const dispatch = useDispatch();
  const taskDep = useSelector(
    (state) => state.userReducer?.taskDep && state.userReducer.taskDep.Dep1
  );

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;
    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
    setErrors({ ...errors, [name]: "" });
  };

  // Error Validation
  const [errors, setErrors] = useState({
    inputMessage: "",
  });

  const errorMessages = {
    inputMessage: "Message is required",
  };

  const location = useLocation();
  const data = location.state?.data;
  const accountId = location.state?.accountId;

  // Get LogedIn User From LocalStorage
  const logedInUser = JSON.parse(localStorage.getItem("user"));

  // const accountId = 2;

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/messages");
  };

  const deleteViewedNotification = async () => {
    try {
      const deleteViewNotify = await servicePost(
        "message/deleteNotificationData",
        {
          accountId: logedInUser?.id,
          messageId: data?.messageId,
        },
        authToken
      );

      dispatch(
        setTaskDep({
          Dep1: taskDep + 1,
        })
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateChatMessages = async () => {
    const requiredFields = ["inputMessage"];
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field] || !formData[field].trim()) {
        newErrors[field] = errorMessages[field];
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
      return;
    }
    try {
      setIsLoading(true);
      const messageResponse = await servicePost(
        "message/updateChatMessageData",
        {
          Message: inputMessage,
          AccountId: logedInUser?.id,
          messageTo: accountId,
          PostId: data?.postId,
          MessageId: data?.messageId,
        },
        authToken
      );

      setIsLoading(false);
      setFormData({
        inputMessage: "",
      });

      // Fetch updated chat message after successful update
      getChatMessages();
      deleteViewedNotification();
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const getChatMessages = async () => {
    try {
      const chatResponse = await servicePost(
        "message/getChatMessageData",
        {
          postId: data?.postId,
          messageId: data?.messageId,
        },
        authToken
      );
      setChatMessages(chatResponse.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getChatMessages(); // Function to fetch API data and update state
      deleteViewedNotification();
    }, 5000); // Check for updates every 5 seconds
    getChatMessages();
    return () => clearInterval(intervalId);
  }, []);

  // Get User Detail By UserId
  const getUserDetail = async () => {
    try {
      const userRes = await servicePost(
        `user/getUser`,
        {
          id: accountId,
        },
        authToken
      );
      setUserData(userRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, [accountId]);

  const goToUserProfile = () => {
    navigate("/user-profile", {
      state: {
        AccountId: accountId,
      },
    });
  };

  const goToViewPost = () => {
    navigate("/post-preview", {
      state: {
        data: data,
        actionType: "viewUserAd",
        accountId: accountId,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title> Chat | Food Classifieds </title>
      </Helmet>

      <div className="chat-page-main-wrapper">
        <div className="chat-page-main-wrap container">
          <div className="back-to-msg-wrap">
            <img
              onClick={goBack}
              src="./images/icons/back-arrow.svg"
              alt="back arrow icon"
            />
            <h4> Back to messages </h4>
          </div>

          <div className="replay-msg-main-wrapper">
            <div className="replay-msg-left-wrap">
              <div className="profile-preview-wrap chat-profile-preview-wrap">
                <div className="profile-page-profile-image chat-profile-wrap">
                  {userData?.ProfileImageURL ? (
                    <img
                      onClick={goToUserProfile}
                      src={userData?.ProfileImageURL}
                      alt="profile-img"
                    />
                  ) : (
                    <img
                      onClick={goToUserProfile}
                      src="./images/icons/avtar.png"
                      alt="user profile"
                    />
                  )}
                </div>
                <div className="profile-page-info-wrapper">
                  {/* <h3>Kevin</h3> */}
                  {userData?.FirstName ? (
                    <h3>{userData?.FirstName}</h3>
                  ) : (
                    <h3> {userData?.Email?.slice(0, 8)}... </h3>
                  )}
                  <div className="profile-user-location info-profile-mobile-none">
                    <img
                      src="./images/icons/location.png"
                      alt="location icon"
                    />
                    <p> Canada</p>
                  </div>
                  <div className="profile-user-location info-profile-mobile-none">
                    <img src="./images/icons/email-icon.png" alt="email icon" />
                    <p>{userData?.Email}</p>
                  </div>
                  <div className="profile-socail-icons info-profile-mobile-none">
                    {userData?.Website && (
                      <a href={userData?.Website} target="_blank">
                        <img
                          src="./images/icons/website.svg"
                          alt="website icon"
                        />
                      </a>
                    )}

                    {userData?.Instagram && (
                      <a href={userData?.Instagram} target="_blank">
                        <img src="./images/icons/insta.svg" alt="insta icon" />
                      </a>
                    )}

                    {userData?.Facebook && (
                      <a href={userData?.Facebook} target="_blank">
                        <img
                          src="./images/icons/facebook.svg"
                          alt="facebook icon"
                        />
                      </a>
                    )}

                    {userData?.Twitter && (
                      <a href={userData?.Twitter} target="_blank">
                        <img
                          src="./images/icons/twitter.svg"
                          alt="twitter icon"
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="replay-msg-right-wrap">
              <div className="replay-msg-product-info">
                {data?.uploadedFiles && data.uploadedFiles.length > 0 && (
                  <img
                    onClick={goToViewPost}
                    src={data.uploadedFiles[0]}
                    alt="product image"
                  />
                )}
                <div className="replay-msg-title-wrap">
                  <p>{data.title}</p>
                  <h3>
                    ${data?.price}/<span>{data.selectedAmountV} </span>
                  </h3>
                </div>
              </div>

              <div className="replay-msg-ads-wrap">
                <img src="./images/preview-top-ad.png" alt="google-ads" />
                <p> Ads </p>
              </div>

              <div className="replay-chat-box-wrapper">
                <div className="replay-chat-box-wrap">
                  <section className="msger">
                    <main className="msger-chat">
                      <div className="chat-messages-wrap">
                        {[...chatMessages]
                          .sort(
                            (a, b) =>
                              new Date(a.createdAt) - new Date(b.createdAt)
                          )
                          .map((msg, index) => ({
                            ...msg,
                            side:
                              msg.userId == logedInUser?.id ? "right" : "left",
                          }))
                          .sort(
                            (a, b) =>
                              new Date(a.createdAt) - new Date(b.createdAt)
                          )
                          .map((msg, index) => (
                            <React.Fragment key={index}>
                              <div className={`msg ${msg.side}-msg`}>
                                <div className="msg-bubble">
                                  <div className="msg-text">{msg.Message}</div>
                                </div>
                              </div>
                              <div className={`${msg.side}-info-time`}>
                                <p>
                                  {moment(msg.createdAt)
                                    .startOf("minutes")
                                    .fromNow()}
                                </p>
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                      {/* <div className="chat-left-message-wrap">
                        <div className="msg-info">
                          <div className="msg-info-name">Kevin</div>
                        </div>
                        {chatMessages
                          .filter((msg) => msg.userId != accountId)
                          .map((msg, index) => (
                            <>
                              <div key={index} className="msg left-msg">
                                <div className="msg-bubble">
                                  <div className="msg-text">{msg.Message}</div>
                                </div>
                              </div>
                              <div className="msg-info-time">
                                <p>
                                  {moment(msg.createdAt)
                                    .startOf("hour")
                                    .fromNow()}
                                </p>
                              </div>
                            </>
                          ))}
                      </div>

                      <div className="chat-right-message-wrap">
                        {chatMessages
                          .filter((msg) => msg.userId == accountId)
                          .map((msg, index) => (
                            <>
                              <div key={index} className="msg right-msg">
                                <div className="msg-bubble">
                                  <div className="msg-text">{msg.Message}</div>
                                </div>
                              </div>
                              <div className="msg-info-time">
                                <p>
                                  {moment(msg.createdAt)
                                    .startOf("hour")
                                    .fromNow()}
                                </p>
                              </div>
                            </>
                          ))}
                      </div> */}
                    </main>
                  </section>
                </div>
                <div className="chat-send-message-wrapper">
                  <div className="chat-send-message-wrap">
                    {errors.inputMessage ? (
                      <label>
                        <span className="error-message">
                          {errors.inputMessage}
                        </span>
                      </label>
                    ) : (
                      ""
                    )}
                    <input
                      className={errors.inputMessage ? "input-error" : ""}
                      type="text"
                      placeholder="Write Message here.."
                      name="inputMessage"
                      value={inputMessage}
                      onChange={inputEventHandler}
                    />

                    <div className="send-message-wrap">
                      <button
                        className="green-button"
                        onClick={updateChatMessages}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div className="button-loading-gif">
                            <Lottie animationData={LoadingLottie} loop={true} />
                          </div>
                        ) : (
                          "Send message"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="replay-msg-bottom-ads">
            <img src="./images/car-ads.png" alt="google ads" />
            <p> Ads </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

const ImagePopup = ({ onHide, show, imageIndex, ...props }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(
    imageIndex !== undefined ? imageIndex : 0
  );

  useEffect(() => {
    setCurrentImageIndex(imageIndex);
  }, [imageIndex]);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === props.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? props.images.length - 1 : prevIndex - 1
    );
  };

  const handleClose = () => {
    onHide();
  };

  return (
    <Modal
      {...props}
      show={show}
      size="lg"
      dialogClassName="image-popup-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
      backdropClassName="custom-backdrop"
    >
      <Modal.Body>
        <div className="img-modal-cross-btn">
          <button onClick={handleClose}> X </button>
        </div>
        <div className="image-popup">
          <button onClick={handlePrev}>{"<"}</button>
          <img src={props.images[currentImageIndex]} alt="category image" />
          <button onClick={handleNext}>{">"}</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ImagePopup;

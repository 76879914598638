import React from "react";

const warningAlert = ({ warning }) => {
  return (
    <div className="warning-main-wrapper">
      <div className="warning-main-wrap">
        <img src="./images/icons/warn-icon.png" alt="icon" />
        <p>{warning}</p>
      </div>
    </div>
  );
};

export default warningAlert;

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { normalizeResponse } from "../../helper/normalizeResponse";
import { servicePost } from "../../helper/api";

const DesktopSeach = ({ searchValue, hideDropdown }) => {
  const [userPosts, setUserPosts] = useState([]);
  const [filteredUserPosts, setFilteredUserPosts] = useState([]);

  // NormalizeData and Loop throw
  const normalizedData = normalizeResponse(filteredUserPosts);
  const itemsToShow = normalizedData?.slice(0, 10);
  const navigate = useNavigate();

  // Get LogedIn User From LocalStorage
  const logedInUser = JSON.parse(localStorage.getItem("user"));
  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  const getUserPosts = async () => {
    try {
      const userPostsRes = await servicePost(
        "post/getAllPostData",
        {},
        authToken
      );
      setUserPosts(userPostsRes.data);
      filterUserPosts(userPostsRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserPosts();
  }, [logedInUser?.id]);

  const filterUserPosts = (data) => {
    if (searchValue) {
      const filteredData = data.filter(
        (post) =>
          post.AdvertisementTitle &&
          post.AdvertisementTitle.toLowerCase().includes(
            searchValue.toLowerCase()
          )
      );
      setFilteredUserPosts(filteredData);
    } else {
      setFilteredUserPosts(data);
    }
  };

  useEffect(() => {
    getUserPosts();
  }, [logedInUser?.id, searchValue]);

  const goToPreviewAd = (data) => {
    navigate("/post-preview", {
      state: {
        data: data,
        actionType: "previewOwnAd",
      },
    });
    hideDropdown();
  };

  return (
    <>
      <div className="search-filter-dropdown-wrapper">
        <div className="search-filter-drop-wrap">
          <div className="nft-search-filter-wrapper">
            <h3> Posts </h3>
            <div className="collection-filter-wrapper">
              {itemsToShow?.length === 0 ? (
                <div className="search-filter-empty-wrap">
                  <img src="./images/icons/empty-icon.svg" alt="icon" />
                  <p> No Posts Found</p>
                </div>
              ) : (
                itemsToShow?.map((data, index) => {
                  return (
                    <div className="nft-filter-repeat" key={index}>
                      {data?.uploadedFiles && data.uploadedFiles.length > 0 && (
                        <img
                          onClick={() => goToPreviewAd(data)}
                          src={data.uploadedFiles[0]}
                          alt="product image"
                        />
                      )}
                      <h4> {data?.title} </h4>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopSeach;

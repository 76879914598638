import { ActionTypes } from "../action/action-types";

const initialState = {
  token: null,
  taskDep: 0,
  locationData: {
    country: "Canada",
    state: "",
    city: "",
  },
};

export const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_TOKEN:
      return {
        ...state,
        token: payload,
      };

    case ActionTypes.SET_TASK_DEP:
      return {
        ...state,
        taskDep: payload,
      };

    case ActionTypes.SET_LOCATION_DATA:
      return {
        ...state,
        locationData: payload,
      };
    default:
      return state;
  }
};

import React from "react";

function HorizontalAds() {
  return (
    <div>
      {/* Other content */}
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1117338431023347"
        crossorigin="anonymous"
      ></script>
      <ins
        class="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-1117338431023347"
        data-ad-slot="8949706521"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      {/* Other content */}
      <p> Ads </p>
    </div>
  );
}

export default HorizontalAds;

import React, { useState } from "react";
import "./register.css";
import { useNavigate } from "react-router-dom";
import LoginModal from "../modals/LoginModal";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";
import { Helmet } from "react-helmet";
import { servicePost } from "../../helper/api";
import validator from "validator";
const Register = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(); // Default image
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShownicon, setPasswordShownicon] = useState(false);
  const [confirmpasswordShown, setConfirmPasswordShown] = useState(false);
  const [confirmpasswordShownicon, setConfirmPasswordShownicon] =
    useState(false);

  const navigate = useNavigate();
  ////////////////////
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [password, setPassword] = useState("");

  // const [email, setEmail] = useState();
  // const [username, setUserName] = useState();
  // const [confirmPassword, setConfirmPassword] = useState();
  const [fileTarget, setfileTarget] = useState();

  const [formData, setFormData] = useState({
    email: "",
    username: "",
    confirmPassword: "",
  });

  const { email, username, confirmPassword } = formData;

  // Error Validation
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    username: "",
    confirmPassword: "",
  });

  const errorMessages = {
    username: "Username is required",
    email: "Email is required",
    password: "Password is required",
    confirmPassword: "confirmPassword is required",
    invalidEmail: "Please enter a valid email",
    invalidPassword: "Please enter a valid password",
  };

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;
    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
    setErrors({ ...errors, [name]: "" });
  };

  ///////////////////
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    setPasswordShownicon(!passwordShownicon);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmpasswordShown);
    setConfirmPasswordShownicon(!confirmpasswordShownicon);
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const goToEmailVerification = () => {
    navigate("/email-verification");
  };

  ////////////////////////////////////
  ///useHandleChange to show password Error when user type
  const handleChange = (e) => {
    let { value } = e.target;

    const pass = e.target.value;
    setPassword(pass);

    setErrors({ ...errors, ["password"]: "" });
    if (
      validator.isStrongPassword(pass, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      // //console.log("hh....");
      //document.getElementById("newpass").style.display = "block";
      setPassword(pass);
      setErrorMessage("");
      setError(false);
    } else {
      setError(true);
      //setHide(!hide);
      setErrorMessage(
        "Password must contain: min 8 Characters, 1 uppercase & 1 lowercase letter, 1 number and 1 special character!"
      );
      return;
    }
  };

  ///////////////////////////////
  const reqSignup = async () => {
    try {
      const requiredFields = ["email", "confirmPassword"];
      const newErrors = {};

      requiredFields.forEach((field) => {
        if (!formData[field] || !formData[field].trim()) {
          newErrors[field] = errorMessages[field];
        }
      });

      // // Check if passwords match
      if (password === "") {
        newErrors["password"] = errorMessages["password"];
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
        return;
      }

      // Validate email
      if (!validator.isEmail(email)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: errorMessages.invalidEmail,
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }

      // Validate password
      if (
        !validator.isStrongPassword(password, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: errorMessages.invalidPassword,
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "",
        }));
      }

      // Check if passwords match
      if (password !== confirmPassword) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "Password doesn't match",
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: "",
        }));
      }

      // if (password != confirmPassword) {
      //   setErrorMessage("Password doesn't match.");
      //   setTimeout(() => {
      //     setErrorMessage("");
      //   }, 5000);
      //   return;
      // }
      //console.log("fileTarget",fileTarget);
      /////////////
      // let formData = new FormData();
      // formData.append("image", fileTarget);
      // formData.append("email", email);
      // formData.append("password", password);
      //////////////

      setIsLoading(true);
      const signupUser = await servicePost(
        "signIn",
        {
          email: email.toLowerCase(),
          password: password,
          confirmPassword: confirmPassword,
          // ipAddress: ip,
          // deviceInfo: `${browserInfo?.name}/${browserInfo?.version}(${browserInfo?.os})`,
          // location: country,
        },
        {}
        //csrfTokenState
      );
      // let signupUser = await servicePost(`signIn`, formData);
      if (signupUser.message == "SUCCESS") {
        // setModalShow(true);
        /// setReqLoader(false);
        setIsLoading(false);
        setTimeout(function () {
          navigate("/email-verification");
          // setModalShow(false);
        }, 2000);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error.response.data.message);
      //setReqLoader(false);
      setErrorMessage(error.response.data.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
  };
  ///////////////////////////////
  return (
    <>
      <Helmet>
        <title> Register | Food Classifieds </title>
      </Helmet>

      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />

      <div className="register-main-wrapper">
        <div className="register-main-wrap register-main-center-wrap">
          <div className="register-main-left-wrapper">
            <div className="register-main-left-wrap">
              <div className="register-header-content">
                <h3> Start Your Journey </h3>
                <p> Please enter your details. </p>
              </div>

              <div className="login-form-main-wrap">
                <form>
                  {/* <div className="profile-upload-main-wrap">
                    <div className="profile-image-wrapper">
                      <div className="profile-image-wrap">
                        {imagePreviewUrl ? (
                          <img
                            className="profile-preview-image"
                            src={imagePreviewUrl}
                            alt="image preview"
                          />
                        ) : (
                          <img
                            src="./images/icons/edit-icon.png"
                            alt="edit icon"
                          />
                        )}
                      </div>
                      <p>Upload your profile pic or Product logo</p>
                    </div>
                    <div className="profile-upload-input">
                      <input
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                    </div>
                  </div> */}

                  {/* <div className="login-form-input-wrap">
                    {errors.username ? (
                      <label>
                        <span className="error-message">{errors.username}</span>
                      </label>
                    ) : (
                      <label> Username* </label>
                    )}

                    <input
                      className={errors.username ? "input-error" : ""}
                      type="text"
                      placeholder="Enter your username"
                      name="username"
                      value={username}
                      onChange={inputEventHandler}
                    />
                  </div> */}

                  <div className="login-form-input-wrap">
                    {errors.email ? (
                      <label>
                        <span className="error-message">{errors.email}</span>
                      </label>
                    ) : (
                      <label> Email Address* </label>
                    )}

                    <input
                      className={errors.email ? "input-error" : ""}
                      type="text"
                      placeholder="Enter your Email Address"
                      name="email"
                      value={email}
                      onChange={inputEventHandler}
                    />
                  </div>

                  <div className="login-form-input-wrap">
                    {errors.password ? (
                      <label>
                        <span className="error-message">{errors.password}</span>
                      </label>
                    ) : (
                      <label> Password* </label>
                    )}

                    <input
                      className={errors.password ? "input-error" : ""}
                      type={passwordShown ? "text" : "password"}
                      placeholder="Enter your Password"
                      onChange={(e) => handleChange(e)}
                    />
                    <span className="password-icon" onClick={togglePassword}>
                      {passwordShownicon ? (
                        <img
                          src="./images/icons/password-show.svg"
                          alt="show icon"
                        />
                      ) : (
                        <img
                          src="./images/icons/password-hide.svg"
                          alt="hide icon"
                        />
                      )}
                    </span>
                  </div>

                  <div className="login-form-input-wrap">
                    {errors.confirmPassword ? (
                      <label>
                        <span className="error-message">
                          {errors.confirmPassword}
                        </span>
                      </label>
                    ) : (
                      <label> Confirm Password* </label>
                    )}

                    <input
                      className={errors.confirmPassword ? "input-error" : ""}
                      type={confirmpasswordShown ? "text" : "password"}
                      placeholder="Re-Enter your Password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={inputEventHandler}
                    />
                    <span
                      className="password-icon"
                      onClick={toggleConfirmPassword}
                    >
                      {confirmpasswordShownicon ? (
                        <img
                          src="./images/icons/password-show.svg"
                          alt="show icon"
                        />
                      ) : (
                        <img
                          src="./images/icons/password-hide.svg"
                          alt="hide icon"
                        />
                      )}
                    </span>
                  </div>
                  {setError ? (
                    <div className="error-msg">{errorMessage}</div>
                  ) : (
                    ""
                  )}
                </form>

                <div className="login-button-main-wrap">
                  <button disabled={isLoading} onClick={reqSignup}>
                    {isLoading ? (
                      <div className="button-loading-gif">
                        <Lottie animationData={LoadingLottie} loop={true} />
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <p onClick={() => setShowLoginModal(true)}>
                    Already To Farm App?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="register-main-right-wrap">
            <img src="./images/register-img.png" alt="register image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;

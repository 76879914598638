import { ActionTypes } from "./action-types";

export const setReduxToken = (data) => {
  return {
    type: ActionTypes.SET_TOKEN,
    payload: data,
  };
};

export const setTaskDep = (data) => {
  return {
    type: ActionTypes.SET_TASK_DEP,
    payload: data,
  };
};

export const setReduxCountry = (data) => {
  return {
    type: ActionTypes.SET_LOCATION_DATA,
    payload: data,
  };
};

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginModal from "../modals/LoginModal";

const Categories = ({ categoryList, categoryType, categoryTypeImg }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();

  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  const data = {
    categoryType: categoryType,
    categoryTypeImg: categoryTypeImg,
  };

  const goToCategoryProducts = (item) => {
    navigate("/category-products", {
      state: {
        data: data,
        category: item.category,
      },
    });
  };

  const AllCategorydata = {
    categoryType: "All",
    categoryTypeImg: categoryTypeImg,
  };

  const goToAllCategoryProducts = () => {
    navigate("/category-products", {
      state: {
        data: AllCategorydata,
        MainCategory: categoryType,
      },
    });
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };

  return (
    <>
      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />
      <div className="home-categories-main-wrap container">
        <div className="categories-header-wrap">
          <div className="category-head-left">
            <img src={categoryTypeImg} alt="category type icon" />
            <h2> {categoryType} </h2>
          </div>
          <div className="category-head-right">
            <h2 onClick={() => goToAllCategoryProducts("All")}> All</h2>
          </div>
        </div>

        <div className="category-list-main-wrapper">
          <div className="category-list-main-wrap">
            <Slider {...settings}>
              {categoryList?.map((data, index) => {
                return (
                  <div
                    className="category-list-repeat"
                    key={index}
                    onClick={() => goToCategoryProducts(data)}
                  >
                    <div className="category-image-wrap">
                      <img src={data.categoryImage} alt="category image" />
                    </div>
                    <div className="category-name-wrap">
                      <p> {data.category}</p>
                    </div>
                  </div>
                );
              })}
            </Slider>

            {/* {categoryType == "Fresh and Flavorful" ? (
              <div className="category-list-repeat">
                <div className="category-image-wrap cateory-add-wrap">
                  <img src="./images/category-ads.png" alt="category" />
                </div>
              </div>
            ) : (
              ""
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;

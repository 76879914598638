import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./modal.css";

const CommanModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="delete-post-modal-main-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="delete-post-main-wrapper">
          <div className="comman-modal-main-wrap">
            <div className="confirm-tick-image">
              <img src="./images/icons/confirm-tick.png" alt="icon" />
            </div>

            <div className="comman-modal-content-wrap">
              <h3> {props.type} </h3>
              <p> {props.content} </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CommanModal;

import React, { useEffect, useState } from "react";
import FilterModal from "../modals/FilterModal";
import "./profile.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { servicePost } from "../../helper/api";
import { normalizeResponse } from "../../helper/normalizeResponse";

import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const UserProfile = () => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userPosts, setUserPosts] = useState([]);
  const [showShare, setShowShare] = useState(false);
  // Get LogedIn User From LocalStorage
  const logedInUser = JSON.parse(localStorage.getItem("user"));
  // get Token From localstroge
  const authToken = localStorage.getItem("token");

  // NormalizeData and Loop throw
  const userPostData = normalizeResponse(userPosts);

  const location = useLocation();
  const AccountId = location.state?.AccountId;
  const navigate = useNavigate();

  // Get Values form Filter modal
  const [formData, setFormData] = useState({
    category: "",
    subcategory: "",
    province: "",
    city: "",
    priceFrom: "",
    priceTo: "",
    quantity: "",
    QuantityVarations: "",
    sortby: "",
  });

  const {
    category,
    subcategory,
    province,
    city,
    priceFrom,
    priceTo,
    quantity,
    QuantityVarations,
    sortby,
  } = formData;

  const addViewCount = async (postId) => {
    try {
      const addView = await servicePost(
        "post/addViewData",
        {
          postId: postId,
        },
        authToken
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  const goToViewPost = (data) => {
    navigate("/post-preview", {
      state: {
        data: data,
        actionType: "viewUserAd",
      },
    });

    if (data.AccountId == logedInUser?.id) {
    } else {
      addViewCount(data.postId);
    }
  };

  // Get Post creator user
  const getUserDetail = async () => {
    try {
      const userDataRes = await servicePost(
        `user/getUser`,
        {
          id: AccountId,
        },
        authToken
      );

      setUserData(userDataRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, [AccountId]);

  // get Valuse for Pick-up Modal
  const handleModalSave = (
    category,
    subcategory,
    province,
    city,
    priceFrom,
    priceTo,
    quantity,
    QuantityVarations,
    sortby
  ) => {
    setFormData({
      category: category == "All" ? "" : category,
      subcategory: subcategory == "All" ? "" : subcategory,
      province: province == "All" ? "" : province,
      city: city == "All" ? "" : city,
      priceFrom: priceFrom,
      priceTo: priceTo,
      quantity: quantity,
      QuantityVarations: QuantityVarations == "All" ? "" : QuantityVarations,
      sortby: sortby,
    });

    setShowFilterModal(false);
  };

  const getUserPosts = async () => {
    try {
      const userPostsRes = await servicePost(
        "post/getFilterData",
        {
          // AccountId: logedInUser?.id,
          Category: category,
          SubCategory: subcategory,
          Province: province,
          City: city,
          PriceFrom: priceFrom,
          PriceTo: priceTo,
          Quantity: quantity,
          QuantityVariation: QuantityVarations,
          SortBy: sortby,
          AccountId: AccountId,
        },
        authToken
      );
      setUserPosts(userPostsRes.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getUserPosts();
  }, [AccountId, formData]);

  const shareUrl = "https://foodclassifieds.com/user-profile";
  const title = "Food Classifieds";

  return (
    <>
      <Helmet>
        <title> User Profile | Food Classifieds </title>
      </Helmet>

      <FilterModal
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
        onSave={handleModalSave}
      />

      <div className="profile-main-wrapper">
        <div className="profile-main-wrap container">
          <div className="profile-top-ad-wrap">
            <img src="./images/preview-top-ad.png" alt="preview image" />
            <p> Ads </p>
          </div>

          <div className="profile-detail-main-wrapper">
            <div className="profile-detail-main-wrap">
              <div className="profile-detail-left">
                <div className="profile-preview-wrap">
                  <div className="profile-page-profile-image">
                    {userData?.ProfileImageURL ? (
                      <img src={userData?.ProfileImageURL} alt="profile-img" />
                    ) : (
                      <img src="./images/icons/avtar.png" alt="user profile" />
                    )}
                  </div>
                  <div className="profile-page-info-wrapper">
                    {userData?.FirstName ? (
                      <h3>
                        {userData?.FirstName}
                        {userData?.FarmName ? "/" : ""}
                        <span>{userData?.FarmName}</span>
                      </h3>
                    ) : (
                      <h3> {userData?.Email?.slice(0, 7)}... </h3>
                    )}
                    <div className="profile-user-location">
                      <img
                        src="./images/icons/location.png"
                        alt="location icon"
                      />
                      <p> Canada</p>
                    </div>
                    <div className="profile-user-location">
                      <img
                        src="./images/icons/email-icon.png"
                        alt="email icon"
                      />
                      {userData?.HideEmail ? (
                        <p> ******* </p>
                      ) : (
                        <p> {userData?.Email} </p>
                      )}
                    </div>
                    <div className="profile-socail-icons">
                      {userData?.Website && (
                        <a href={userData?.Website} target="_blank">
                          <img
                            src="./images/icons/website.svg"
                            alt="website icon"
                          />
                        </a>
                      )}

                      {userData?.Instagram && (
                        <a href={userData?.Instagram} target="_blank">
                          <img
                            src="./images/icons/insta.svg"
                            alt="insta icon"
                          />
                        </a>
                      )}

                      {userData?.Facebook && (
                        <a href={userData?.Facebook} target="_blank">
                          <img
                            src="./images/icons/facebook.svg"
                            alt="facebook icon"
                          />
                        </a>
                      )}

                      {userData?.Twitter && (
                        <a href={userData?.Twitter} target="_blank">
                          <img
                            src="./images/icons/twitter.svg"
                            alt="twitter icon"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="profile-share-wrap">
                  <div className="profile-user-location">
                    <img src="./images/icons/share.png" alt="share icon" />
                    <p> Share Profile</p>
                  </div>
                </div> */}

                <div className="profile-share-wrap">
                  <div
                    className="profile-user-location"
                    onMouseEnter={() => setShowShare(true)}
                    onMouseLeave={() => setShowShare(false)}
                  >
                    <img src="./images/icons/share.png" alt="share icon" />
                    <p> Share Profile</p>
                  </div>

                  <div
                    className="share-btn-space-manage"
                    onMouseEnter={() => setShowShare(true)}
                    onMouseLeave={() => setShowShare(false)}
                  ></div>

                  {showShare && (
                    <div
                      className="report-button-wrap"
                      onMouseEnter={() => setShowShare(true)}
                      onMouseLeave={() => setShowShare(false)}
                    >
                      <div className="share-button-wrapper">
                        <div className="share-icon-wrap">
                          <InstapaperShareButton url={shareUrl} quote={title}>
                            <img src="./images/icons/insta.svg" alt="icon" />
                          </InstapaperShareButton>
                          <FacebookShareButton url={shareUrl} quote={title}>
                            <img src="./images/icons/facebook.svg" alt="icon" />
                          </FacebookShareButton>
                          <TwitterShareButton url={shareUrl} quote={title}>
                            <img src="./images/icons/twitter.svg" alt="icon" />
                          </TwitterShareButton>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="profile-product-items-wrapper">
            <div className="profile-product-filter-wrap">
              <div className="profile-filter-left-wrap">
                <div className="profile-my-ads-wrap active">
                  <p> Listing </p>
                  <div className="nav-dot-wrap"></div>
                </div>
              </div>
              <div className="profile-filter-right-wrap">
                <button
                  className="green-button"
                  onClick={() => setShowFilterModal(true)}
                >
                  <img src="./images/icons/filter-icon.png" alt="filter icon" />{" "}
                  Filter
                </button>
              </div>
            </div>

            <div className="profile-item-grid-wrapper">
              {userPostData?.length > 0 ? (
                <div className="profile-item-grid-wrap">
                  {userPostData.map((data, index) => (
                    <div className="profile-grid-item-repeat" key={index}>
                      <div
                        className="profile-product-image-wrap"
                        onClick={() => goToViewPost(data)}
                      >
                        {data?.uploadedFiles &&
                          data.uploadedFiles.length > 0 && (
                            <img
                              src={data.uploadedFiles[0]}
                              alt="product image"
                            />
                          )}
                      </div>
                      <div className="profile-item-detail-wrap">
                        <h3>{data.title}</h3>
                        <p>
                          {data?.description?.length > 80
                            ? `${data?.description?.slice(0, 80)}...`
                            : data?.description}
                          {data?.description.length > 80 && (
                            <span onClick={() => goToViewPost(data)}>
                              Read more
                            </span>
                          )}
                        </p>
                        <h3>
                          {data?.price}/{data.selectedAmountV}
                        </h3>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="empty-icon-wrap">
                  <img src="./images/icons/empty-icon.svg" alt="empty-icon" />
                  <p>No Data Found</p>
                </div>
              )}
            </div>

            <div className="profile-botto-ad-wrap">
              <img src="./images/car-ads.png" alt="ads" />
              <p> Ads </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;

import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { servicePost } from "../../helper/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoginModal from "../modals/LoginModal";
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const EmailVerification = () => {
  const [checkVerified, setCheckVerified] = useState();
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  //////////////////////
  let query = useQuery();
  const token = query.get("token");
  let { id } = useParams();
  const decoded = jwtDecode(token);
  // //console.log("decoded", decoded);
  const accountId = decoded?.data?.id;

  const checkverfiytoken = async () => {
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    } else {
      const getVerifyToken = await servicePost(
        "emailverification",
        {
          accountId: accountId,
        },
        {}
      );

      if (getVerifyToken.isExpired) {
        return false;
      }
      return true;
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (decoded.data.id) {
        const checkVerfiy = await checkverfiytoken();
        setCheckVerified(checkVerfiy);
      }
    }
    fetchData();
  }, [decoded?.data?.id]);
  ////////////////////////
  const goToRegisterStep = () => {
    setShowLoginModal(true);
    //navigate("/register-step-2");
  };
  return (
    <>
      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
        loginFirstTime={true}
      />
      <div className="register-main-wrapper">
        <div className="register-main-wrap">
          <div className="register-main-left-wrapper">
            <div className="register-main-left-wrap email-verification-content">
              <img src="./images/icons/confirm-tick.png" alt="img" />
              <h3> Email Confirmation </h3>
              {checkVerified ? (
                <p className="sucess-msg">
                  Thank you! Your email has been verified, please login.
                </p>
              ) : (
                <>
                  <p className="error">Your Token Expired</p>
                  <p className="sucess-msg"> Please sign up again</p>
                </>
              )}
              <div className="login-button-main-wrap">
                <button onClick={goToRegisterStep}> Sign In </button>
              </div>
            </div>
          </div>
          <div className="register-main-right-wrap">
            <img src="./images/email-verify-screen.png" alt="img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerification;

import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./modal.css";
import Lottie from "lottie-react";
import LoadingLottie from "../lottiefiles/loading.json";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { servicePost } from "../../helper/api";
import { setReduxToken } from "../../redux/action/userAction";
import { useDispatch } from "react-redux";
const LoginModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShownicon, setPasswordShownicon] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    setPasswordShownicon(!passwordShownicon);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState();
  const goToRegisterPage = () => {
    props.onHide();
    navigate("/register");
  };
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { password, email } = formData;

  // Error Validation
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const errorMessages = {
    email: "Email is required",
    password: "Password is required",
    invalidEmail: "Please enter a valid email",
    invalidPassword: "Please enter a valid password",
  };

  // get All InputValues
  const inputEventHandler = (event) => {
    const { name, value } = event.target;
    setFormData((oldValue) => {
      return {
        ...oldValue,
        [name]: value,
      };
    });
    setErrors({ ...errors, [name]: "" });
  };

  const goToForgetPassword = () => {
    props.onHide();
    navigate("/forget-password");
  };

  ///////////////////////////////
  const reqLogin = async () => {
    try {
      const requiredFields = ["email", "password"];
      const newErrors = {};

      requiredFields.forEach((field) => {
        if (!formData[field] || !formData[field].trim()) {
          newErrors[field] = errorMessages[field];
        }
      });

      if (Object.keys(newErrors).length > 0) {
        setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
        return;
      }

      // Validate email
      if (!validator.isEmail(email)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: errorMessages.invalidEmail,
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }

      // Validate password
      if (
        !validator.isStrongPassword(password, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: errorMessages.invalidPassword,
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "",
        }));
      }

      setIsLoading(true);

      //////////////
      const signupUser = await servicePost(
        "login",
        {
          email: email.toLowerCase(),
          password: password,
        },
        {}
        //csrfTokenState
      );

      if (signupUser.message == "SUCCESS") {
        setIsLoading(false);
        props.onHide();
        localStorage.setItem("user", JSON.stringify(signupUser.data));
        localStorage.setItem("token", signupUser.token);
        dispatch(setReduxToken(signupUser.token));

        // setModalShow(true);
        /// setReqLoader(false);

        if (props.loginFirstTime == true) {
          navigate("/register-step-2");
        } else {
          navigate("/");
        }

        setFormData({
          email: "",
          password: "",
        });

        // setTimeout(function () {
        //   navigate("/");
        //   // setModalShow(false);
        // }, 2000);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error.response.data.message);
      //setReqLoader(false);
      setErrorMessage(error.response.data.message);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
      return;
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      dialogClassName="login-modal-main-wrapper"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}

      <Modal.Body>
        <div className="login-main-wrapper">
          <div className="cross-button">
            <img
              onClick={() => {
                props.onHide();
              }}
              src="./images/icons/cross-icon.svg"
              alt="icon"
            />
          </div>
          <div className="login-main-wrap">
            <div className="login-content-wrap">
              <h3> Login Zone </h3>
              <p> Please enter your details. </p>
            </div>

            <div className="login-form-main-wrap">
              <form>
                <div className="login-form-input-wrap">
                  {errors.email ? (
                    <label>
                      <span className="error-message">{errors.email}</span>
                    </label>
                  ) : (
                    <label> Email Address or Username* </label>
                  )}

                  <input
                    className={errors.email ? "input-error" : ""}
                    type="email"
                    placeholder="Enter your Email Address or Username"
                    name="email"
                    value={email}
                    onChange={inputEventHandler}
                  />
                </div>

                <div className="login-form-input-wrap">
                  {errors.password ? (
                    <label>
                      <span className="error-message">{errors.password}</span>
                    </label>
                  ) : (
                    <label> Password* </label>
                  )}
                  <input
                    className={errors.password ? "input-error" : ""}
                    type={passwordShown ? "text" : "password"}
                    placeholder="Enter your Password"
                    name="password"
                    value={password}
                    onChange={inputEventHandler}
                  />
                  <span className="password-icon" onClick={togglePassword}>
                    {passwordShownicon ? (
                      <img src="./images/icons/password-show.svg" alt="icon" />
                    ) : (
                      <img src="./images/icons/password-hide.svg" alt="icon" />
                    )}
                  </span>
                </div>

                <div className="forget-password-wrap">
                  <label for="agreeTerms">
                    <input
                      className="accented"
                      type="checkbox"
                      id="agreeTerms"
                      name="agreeTerms"
                    />
                    I Remember me
                  </label>

                  <h4 onClick={goToForgetPassword}> Forgot Password? </h4>
                </div>
              </form>
              <div className="login-button-main-wrap">
                <div className="error-msg">{errorMessage}</div>
                <button onClick={reqLogin}>
                  {isLoading ? (
                    <div className="button-loading-gif">
                      <Lottie animationData={LoadingLottie} loop={true} />
                    </div>
                  ) : (
                    "Login"
                  )}
                </button>
                <p onClick={goToRegisterPage}> not Resigtered yet? </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;

import React, { useState } from "react";
import LoginModal from "../modals/LoginModal";
import { useSelector } from "react-redux";

const DirectConnect = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  //   const token = localStorage.getItem("token");
  const token = useSelector(
    (state) => state.userReducer && state.userReducer.token
  );

  return (
    <>
      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />

      <div className="home-farm-banner-wrapper">
        <div className="home-farm-banner-wrap container">
          <div className="home-farm-banner-left"></div>
          <div className="home-farm-banner-right">
            <div className="home-form-banner-content">
              <h3> Local Flavor, Direct Connection </h3>
              <p>
                Dedicated to direct food connections. Explore local flavors and
                connect directly with buyers and sellers.
              </p>
              <div className="farm-banner-button">
                {!token ? (
                  <button onClick={() => setShowLoginModal(true)}>
                    Sign In
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectConnect;

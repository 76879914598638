import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginModal from "../modals/LoginModal";

const EmailVerification = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  const goToRegisterStep = () => {
    setShowLoginModal(true);

    // navigate("/register-step-2");
  };
  return (
    <>
      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
        loginFirstTime={true}
      />
      <div className="register-main-wrapper">
        <div className="register-main-wrap">
          <div className="register-main-left-wrapper">
            <div className="register-main-left-wrap email-verification-content">
              <img
                src="./images/icons/confirm-tick.png"
                alt="confirm tick icon"
              />
              <h3> Email Verification </h3>
              <p>
                Verification mail Send to your Resigter email address Please
                click link to verify your account
              </p>
              <div className="login-button-main-wrap">
                <button onClick={goToRegisterStep}> Sign In </button>
              </div>
            </div>
          </div>
          <div className="register-main-right-wrap">
            <img src="./images/email-verify-screen.png" alt="verify image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerification;
